import { gql } from '@apollo/client'
import { brightspotConfig } from '../../config'
import SemaphoreTagByParentSearch from './fragments/SemaphoreTagByParentSearch'
import SemaphoreTagBySESIdsSearch from './fragments/SemaphoreTagBySESIdsSearch'
import SemaphoreTagTopLevelSearch from './fragments/SemaphoreTagTopLevelSearch'
import gqlSanitize from './stringUtils'

const { defaults } = brightspotConfig.query

function querySemaphoresByParent() {
  return gql(`
    ${SemaphoreTagByParentSearch}
    query SemaphoreTagByParentSearch(
      $parentId: String, 
      $pageRequest: PaginationInput = ${gqlSanitize(defaults.pageRequest)}) {
      SemaphoreTagByParentSearch(
        parentId: $parentId,
        pageRequest: $pageRequest) {
          ...SemaphoreTagByParentSearch
      }
    }
  `)
}

function queryTopLevelSemaphores() {
  return gql(`
    ${SemaphoreTagTopLevelSearch}
    query SemaphoreTagTopLevelSearch($locale: String) {
      SemaphoreTagTopLevelSearch(contentLocale: $locale) {
        ...SemaphoreTagTopLevelSearch
      }
    }
  `)
}

// An sesId is a uuid from the Semaphore system (not BS)
function querySemaphoresBySESIds() {
  return gql(`
    ${SemaphoreTagBySESIdsSearch}
    query SemaphoreTagBySESIdsSearch($locale: String, $ids: [String]) {
      SemaphoreTagBySESIdsSearch(contentLocale: $locale, sesSemaphoreIds: $ids) {
        ...SemaphoreTagBySESIdsSearch
      }
    }  
  `)
}

// Read all the child semaphores of the given parent semaphore
export async function getSemaphoresByParent(client, parentId) {
  const results = { results: [] }
  let hasNext = true
  const variables = {
    parentId,
    pageRequest: { ...defaults.pageRequest, page: 0 },
  }
  while (hasNext) {
    // eslint-disable-next-line no-await-in-loop
    const response = await client.query({ query: querySemaphoresByParent(), variables })
    hasNext = response?.data?.SemaphoreTagByParentSearch?.paginationData?.hasNext
    ++variables.pageRequest.page

    if (response?.data.SemaphoreTagByParentSearch?.results?.length > 0) {
      results.results.push(...response.data.SemaphoreTagByParentSearch.results)
    }
  }

  return results
}

export async function getTopLevelSemaphores(client, locale) {
  const variables = {
    locale,
  }

  return client.query({ query: queryTopLevelSemaphores(), variables }).then((response) => {
    return response?.data?.SemaphoreTagTopLevelSearch
  })
}

// Given a list of uuids from the Semaphore system, ask BS for the corresponding Semaphore objects in BS
export async function getSemaphoresBySESIds(client, sesIds, locale = 'en-US') {
  if (!sesIds || !sesIds.length) {
    return []
  }

  const variables = {
    locale,
    sesIds,
  }

  return client
    .query({ query: querySemaphoresBySESIds(), variables })
    .then((response) => {
      return response?.data?.SemaphoreTagBySESIdsSearch
    })
    .catch((err) => {
      console.error(`Error retrieving semaphores by SES id: ${JSON.stringify(err, null, 4)}`)
      throw new Error('Error retrieving semaphores', { cause: err })
    })
}
