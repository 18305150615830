import { gql } from '@apollo/client'

export default function getSearchTopicsPage() {
  return gql(`
      query SearchTopicsPage (
        $path: String
      ) {
        OneOffPageDetail(path: $path) {
          content {
            ... on ListModule {
              __typename
              title
              items {
                url
                title
              }
            }
          }
        }
      }
    `)
}
