import * as Sentry from '@sentry/react'

export default function notifySentry(error) {
  if (error) {
    const message = error.message
    console.error(message)
    const customError = new Error(message)
    Sentry.captureException(customError)
  }
}
