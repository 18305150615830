import { i18n } from '@fs/zion-locale'
import { supportConfig } from '../../../config'

const communityLinks = supportConfig.communityLinks

const getCommunityLink = () => {
  return communityLinks[i18n?.language] || communityLinks.en
}

export default getCommunityLink
