import React from 'react'
import { css } from '@emotion/core'
import PropTypes from 'prop-types'
import { flavors, validFlavors } from '../config'

const flavorCss = css`
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: var(--gradient);
`

export default function RainbowText({ children, flavor = flavors.default, as: TagName = 'span', ...props }) {
  return (
    <TagName css={flavorCss} style={{ '--gradient': flavors[flavor].gradient }} {...props}>
      {children}
    </TagName>
  )
}

RainbowText.propTypes = {
  flavor: PropTypes.oneOf(validFlavors),
}
