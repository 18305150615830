import React from 'react'
import { Usabilla } from '@fs/zion-usabilla'
import { usabillaConfig } from './config'
import { rootsTechLogoWhitePng } from './images'

export default function Feedback() {
  return (
    <Usabilla url={usabillaConfig.url} hideOnMobile logo={rootsTechLogoWhitePng} localeMap={usabillaConfig.localeMap} />
  )
}
