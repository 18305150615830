import { useCallback } from 'react'
import { trackVideo } from '@fs/zion-analytics'
import { second } from '../../../../config'

const milestones = {
  started: 0,
  other: [0.25, 0.5, 0.75],
  finished: 1,
}

const milestonesSegment = {
  0.25: '25',
  0.5: '50',
  0.75: '75',
}

// these fields are defined in the FamilySearch data layer. https://nam10.safelinks.protection.outlook.com/?url=https%3A%2F%2Fdocs.google.com%2Fspreadsheets%2Fd%2F1VsfZTzsI4nQre8qt5WbKVBK9M7EwEExvmMcf_-j8bSQ%2Fedit%23gid%3D366884203&data=04%7C01%7Cnicknielsen86%40ChurchofJesusChrist.org%7Cfeb8129ce1d54443898808d9f228d8bf%7C61e6eeb35fd74aaaae3c61e8deb09b79%7C0%7C0%7C637807079518330783%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000&sdata=5Dd02n0LgU2NGA4n2HIKZ%2BuM5XfMrisDMm30zqT8Qm4%3D&reserved=0
const eventNames = {
  0: 'video_play',
  0.25: 'video_segment',
  0.5: 'video_segment',
  0.75: 'video_segment',
  1: 'video_complete',
}

let milestonesReached = {}
function sendTracking({ milestone, id, name }) {
  // preventing the same milestone from being sent twice.
  if (milestonesReached[milestone]) return
  milestonesReached[milestone] = true
  const trackingData = {
    event_name: eventNames[milestone],
    video_id: id,
    video_player: 'brightcove',
    video_name: name,
  }
  if (milestonesSegment[milestone]) {
    trackingData.video_segment_name = milestonesSegment[milestone]
  }
  trackVideo(trackingData)
}

const nearEndSeconds = 10
function determinePotentialMilestone({ currentTime, duration }) {
  const isNearEnd = duration > 0 && currentTime > duration - nearEndSeconds
  if (isNearEnd) {
    return milestones.finished
  }
  const proportionWatched = (duration > 0 && currentTime / duration) || 0
  const potentialMilestones = milestones.other.filter((a) => a <= proportionWatched)
  return potentialMilestones[potentialMilestones.length - 1] || milestones.started
}

let bitmovinInterval
function checkProgress({ currentTime, duration, id, name }) {
  const potentialMilestone = determinePotentialMilestone({ currentTime, duration })
  if (potentialMilestone === milestones.finished) {
    clearInterval(bitmovinInterval)
  }
  sendTracking({ milestone: potentialMilestone, id, name })
}
export default function useBitmovinPlayerAnalytics() {
  const setNewPlayer = useCallback((player) => {
    if (!player) return
    milestonesReached = {}
    bitmovinInterval && clearInterval(bitmovinInterval)
    bitmovinInterval = setInterval(() => {
      checkProgress({
        currentTime: player.getCurrentTime(),
        duration: player.getDuration(),
        id: player.getInfo().dataVideoId,
        name: player.getSource().title,
      })
    }, second / 4)
  }, [])

  return setNewPlayer
}
