import React from 'react'
import { Image } from '@fs/zion-ui'

export default function SponsorImage({ image }) {
  return image ? (
    <Image
      src={image}
      alt="Company Logo"
      data-testid="company-logo"
      style={{ borderRadius: 4, aspectRatio: '3/2', width: 120 }}
      loading="lazy"
    />
  ) : null
}
