import React, { Children } from 'react'
import SingleViewCarousel from '../SingleViewCarousel'
import MediaPromo from '../MediaPromo'

const CarouselList = ({ items, flavor }) => {
  return (
    <SingleViewCarousel flavor={flavor}>
      {Children.toArray(items.map((item) => <MediaPromo promo={item} isGif buttonEmphasis="medium" endGutter />))}
    </SingleViewCarousel>
  )
}

export default CarouselList
