import React, { useEffect, useMemo, useRef } from 'react'
import { IconButton, useMenuOverlay } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { NoticeQuestion } from '@fs/zion-icon'
import { useQueryParams } from '@fs/zion-router'
import HelpOverlay from './HelpOverlay'

export default function HelpButton({ onClick = () => {}, size = 'sm' }) {
  const [t] = useTranslation()
  const ref = useRef()
  const overlay = useMenuOverlay()
  const { query, removeQuery } = useQueryParams()

  const handleClick = (e) => {
    onClick(e)
    overlay.handleClick(e)
  }

  const isHelpQuery = useMemo(() => Object.keys(query).some((key) => key === 'help'), [query])
  useEffect(() => {
    if (isHelpQuery) {
      removeQuery('help')
      overlay.open({ target: ref.current })
    }
  }, [overlay, isHelpQuery, removeQuery])

  return (
    <>
      <IconButton
        ref={ref}
        size={size}
        Icon={NoticeQuestion}
        title={t('help.title', 'Help')}
        data-testid="menu-help"
        onClick={handleClick}
        linkName="Help"
      />

      <HelpOverlay {...overlay} />
    </>
  )
}
