import React, { forwardRef } from 'react'
import { Paragraph, colors } from '@fs/zion-ui'
import { css } from '@emotion/core'
import { SafeInnerHtml } from '@fs/zion-frontend-friends'

const richTextCss = css`
  h2 {
    font-size: var(--text-size-f);
    font-weight: 500;
    * + & {
      margin-top: 60px;
    }
    line-height: 1.08 !important;
  }

  h3 {
    font-size: var(--text-size-d);
  }
  h4 {
    font-size: var(--text-size-c);
  }
  h5 {
    font-size: var(--text-size-b);
  }

  h3,
  h4,
  h5 {
    font-weight: 700;
    margin-bottom: 8px;
    line-height: 1.2;
    * + & {
      margin-top: 40px;
    }
  }

  h3 + br,
  h2 + br {
    display: none;
  }

  a {
    color: ${colors.blue60};
    text-decoration: none;

    &:hover[class='Link'] {
      text-decoration: underline;
    }

    &:visited[class='Link'] {
      color: ${colors.red60};
    }
  }
`

const SafeRichText = ({ as = Paragraph, dirtyInput, ...props }, ref) => {
  return dirtyInput ? (
    <span css={richTextCss}>
      <SafeInnerHtml as={as} dirtyInput={dirtyInput} {...props} ref={ref} />{' '}
    </span>
  ) : null
}

export default forwardRef(SafeRichText)
