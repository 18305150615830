import { gql } from '@apollo/client'
import { brightspotConfig } from '../../config'
import { CalendarDetail } from './fragments'

const { defaults } = brightspotConfig.query

export default function getCalendarDetail() {
  return gql(`
    ${CalendarDetail}
    query CalendarDetail(
      $id: ID,
      $profileImage_crop: Boolean = false,
      $profileImage_height: Int = ${defaults.imageSize},
      $profileImage_width: Int = ${defaults.imageSize},
      $promoImage_crop: Boolean = false,
      $promoImage_height: Int = ${defaults.imageSize},
      $promoImage_width: Int = ${defaults.imageSize},
      $thumbnailImage_crop: Boolean = false
      $thumbnailImage_height: Int = ${defaults.imageSize},
      $thumbnailImage_width: Int = ${defaults.imageSize}) {
      CalendarDetail(id: $id) {
        ...CalendarDetail
      }
    }
  `)
}
