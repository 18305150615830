import React from 'react'
import { flavors } from '../../../config'
import { familyTentJpg } from '../../../images'
import RainbowCarouselSection from './RainbowCarouselSection'

export default function ExhibitorSpotlight({ data }) {
  const flavor = flavors.default
  return (
    data && (
      <RainbowCarouselSection
        title={data.title}
        items={data.items}
        description={data.description}
        ctaUrl={data.ctaUrl}
        ctaLabel={data.ctaLabel}
        flavor={flavor}
        image={familyTentJpg}
        noCrop
        largeMobileImg
      />
    )
  )
}
