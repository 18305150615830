import { useRef, useEffect } from 'react'
import { useHistory, useLocation } from '@fs/zion-router'

export default function useRouterHistory() {
  const history = useHistory()
  // comes from history.listen internal to react router
  const location = useLocation()
  const onPopRef = useRef()

  const onHistoryPop = (callback) => {
    onPopRef.current = callback
  }

  useEffect(() => {
    // Note: this will run on first render
    if (history.action === 'POP') {
      onPopRef.current?.(location)
    }
  }, [history.action, location])

  return { onHistoryPop, location, history }
}
