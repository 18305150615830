import { gql } from '@apollo/client'
import { OneOffPageDetail } from './fragments'
import { brightspotConfig } from '../../config'

const { defaults } = brightspotConfig.query

export default function getOneOffPage() {
  return gql(`
  ${OneOffPageDetail}
  query OneOffPageDetail(
    $path: ID,
    
    $leadPromoImage_crop: Boolean = false,
    $leadPromoImage_height: Int = ${defaults.imageSize},
    $leadPromoImage_width: Int = ${defaults.imageSize},
    
    $leadThumbnailImage_crop: Boolean = false,
    $leadThumbnailImage_height: Int = ${defaults.imageSize},
    $leadThumbnailImage_width: Int = ${defaults.imageSize},
    
    $leadProfileImage_crop: Boolean = false,
    $leadProfileImage_height: Int = ${defaults.imageSize},
    $leadProfileImage_width: Int = ${defaults.imageSize},
    
    $leadImage_crop: Boolean = false,
    $leadImage_height: Int = ${defaults.imageSize},
    $leadImage_width: Int = ${defaults.imageSize},
    
    $profileImage_crop: Boolean = false,
    $profileImage_height: Int = ${defaults.imageSize},
    $profileImage_width: Int = ${defaults.imageSize},

    $promoImage_crop: Boolean = false,
    $promoImage_height: Int = ${defaults.imageSize},
    $promoImage_width: Int = ${defaults.imageSize},
    
    $thumbnailImage_crop: Boolean = false,
    $thumbnailImage_height: Int = ${defaults.imageSize},
    $thumbnailImage_width: Int = ${defaults.imageSize}) {
    OneOffPageDetail(id: $path) {
      ...OneOffPageDetail
    }
  }
  `)
}
