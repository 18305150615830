import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import { colors } from '@fs/zion-ui'
import { Link } from '@fs/zion-router'
import { trackLink } from '@fs/zion-analytics'

const linkCss = css`
  &,
  &:active,
  &:visited,
  &:focus {
    color: ${colors.transparent.gray70} !important;
    font-size: 0.875rem;
    line-height: 1.35;
    margin-inline-end: 25px;
    display: inline-block;
    cursor: pointer;
    background: transparent;
    border: none;
    font-family: Verdana, Ayuthaya, sans-serif;
  }

  &:hover {
    text-decoration: underline;
    color: ${colors.transparent.gray70};
  }
`

const linkButtonCss = css`
  ${linkCss};
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
`

export default function NavLink({ id, href, to, text, external, linkName, onClick, ...props }) {
  const handleClick = (e) => {
    trackLink(e)
    onClick && onClick(e)
  }
  if (id === 'teconsent') {
    return <span id={id} css={linkButtonCss} {...props} />
  }
  if (!href && !to) {
    // see https://github.com/evcohen/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md
    return (
      <button
        id={id}
        type="button"
        css={linkButtonCss}
        data-link-name={linkName}
        onClick={linkName ? handleClick : undefined}
        {...props}
      >
        {text}
      </button>
    )
  }
  if (to) {
    return (
      <Link id={id} to={to} noLinkStyles css={linkCss} linkName={linkName} onClick={onClick} {...props}>
        {text}
      </Link>
    )
  }
  return (
    <a
      id={id}
      href={href}
      css={linkCss}
      data-link-name={linkName}
      onClick={linkName ? handleClick : undefined}
      {...props}
    >
      {text}
    </a>
  )
}

NavLink.propTypes = {
  href: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  id: PropTypes.string,
  to: PropTypes.string,
}
