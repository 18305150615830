import React, { useState } from 'react'
import { InfoSheet, H3, Paragraph, Separator, Select } from '@fs/zion-ui'
import RainbowText from '../../RainbowText'
import { removeDuplicatesByKey } from '../../helpers'
import { useCountryChange } from '../../../providers'

const getCountries = () => {
  const A = 65 // ASCII code for 'A'
  const Z = 90 // ASCII code for 'Z'

  const countryName = new Intl.DisplayNames(['en'], { type: 'region' })
  const countries = {}

  for (let i = A; i <= Z; i++) {
    for (let j = A; j <= Z; j++) {
      const code = String.fromCharCode(i) + String.fromCharCode(j)
      const name = countryName.of(code)

      // we send INTL a potential country code. If it is valid, it will return the
      // name of the country
      if (code !== name) {
        countries[code] = name
      }
    }
  }

  return countries
}

const ChangeCountryInfoSheet = ({ flavor, ...infoSheet }) => {
  const { setCountry } = useCountryChange()
  const [value, setValue] = useState()
  const onChange = (e) => {
    const newValue = e.target.value
    setCountry(newValue)
    setValue(newValue)
  }

  const countries = getCountries()
  const options = removeDuplicatesByKey(
    Object.entries(countries).map(([v, label]) => ({ value: v, label })),
    'label'
  ).sort((a, b) => a.label.localeCompare(b.label))

  return (
    <InfoSheet
      {...infoSheet}
      size="md"
      title={
        <H3 size="H4">
          <RainbowText flavor={flavor}>Change Country</RainbowText>
        </H3>
      }
    >
      <Paragraph size="sm">
        To change your virtual location, simply select your desired country from the dropdown menu.
      </Paragraph>
      <Separator />
      <Select label="Country" placeholder="Select a country" options={options} value={value} onChange={onChange} />
    </InfoSheet>
  )
}

export default ChangeCountryInfoSheet
