import axios from '@fs/zion-axios'
import { i18n } from '@fs/zion-locale'
import SERVICE from './serviceName'

// DOCS: /service/rootstech/call-for-content/contracts'

const BASE = `${SERVICE}/call-for-content/contracts`
const lang = i18n.language
const config = {
  headers: { 'Accept-Language': lang, 'Content-Language': lang, 'Content-Type': 'application/json' },
  data: {},
}

/**
 * Sign the Session Contract.
 * This service is used to sign the contract of the session
 *
 * @param {Object} body The user's session, example: { "sessionId" : "brightspotSessionId", "locale" : "usersLocale" }
 * @returns {Promise} A Promise resolving to the user's sign contract service
 */
export default async function postSessionContract(body) {
  return axios.post(`${BASE}`, body, config)
}
