import { useMemo, useCallback, useEffect, useState } from 'react'
import { useUser } from '@fs/zion-user'

import { getCurrentUser } from '../api'

const SUCCESSFUL = 200
const REDIRECTION = 300

export default function useUserData(disableCache) {
  const { signedIn } = useUser()
  const [currentUser, setCurrentUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const [refetching, setRefetching] = useState(false)

  const getData = useCallback(async () => {
    setRefetching(true)
    const response = await getCurrentUser(disableCache)
    if (response.status >= SUCCESSFUL && response.status < REDIRECTION) {
      setCurrentUser(response.data)
    }
    setRefetching(false)
  }, [disableCache])

  useEffect(() => {
    if (signedIn) {
      getData()
    } else {
      setLoading(false)
    }
  }, [getData, signedIn])

  return useMemo(
    () =>
      currentUser
        ? {
            ...currentUser,
            signedIn: true,
            refetchUser: getData,
            refetching,
            loading: loading || refetching,
          }
        : { loading: loading || refetching },
    [currentUser, getData, loading, refetching]
  )
}
