import React from 'react'
import { LayoutBand, useContainerWidth } from '@fs/zion-ui'
import ResponsiveSeparator from './ResponsiveSeparator'

export default function ResponsiveLayoutBand({ children, noTopSeparator, ...props }) {
  const atWidth = useContainerWidth()
  return (
    <>
      {!noTopSeparator ? <ResponsiveSeparator /> : null}
      <LayoutBand {...props} marginX={atWidth({ default: 'sm', md: 'md', lg: 'lg' })}>
        {children}
      </LayoutBand>
    </>
  )
}
