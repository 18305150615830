import React from 'react'
import { NoticeProtected, ThingTimer, PlaceWorld, NoticeSuggestion } from '@fs/zion-icon'
import { List, ListItem, QuickGlanceOverlay, useInfoSheet, useOverlay } from '@fs/zion-ui'
import TimeTravelInfoSheet from './TimeTravelInfoSheet'
import ChangeCountryInfoSheet from './ChangeCountryInfoSheet'
import ChangePermissionsInfoSheet from './ChangePermissionsInfoSheet'
import FixedButton from './FixedButton'

const QAInfoSheets = () => {
  const overlay = useOverlay()
  const timeTravelInfoSheet = useInfoSheet()
  const countryInfoSheet = useInfoSheet()
  const permissionsInfoSheet = useInfoSheet()

  const onItemClick = (e, handleClick) => {
    handleClick(e)
    overlay.handleClick(e)
  }
  const flavor = 'dusk'

  return (
    <>
      <FixedButton
        ariaLabel="Open Quality Assurance Options"
        Icon={NoticeProtected}
        onClick={overlay.handleClick}
        flavor={flavor}
      />
      <QuickGlanceOverlay {...overlay}>
        <List>
          <ListItem
            startElement={<ListItem.Icon Icon={ThingTimer} />}
            primaryText="Time Travel"
            onClick={(e) => onItemClick(e, timeTravelInfoSheet.handleClick)}
          />
          <ListItem
            startElement={<ListItem.Icon Icon={PlaceWorld} />}
            primaryText="Change Country"
            onClick={(e) => onItemClick(e, countryInfoSheet.handleClick)}
          />
          <ListItem
            startElement={<ListItem.Icon Icon={NoticeSuggestion} />}
            primaryText="Change Permissions"
            onClick={(e) => onItemClick(e, permissionsInfoSheet.handleClick)}
          />
        </List>
      </QuickGlanceOverlay>

      <TimeTravelInfoSheet flavor={flavor} {...timeTravelInfoSheet} />
      <ChangeCountryInfoSheet flavor={flavor} {...countryInfoSheet} />
      <ChangePermissionsInfoSheet flavor={flavor} {...permissionsInfoSheet} />
    </>
  )
}

export default QAInfoSheets
