const AttachmentDetail = `
  fragment AttachmentDetail on AttachmentDetail {
    contentLocale
    description
    id
    title
    url
  }
`

export default AttachmentDetail
