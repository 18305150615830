import React from 'react'
import { css } from '@emotion/core'
import PropTypes from 'prop-types'
import { useIsMdWidth } from '../../hooks'

const sectionContainerCss = css`
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: auto;
  width: 100%;
`

export default function SectionContainer({ children, ...props }) {
  const isMdWidth = useIsMdWidth()
  return (
    <section css={sectionContainerCss} style={{ borderRadius: isMdWidth ? 16 : 32 }} {...props}>
      {children}
    </section>
  )
}

SectionContainer.propTypes = {
  children: PropTypes.node.isRequired,
}
