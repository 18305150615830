// https://fhconfluence.churchofjesuschrist.org/x/hYFtE
import axios from '@fs/zion-axios'
import SERVICE from './serviceName'

const BASE = `${SERVICE}/dashboard-authorizer/`
const STATE = `state`

// GET
export const getAccessRequisitions = async () => axios.get(BASE)
export const getAccessRequisition = async (id) => axios.get(`${BASE}/${id}`)
export const getCurrentUserAccessRequisition = async (cisId) => axios.get(`${BASE}?cisId=${cisId}`)

export const getAccessRequisitionsEntries = async (params) =>
  axios.get(
    `${BASE}?${Object.keys(params)
      .map((key) => {
        return `${key}=${encodeURIComponent(params[key])}`
      })
      .join('&')}`
  )

// DELETE
export const removeAccessRequisitionEntry = async (id) => axios.delete(`${BASE}/${id}`)

// PUT
// state values: PENDING APPROVED DECLINED REVOKED CANCELED
export const setAccessRequisitionState = async (id, accessState) =>
  axios.put(`${BASE}${id}/${STATE}`, { state: accessState })

// POST
export const addAccessRequisitionEntry = async ({ duration, expiration, group }) =>
  axios.post(BASE, { duration, expiration, group })
