import React from 'react'
import { InlineButton } from '@fs/zion-ui'
import { FormTextField } from '@fs/zion-form'
import { Trans, useTranslation } from 'react-i18next'

export default function MobileNumberField() {
  const [t] = useTranslation()
  return (
    <FormTextField
      name="mobile"
      label={`${t('form.mobile-number.label', 'Mobile Number')}:`}
      helpMessage={
        <Trans i18nKey="form.mobile-number.help-message">
          Message and data rates may apply. See{' '}
          <InlineButton
            to="https://www.familysearch.org/legal/text-messaging-terms-and-conditions"
            linkName="Form: Mobile Number: Messaging rates"
            target="_blank"
            external
          >
            Text Messaging Terms and Conditions
          </InlineButton>
          .
        </Trans>
      }
    />
  )
}
