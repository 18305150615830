import { brightspotConfig } from '../../config'
import { getEnv } from './urlHelpers'

const configs = brightspotConfig.lane

export default function getConfig() {
  const configTarget = getEnv()
  const config = configs[configTarget]
  return config
}
