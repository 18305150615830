// import { useState, useEffect } from 'react'

const getNavigatorConnection = () => {
  if (typeof navigator !== 'object') {
    return undefined
  }
  return navigator.connection || navigator.mozConnection || navigator.webkitConnection
}

const getNetworkInformation = () => {
  const connectionObject = getNavigatorConnection()
  if (!connectionObject) {
    return {
      supported: false,
    }
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation
  return {
    supported: true,
    ...connectionObject,
  }
}

// expose a pure javascript function for apis
const getIsLowBandwidth = () => {
  const connection = getNetworkInformation()

  if (!connection?.supported) return false

  // If datasave mode is on or if download speed is less than 2Mbps
  return connection?.downlink < 2 || connection?.saveData
}

export default getIsLowBandwidth
