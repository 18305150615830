import { useEffect } from 'react'

const stopUserWithPrompt = (e) => {
  e.preventDefault()
  e.returnValue = ''
}

export default function useWaitDontGo(shouldStopUser) {
  useEffect(() => {
    if (shouldStopUser) {
      window.addEventListener('beforeunload', stopUserWithPrompt)
    }
    return () => {
      window.removeEventListener('beforeunload', stopUserWithPrompt)
    }
  }, [shouldStopUser])
}
