const registrationConfig = {
  defaultPromoVideos: {
    de: 'XdIxhuY_zlg',
    en: 'zzGzbHoIFhk',
    es: 'tCxu5nMw1sU',
    fr: 'p_hqf6USeWk',
    it: '7mA31fxTmAg',
    ja: 'nGqPJGTATV4',
    ko: 'UT4m5iFnBgE',
    mn: 'zzGzbHoIFhk', // english for now since there is no mn translation
    pt: 'IICNZSgN528',
    ru: 'csiutIuVFz8',
    zh: 'T03P9Z3aJyw',
    'zh-hans': 'ZGrheJKq3w8',
  },
  discoveryPromoVideos: {
    de: 'XdIxhuY_zlg',
    en: 'CKikmJYJFvY',
    es: 'tCxu5nMw1sU',
    fr: 'p_hqf6USeWk',
    it: '7mA31fxTmAg',
    ja: 'nGqPJGTATV4',
    ko: 'UT4m5iFnBgE',
    mn: 'zzGzbHoIFhk', // english for now since there is no mn translation
    pt: 'IICNZSgN528',
    ru: 'csiutIuVFz8',
    zh: 'T03P9Z3aJyw',
    'zh-hans': 'ZGrheJKq3w8',
  },
  influencerPromoVideos: {
    kenna: '',
    batchelor: '',
    tykena: 'e8XsAqTGrR4',
    sarah: 'FseV9zNVyd8',
    tyler: '',
    tj: 'TjMYX8x5MFI',
    daisy: '',
    scott: 'iFhfpwabrtY',
    carol: '',
    kirby: 'DDN3EPlwpuU',
    'kirby-es': 'moJCATwgHwM',
    speakers: '2Io1dwWhvZM',
  },
  youngAdultVideos: {
    default: 'AewTI9WnQvA',
  },
  attendeePricing: {
    promo: {
      threeDay: 99,
      oneDay: 69,
    },
    standard: {
      threeDay: 129,
      oneDay: 79,
    },
  },
}

export default registrationConfig
