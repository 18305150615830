const relativesConfig = {
  eventIds: {
    INT: 'cabf8cce-5788-4429-af0f-a1bb6ad3fe44',
    BETA: '8ceedfe4-9720-4292-be42-72f9e1da842d',
    PROD: 'c808520d-0660-4c10-96b7-7241e6a3acff',
  },
  previewUrl: 'https://www.familysearch.org/en/connect/cabf8cce-5788-4429-af0f-a1bb6ad3fe44',
}

export default relativesConfig
