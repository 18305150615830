import React from 'react'
import { css } from '@emotion/core'
import { LayoutBand, Row, colors, Paragraph, Button } from '@fs/zion-ui'
import { NoticeInfo, MenuSettings, NoticeWarning, NoticeImportant } from '@fs/zion-icon'
import { PillButton } from '../../buttons'
import { useLocalStorage } from '../../../hooks'

const boldCss = css`
  & button,
  & a {
    font-weight: bold;
  }
`

const fullWidthCss = css`
  & * {
    max-width: 100%;
  }
`

const BannerText = ({ children, isMarketing, textSize, fullWidth }) =>
  isMarketing ? (
    <Paragraph>
      <span
        css={css`
          font-weight: bold;
        `}
      >
        {children}
      </span>
    </Paragraph>
  ) : (
    <div css={fullWidth && fullWidthCss}>
      <Paragraph size={textSize}>{children}</Paragraph>
    </div>
  )

const ConfirmButton = ({ confirmProps, isMarketing, BannerButton }) =>
  !confirmProps.hidden ? (
    <div css={!isMarketing && boldCss}>
      <BannerButton {...confirmProps} />
    </div>
  ) : null

const DismissButton = ({ dismissProps, setDismissed }) =>
  !dismissProps.hidden ? (
    <div css={boldCss}>
      <Button
        {...dismissProps}
        onClick={() => {
          dismissProps.onClick?.()
          setDismissed(true)
        }}
      />
    </div>
  ) : null

const SiteBanner = ({
  heading,
  confirmProps = { hidden: true },
  dismissProps = { hidden: true },
  storageKey,
  isMarketing,
  type,
  textSize,
  fullWidth = false,
}) => {
  const [dismissed, setDismissed] = useLocalStorage(storageKey, false)
  if (dismissed) {
    return null
  }

  const BannerButton = isMarketing ? PillButton : Button

  return (
    <LayoutBand color={colors.gray02} marginY="xxs">
      <Row alignY="middle" wrap guttersY="nano">
        <Row guttersX="lg">
          {type === 'info' && <NoticeInfo background={colors.blue10} />}
          {type === 'maintenance' && <MenuSettings background={colors.yellow10} />}
          {type === 'warning' && <NoticeWarning background={colors.yellow60} />}
          {type === 'error' && <NoticeImportant background={colors.danger60} />}
          <Row alignY="middle">
            <BannerText isMarketing={isMarketing} textSize={textSize} fullWidth={fullWidth}>
              {heading}
            </BannerText>
          </Row>
        </Row>
        <Row alignX="end" growX>
          <ConfirmButton confirmProps={confirmProps} isMarketing={isMarketing} BannerButton={BannerButton} />
          <DismissButton dismissProps={dismissProps} setDismissed={setDismissed} />
        </Row>
      </Row>
    </LayoutBand>
  )
}

export default SiteBanner
