import { attachmentDefaultJpg, profileDefaultJpg, videoPlaceholder2025FireJpg } from '../images'

const imageConfig = {
  defaultPlaceholder:
    'https://familysearch.brightspotcdn.com/2f/2f/586600234bf88f8bd62f95acc2d9/rootstechdefaultplaceholder.png',
  defaultPlaceholder2025: videoPlaceholder2025FireJpg,
  defaultProfilePlaceholder: profileDefaultJpg,
  none: null,
  attachmentPlaceholder: attachmentDefaultJpg,
  size: {
    small: 350,
    medium: 500,
    large: 700,
  },
  videoThumbnail: {
    lowBandwidth: { height: 1080, width: 1920 },
    containerWidth: {
      xs: { height: 1080, width: 1920 },
      sm: { height: 1080, width: 1920 },
      md: { height: 1080, width: 1920 },
      lg: { height: 1080, width: 1920 },
      xl: { height: 1080, width: 1920 },
      xxl: { height: 1080, width: 1920 },
    },
  },
  profilePhoto: {
    lowBandwidth: { height: 250, width: 250 },
    containerWidth: {
      xs: { height: 250, width: 250 },
      sm: { height: 500, width: 500 },
      md: { height: 500, width: 500 },
      lg: { height: 500, width: 500 },
      xl: { height: 500, width: 500 },
      xxl: { height: 500, width: 500 },
    },
  },
  vendorLogo: {
    lowBandwidth: { height: 250, width: 250 },
    containerWidth: {
      xs: { height: 250, width: 250 },
      sm: { height: 500, width: 500 },
      md: { height: 500, width: 500 },
      lg: { height: 500, width: 500 },
      xl: { height: 500, width: 500 },
      xxl: { height: 500, width: 500 },
    },
  },
}

export default imageConfig
