import axios from '@fs/zion-axios'
import SERVICE from './serviceName'

// DOCS: https://integration.familysearch.org/service/fs-chat/api/resource_ApprovalEndpointsV2.html

const BASE = `${SERVICE}/v2/approval/channels`

/**
 * Check if the calling user can create/exist/modify channel memberships associated with the provided list of cisIds.
 * @param {Object} data The parameters for checking approval. A list of 10 or less cisIds. {"cisIds": [ "...", "..." ]}
 * @param {Boolean} forceForbidden Optional temporary parameter that will force 403 to be returned.
 * @returns {Promise} A Promise resolving to the user's approval state
 */
export default function postUserApproval(data, forceForbidden = false) {
  return axios
    .post(`${BASE}/membership`, data, {
      params: {
        forceForbidden,
      },
    })
    .catch((error) => {
      return error.response
    })
}
