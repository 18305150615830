import React, { createContext, useContext } from 'react'
import { useQuery } from '@apollo/client'
import { getLocales } from '../api'
import useErrorHandlerOverlay from '../hooks/useErrorHandlerOverlay'
import { languageConfig } from '../config'

export const LocalesContext = createContext()
export const useLocalesContext = () => useContext(LocalesContext)

const useLocalesData = () => {
  const { loading, data, error } = useQuery(getLocales())
  useErrorHandlerOverlay(error)

  let locales = data
  if (!loading && data) {
    const unsortedLocales = [...data.ConferenceDataViewModel.locales]
    const sortedLocales = unsortedLocales
      .sort((first, second) => `${first.label}`.localeCompare(second.label))
      .map(({ displayLanguage, ...locale }) => {
        const chineseLocales = languageConfig.chineseLocales
        const label = chineseLocales.includes(locale.value) ? locale.label : displayLanguage
        return { ...locale, label }
      })
    locales = sortedLocales
  }

  return { loading, locales, error }
}

export function LocalesProvider({ children }) {
  const { loading, locales, error } = useLocalesData()

  return (
    <LocalesContext.Provider
      // FIXME: This is a temporary fix. We need to remove this eslint-disable line and fix the issue.
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ loading, locales, error }}
    >
      {children}
    </LocalesContext.Provider>
  )
}
