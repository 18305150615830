import React, { useState } from 'react'
import { ExpandableListItem, ListItem } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { NoticeQuestion } from '@fs/zion-icon'
import HelpItems from './HelpItems'

const HelpExpandableList = ({ dense, onClick }) => {
  const [t] = useTranslation()
  const [open, setOpen] = useState(false)

  return (
    <ExpandableListItem
      primaryText={t('help.title', 'Help')}
      startElement={<ListItem.Icon Icon={NoticeQuestion} />}
      data-testid="menu-help"
      dense={dense}
      open={open}
      setOpen={setOpen}
      linkName={`Help: ${open ? 'open' : 'close'}`}
      selected={open}
    >
      <HelpItems dense={dense} onClick={onClick} />
    </ExpandableListItem>
  )
}

export default HelpExpandableList
