import { useEffect } from 'react'
import useLazyRequest from './useLazyRequest'

const initialState = {
  loading: true,
  data: null,
  error: null,
}

export default function useRequest(requestFunction) {
  const [request, state] = useLazyRequest(requestFunction, initialState)

  useEffect(() => {
    request()
  }, [request])

  return state
}
