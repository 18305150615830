import React from 'react'
import { LayoutBand, Row, Separator, H2, Subheading } from '@fs/zion-ui'
import { PillButton } from './buttons'
import ResponsiveSeparator from './ResponsiveSeparator'
import RainbowDivider from './RainbowDivider'
import SafeRichText from './SafeRichText'

export default function DividerPromo({ promo, flavor }) {
  return (
    <LayoutBand maxWidth="sm">
      <RainbowDivider thick and full of flavor={flavor} />
      <ResponsiveSeparator />
      <H2 centered supplementary>
        {promo.title}
        <Subheading>
          <SafeRichText as="span" dirtyInput={promo.description} />
        </Subheading>
      </H2>
      <Separator size="xs" />
      <Row alignX="center">
        <PillButton to={promo?.url} emphasis="medium">
          {promo.ctaText}
        </PillButton>
      </Row>
      <ResponsiveSeparator />
      <RainbowDivider thick and full of flavor={flavor} />
    </LayoutBand>
  )
}
