import { useState, useRef, useLayoutEffect } from 'react'

const useElement = () => {
  const [state, setState] = useState({})
  const ref = useRef(null)

  useLayoutEffect(() => {
    let isCurrent = true
    setTimeout(() => isCurrent && setState(ref.current))

    return () => {
      isCurrent = false
    }
  }, [])

  return [ref, state]
}

export default useElement
