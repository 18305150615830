import ConferenceCustomImageCrop from './ConferenceCustomImageCrop'
import ImageAttributes from './ImageAttributes'
import ContentVisibility from './ContentVisibility'

const buildImageDetailFragment = (varPrefix) => {
  return `
    ${ConferenceCustomImageCrop}
    ${ContentVisibility}
    ${ImageAttributes}
    fragment ${varPrefix.charAt(0).toUpperCase() + varPrefix.slice(1)} on ImageDetail {
      alt
      attributes {
        ...ImageAttributes
      }
      contentLocale
      custom(crop: $${varPrefix}_crop, height: $${varPrefix}_height, width: $${varPrefix}_width) {
        ...ConferenceCustomImageCrop
      }
      id
      source
      url
      visibility {
        ...ContentVisibility
      }
      __typename
    }
  `
}
export const ImageDetail = buildImageDetailFragment('imageDetail')
export const ThumbnailImage = buildImageDetailFragment('thumbnailImage')
export const ProfileImage = buildImageDetailFragment('profileImage')
export const PromoImage = buildImageDetailFragment('promoImage')

export const LeadThumbnailImage = buildImageDetailFragment('leadThumbnailImage')
export const LeadPromoImage = buildImageDetailFragment('leadPromoImage')
export const LeadProfileImage = buildImageDetailFragment('leadProfileImage')
export const LeadImage = buildImageDetailFragment('leadImage')
// LeadLogo?
// LeadVideo?

export const ExpoThumbnailImage = buildImageDetailFragment('expoThumbnailImage')

export const GraphicsImage = buildImageDetailFragment('graphicsImage')
export const LogoImage = buildImageDetailFragment('logoImage')
