import React from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from '@fs/zion-ui'
import { MenuSearch } from '@fs/zion-icon'
import RainbowRibbonBand from '../RainbowRibbonBand'
import NoContentFound from '../NoContentFound'
import { PillButton } from '../buttons'

export default function NoLandingPageFound({ rootPath, flavor }) {
  const [t] = useTranslation()
  return (
    <>
      <RainbowRibbonBand flavor={flavor} />
      <NoContentFound
        noBackButton={rootPath === '/'}
        heading={t('landing.not-found.heading', 'No Page Found')}
        subHeading={t(
          'landing.not-found.subHeading',
          "We're sorry the link is broken for this page, try searching other landing pages."
        )}
        actions={
          <Row alignX="center">
            <PillButton Icon={MenuSearch} to={rootPath} linkName="Landing Pages" emphasis="medium">
              {t('landing.search.action', 'Landing Pages')}
            </PillButton>
          </Row>
        }
      />
    </>
  )
}
