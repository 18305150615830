import React, { Suspense, useState } from 'react'
import { CHAT_ROUTE_KEY, EmbeddedChat, buildChatRoute } from '@fs/zion-chat'
import ErrorBoundary from '@fs/zion-error-boundary'
import { useFeatureFlag } from '@fs/zion-flags'
import { useUser } from '@fs/zion-user'
import { Row } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { trackLink } from '@fs/zion-analytics'
import EmbeddedRTChatFallback from './EmbeddedRTChatFallback'
import { PillButton } from '../buttons'
import SignInButton from '../../components/header/SignInButton'
import LoadingSpinner from '../LoadingSpinner'

const EmbeddedRTChat = ({ supportChatInformation, onClose }) => {
  const embeddedChatFlag = useFeatureFlag('rootstech_embedded_chat')
  const chatFlag = useFeatureFlag('rootstech_chat')
  const joinEmbeddedChatFlag = useFeatureFlag('rootstech_join_embedded_chat')
  const { signedIn } = useUser()
  const [joined, setJoined] = useState()
  const [t] = useTranslation()

  if (!embeddedChatFlag.isOn || !chatFlag.isOn) {
    return null
  }

  if (!signedIn) {
    return (
      <EmbeddedRTChatFallback onClose={onClose}>
        <Row alignX="center">
          <SignInButton linkName="EmbeddedChat: Sign In" emphasis="medium">
            {t('embedded-chat.signed-out.button', 'Join The Discussion')}
          </SignInButton>
        </Row>
      </EmbeddedRTChatFallback>
    )
  }

  if (!joined && joinEmbeddedChatFlag.isOn) {
    const onClick = () => {
      setJoined(true)
      trackLink({ name: 'EmbeddedChat: Join' })
    }
    return (
      <EmbeddedRTChatFallback onClose={onClose}>
        <Row alignX="center">
          <PillButton emphasis="medium" onClick={onClick}>
            {t('embedded-chat.signed-out.button', 'Join The Discussion')}
          </PillButton>
        </Row>
      </EmbeddedRTChatFallback>
    )
  }

  return (
    // I think the embedded chat is lazy loaded outside of our app
    <Suspense fallback={<LoadingSpinner />}>
      <ErrorBoundary>
        {/* the embedded chat looks better when wrapped in a div */}
        <div>
          <EmbeddedChat
            supportChatInformation={supportChatInformation}
            chatRoute={buildChatRoute(CHAT_ROUTE_KEY.CREATE_SESSION_CHAT)}
          />
        </div>
      </ErrorBoundary>
    </Suspense>
  )
}

export default EmbeddedRTChat
