import React from 'react'
import YouTube from 'react-youtube'
import { css } from '@emotion/core'
import { useYoutubeAnalytics } from '@fs/zion-analytics'
import PropTypes from 'prop-types'
import { i18n } from '@fs/zion-locale'

const contentCss = css`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`
const videoWrapperCss = css`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  box-shadow: 0px 0px 48px #000000bf;
`
const youtubeCss = css`
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
`
export default function YoutubePlayer({
  videoId,
  autoplay = false,
  disableControls = false,
  seekStartTime = 0,
  cmsId,
  isInPlaylist,
  title = '',
  isLive = false,
  ...props
}) {
  const onAnalyticsStateChange = useYoutubeAnalytics(isLive)
  const opts = {
    height: '100%',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: autoplay ? 1 : 0,
      start: seekStartTime,
      hl: i18n.language,
      cc_lang_pref: i18n.language,
      controls: disableControls ? 0 : 1,
      modestbranding: 1,
      rel: 0,
    },
  }

  const onStateChange = (event) => {
    onAnalyticsStateChange(event)
  }

  return (
    <div style={{ aspectRatio: '16/9', width: '100%' }}>
      <div css={contentCss}>
        <div css={videoWrapperCss}>
          <YouTube
            css={youtubeCss}
            videoId={videoId}
            opts={opts}
            onStateChange={onStateChange}
            title={title}
            {...props}
          />
        </div>
      </div>
    </div>
  )
}

YoutubePlayer.propTypes = {
  videoId: PropTypes.string,
  seekStartTime: PropTypes.number,
  cmsId: PropTypes.string,
  isInPlaylist: PropTypes.bool,
  autoplay: PropTypes.bool,
  disableControls: PropTypes.bool,
  title: PropTypes.string,
}
