import CalendarItemDetail from './CalendarItemDetail'
import ContentVisibility from './ContentVisibility'

const StageDetail = `
  ${CalendarItemDetail}
  ${ContentVisibility}
  fragment StageDetail on StageDetail {
    title
    calendarItems {
      ...CalendarItemDetail
    }
    visibility {
      ...ContentVisibility
    }
  }
`
export default StageDetail
