import React from 'react'
import { HorizontalFlowGrid } from '@fs/zion-ui'
import { HorizontalScroller } from '@fs/zion-ui/quarks-authorized-use-only'

export default function CardCarousel({ children, height }) {
  return (
    <HorizontalScroller inLayoutBand buttonSize="md" buttonEmphasis="high">
      <HorizontalFlowGrid style={{ height }} widthEstimate={250} keepVisible>
        {children}
      </HorizontalFlowGrid>
    </HorizontalScroller>
  )
}
