import React, { Children } from 'react'
import { Separator, Paragraph } from '@fs/zion-ui'
import NoBulletList from '../NoBulletList'
import BulletList from '../BulletList'
import SafeRichText from '../SafeRichText'
import BorderCard from './BorderCard'

export default function BorderPromoCard({ heading, highlightItem, flavor, listItems, actions, bullets, description }) {
  const List = bullets ? BulletList : NoBulletList

  return (
    <BorderCard flavor={flavor} heading={heading} topSeparator>
      {highlightItem && (
        <Paragraph>
          <strong>{highlightItem}</strong>
        </Paragraph>
      )}
      {listItems ? (
        <List growY>
          {Children.toArray(
            listItems.map((item) => (
              <Paragraph as="li" size="sm">
                {item}
              </Paragraph>
            ))
          )}
        </List>
      ) : (
        <SafeRichText size="sm" dirtyInput={description} />
      )}
      <Separator />
      {actions}
    </BorderCard>
  )
}
