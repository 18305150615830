import { useCallback, useState } from 'react'
import useErrorHandlerOverlay from '../useErrorHandlerOverlay'
import useStableObject from '../useStableObject'

const initialState = {
  loading: false,
  data: null,
  error: null,
}

export default function useLazyRequest(requestFunction, optionalState) {
  const stableInitialState = useStableObject(optionalState || initialState)
  const [state, setState] = useState(stableInitialState)
  useErrorHandlerOverlay(state.error)

  const functionCallback = useCallback(
    async (...args) => {
      try {
        setState(stableInitialState)
        const response = await requestFunction(...args)
        setState(response)
        return response
      } catch (error) {
        setState({ ...error.response, error })
        throw error
      }
    },
    [requestFunction, stableInitialState]
  )

  return [functionCallback, state]
}
