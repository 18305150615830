// countries are ISO 3166-1 alpha-2 format: https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes

// The linter made this object take up way too much space
const territories = {
  africa: {
    north: ['MA', 'DZ', 'LY', 'EG', 'EH', 'MR', 'SD', 'ER', 'DJ', 'ET', 'SO'],
    // eslint-disable-next-line prettier/prettier
    south: ['AO', 'BJ', 'BW', 'BF', 'BI', 'CM', 'CV', 'CF', 'TD', 'KM', 'CG', 'CD', 'CI', 'GQ', 'SZ', 'GA', 'GM', 'GH', 'GN', 'GW', 'KE', 'LS', 'LR', 'MG', 'MW', 'ML', 'MU', 'YT', 'MZ', 'NA', 'NE', 'NG', 'RE', 'RW', 'ST', 'SN', 'SC', 'SL', 'ZA', 'SS', 'SH', 'TZ', 'TG', 'UG', 'ZM', 'ZW'],
  },
  // eslint-disable-next-line prettier/prettier
  middleEast: ['SY', 'IQ', 'SA', 'YE', 'OM', 'AE', 'KW', 'IR', 'AF', 'TR', 'GE', 'AZ', 'JO', 'LB', 'AM', 'PK'],
  america: {
    north: ['CA', 'US', 'MX'],
    central: ['BZ', 'CR', 'SV', 'GT', 'HN', 'NI', 'PA'],
    south: ['AR', 'BO', 'BR', 'CL', 'CO', 'EC', 'GY', 'PY', 'PE', 'SR', 'UY', 'VE'],
  },
  // eslint-disable-next-line prettier/prettier
  caribbean: ['AG', 'AW', 'BS', 'BB', 'BM', 'BQ', 'VG', 'KY', 'CU', 'CW', 'DM', 'DO', 'GD', 'GP', 'HT', 'JM', 'MQ', 'MS', 'PR', 'BL', 'KN', 'LC', 'MF', 'VC', 'SX', 'TT', 'TC', 'VI'],
  pacificIslands: ['AU', 'FJ', 'PG', 'NZ', 'SB', 'VU', 'WS', 'TO', 'TV', 'KI', 'NR', 'MH', 'PW', 'FM'],
  britishIrishIsles: ['GB', 'IE'],
}

// These are manually translated. Also there are languages in this file that are not in our core languages, useTranslations would not work.
const noThanks = {
  en: 'No Thanks',
  es: 'No gracias',
  fr: 'Non merci',
  pt: 'Ignorar',
  ar: 'ًلا شكرا',
  ms: 'Tidak, terima Kasih',
}

const goToPage = {
  en: 'Go To Page',
  es: 'Ir a la página',
  fr: 'Aller à la page',
  pt: 'Ver a página',
  ar: 'إذهب إلى الصّفحة',
  ms: 'Pergi Ke Laman',
}

const regionPages = [
  // Africa
  {
    url: '/regions/rootstech-africa',
    countries: territories.africa.south,
    languages: ['en'],
    heading: 'The RootsTech Africa page has content for your area. Do you want to view this page, which is in English?',
    confirmText: goToPage.en,
    dismissText: noThanks.en,
  },
  { url: '/regions/rootstech-afrique-francais', countries: territories.africa.south, languages: ['fr'] },
  { url: '/regions/rootstech-africa-portugues', countries: territories.africa.south, languages: ['pt'] },

  // MENA
  {
    url: '/regions/rootstech-mena',
    countries: [...territories.africa.north, ...territories.middleEast],
    languages: ['en'],
    heading:
      'The MENA RootsTech page has content for your area. Do you want to view this page, which has content in English and Arabic?',
    confirmText: goToPage.en,
    dismissText: noThanks.en,
  },
  {
    url: '/regions/rootstech-mena',
    countries: [...territories.africa.north, ...territories.middleEast],
    languages: ['ar'],
    heading: 'تتضمن صفحة MENA RootsTech معلومات باللغة العربية. هل تريد استخدام الصفحة باللغة العربية؟', // Translation given, but mixed directional text flips the string
    confirmText: goToPage.ar,
    dismissText: noThanks.ar,
  },

  // Latin America
  {
    url: '/regions/rootstech-latinoamerica',
    countries: [...Object.values(territories.america).flat(), ...territories.caribbean],
    languages: ['es'],
    heading: 'La página Latinoamérica tiene contenido para su área. ¿Desea ver esta página, que está en español?',
    confirmText: goToPage.es,
    dismissText: noThanks.es,
  },
  {
    url: '/regions/rootstech-brasil',
    countries: [...Object.values(territories.america).flat(), ...territories.caribbean],
    languages: ['pt'],
    heading: 'A página Brasil tem conteúdos para a sua área. Pretende ir para essa página, que está em português?',
    confirmText: goToPage.pt,
    dismissText: noThanks.pt,
  },

  // Pacific
  {
    url: '/regions/rootstech-australia',
    countries: ['AU'],
    languages: ['en'],
    heading:
      'The RootsTech Australia page has content for your area. Do you want to view this page, which is in English?',
    confirmText: goToPage.en,
    dismissText: noThanks.en,
  },
  {
    url: '/regions/rootstech-new-zealand',
    countries: ['NZ'],
    languages: ['en'],
    heading:
      'The RootsTech New Zealand page has content for your area. Do you want to view this page, which is in English?',
    confirmText: goToPage.en,
    dismissText: noThanks.en,
  },
  {
    url: '/regions/rootstech-pacific-islands',
    countries: territories.pacificIslands,
    languages: ['en'],
    heading:
      'The RootsTech Pacific Islands page has content for your area. Do you want to view this page, which is in English?',
    confirmText: goToPage.en,
    dismissText: noThanks.en,
  },
  {
    url: '/regions/rootstech-philippines',
    countries: ['PH'],
    languages: ['en'],
    heading: 'The RootsTech Philippines page has content for your area in Filipino. Do you want to go to the page?',
    confirmText: goToPage.en,
    dismissText: noThanks.en,
  },

  // Europe
  {
    url: '/regions/rootstech-uk-ireland',
    countries: territories.britishIrishIsles,
    languages: ['en'],
    heading:
      'The RootsTech U.K. and Ireland page has content for your area. Do you want to view this page, which is in English?',
    confirmText: goToPage.en,
    dismissText: noThanks.en,
  },

  // Asia
  {
    url: '/regions/isi-bahasa-melayu-malay',
    languages: ['ms'],
    heading:
      'Laman web Isi Bahasa Melayu mempunyai kandungan untuk kawasan anda. Adakah anda mahu melihat laman web ini, yang terdapat dalam Bahasa Melayu?',
    confirmText: goToPage.ms,
    dismissText: noThanks.ms,
  },

  // Order matters, if they don't qualify for any of the above, they may qualify for this one
  // Generic
  {
    url: '/regions/',
    languages: ['es'],
    heading: 'RootsTech tiene páginas personalizadas para muchas áreas del mundo. Mira la lista ahora.',
    confirmText: goToPage.es,
    dismissText: noThanks.es,
  },
  {
    url: '/regions/',
    languages: ['fr'],
    heading:
      'RootsTech propose des pages personnalisées pour de nombreuses régions du monde. Consulter la liste dès maintenant.',
    confirmText: goToPage.fr,
    dismissText: noThanks.fr,
  },
  {
    url: '/regions/',
    languages: ['pt'],
    heading: 'A RootsTech tem páginas personalizadas para muitas áreas do mundo. Veja a lista agora.', // need final text
    confirmText: goToPage.pt,
    dismissText: noThanks.pt,
  },
]

export default regionPages
