import React from 'react'
import { Row, Paragraph, useContainerWidth } from '@fs/zion-ui'
import { ContentDownload } from '@fs/zion-icon'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { PillButton } from '../buttons'

export default function DownloadableResource({ url = '', title = 'Brochure' }) {
  const [t] = useTranslation()
  const atWidth = useContainerWidth()
  return (
    <Row wrap guttersY="none" alignX={atWidth({ default: 'center', md: 'start' })}>
      <PillButton to={url} linkName={`Download Resource: ${title}`} emphasis="medium" Icon={ContentDownload}>
        {t('download.label', 'Download')}
      </PillButton>
      <Paragraph size="sm" noGrowY centered={atWidth({ default: true, md: false })} alignY="middle">
        {title}
      </Paragraph>
    </Row>
  )
}

DownloadableResource.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
}
