import { geoData } from '@fs/zion-config'
import { i18n } from '@fs/zion-locale'
import { regionConfig } from '../../../../config'
import { getBrowserLanguage } from '../../../helpers'

const getIsSupported = (array, item) => {
  if (!array || array.includes(item)) {
    return true
  }
  return false
}

const getRegion = (country, language) => {
  // There's a possibility that a user can apply to multiple regions,
  // return an array instead of an object
  return regionConfig.filter(({ countries, languages }) => {
    // if countries or language is absent, apply to everything
    const isInCountry = getIsSupported(countries, country)
    const isInLanguage = getIsSupported(languages, language)

    return isInCountry && isInLanguage
  })
}

const getApplicableRegionPages = (overriddenCountry) => {
  const { country: geoCountry } = geoData
  const country = overriddenCountry || geoCountry

  const appApplicablePages = getRegion(country, i18n.language)

  // do the search again for the browser language
  if (appApplicablePages.length === 0) {
    const language = getBrowserLanguage()
    return getRegion(country, language)
  }

  return appApplicablePages
}

export default getApplicableRegionPages
