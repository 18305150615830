import React from 'react'
import { css } from '@emotion/core'
import { LayoutBand } from '@fs/zion-ui'
import { flavors, scrimGradient } from '../../config'

const gradientCss = css`
  background-position: right;
  background-size: 50%;
  background: var(--scrim), var(--gradient);
`

export default function RainbowBand({ flavor = flavors.default, noScrim, children, maxWidth = 'lg', ...props }) {
  return (
    <LayoutBand
      maxWidth={maxWidth}
      style={{
        '--scrim': noScrim ? flavors.flavorless.filmedGradient : scrimGradient,
        '--gradient': flavors[flavor].gradient,
      }}
      css={gradientCss}
      {...props}
    >
      {children}
    </LayoutBand>
  )
}
