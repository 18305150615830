import Pagination from './Pagination'
import { MinimumCreatorDetail } from './ConferencePromo'
import { MinimumExhibitorDetail } from './ExhibitorDetail'
import { MinimumSeriesDetail } from './SeriesDetail'
import { MinimumSessionDetail } from './SessionDetail'

const ConferenceSiteSearch = `
  ${Pagination}
  ${MinimumSeriesDetail}
  ${MinimumCreatorDetail}
  ${MinimumSessionDetail}
  ${MinimumExhibitorDetail}
  fragment ConferenceSiteSearch on ConferenceSiteSearch {
    paginationData {
      ...Pagination
    }
    results {
      ...MinimumSeriesDetail
      ...MinimumCreatorDetail
      ...MinimumSessionDetail
      ...MinimumExhibitorDetail
    }
  }
`
export default ConferenceSiteSearch
