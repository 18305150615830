import { gql } from '@apollo/client'
import { TagDataReference } from './fragments'

export default function getBsTags() {
  return gql(`
    ${TagDataReference}
    query QueryTags {
      ConferenceDataViewModel {
        tags {
          ...TagDataReference
        }
      }
    }
  `)
}
