import BrightcoveLivestream from './BrightcoveLiveStream'

const RootsTechBrightcoveVideoProvider = `
  ${BrightcoveLivestream}
  fragment RootsTechBrightcoveVideoProvider on RootsTechBrightcoveVideoProvider {
    accountId
    liveStream {
      ...BrightcoveLivestream
    }
    playerId
    providerType
    videoDuration
    videoId
  }
`
export default RootsTechBrightcoveVideoProvider
