import { getAvailableVendorReps, upsertVendorRep, deleteVendorRep } from '../api/rootstech/rtc-2022-helpDesk'
import { useLazyRequest } from './useRequest'

const getIsVendorRep = (vendorId, eventName, cisId) => {
  return getAvailableVendorReps(vendorId, eventName).then((r) => {
    return r.data?.some((rep) => rep.cisId === cisId)
  })
}
const getReps = (vendorId, eventName) => {
  return getAvailableVendorReps(vendorId, eventName).then((r) => {
    return r.data?.map((person) => person.cisId)
  })
}

const addRep = (vendorId, eventName, cisId) => {
  return upsertVendorRep(vendorId, eventName, cisId, false)
    .then((r) => r.status === 200)
    .catch((err) => {
      console.error('error adding rep to vendor ', err)
      return false
    })
}

const deleteVendorRepresentative = (vendorId, eventName, cisId) => {
  return deleteVendorRep(vendorId, eventName, cisId)
}
export default function useHelpDesk() {
  const [getVendorReps] = useLazyRequest(getReps)

  const [addVendorRep] = useLazyRequest(addRep)

  const [isUserVendorRep] = useLazyRequest(getIsVendorRep)

  const [deleteRep] = useLazyRequest(deleteVendorRepresentative)

  return {
    getVendorReps,
    addVendorRep,
    isUserVendorRep,
    deleteRep,
  }
}
