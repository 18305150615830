import React, { createContext, useContext, useState } from 'react'

const CountryContext = createContext()
export const useCountryChange = () => useContext(CountryContext)

export const CountryProvider = ({ children }) => {
  const [country, setCountry] = useState()

  return (
    <CountryContext.Provider
      // FIXME: This is a temporary fix. We need to remove this eslint-disable line and fix the issue.
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        country,
        setCountry,
      }}
    >
      {children}
    </CountryContext.Provider>
  )
}
