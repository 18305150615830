import PropTypes from 'prop-types'

export const validated = (baseType, ...validators) => {
  function checkType(isRequired, props, key, componentName, location, propFullName, secret) {
    const baseResult = baseType(props, key, componentName, location, propFullName, secret)
    if (baseResult) {
      return baseResult
    }

    const propValue = props[key]
    if (propValue === null || propValue === undefined) {
      if (isRequired) {
        return new Error(`Required property [${key}] has no value`)
      }

      return null
    }

    const valid = validators.every((validator) => {
      if (typeof validator === 'function') {
        const result = validator(propValue)
        return typeof result === 'boolean' && result
      }

      return false
    })

    if (!valid) {
      return new Error(`Invalid prop \`${key}\` passed to \`${componentName}\`. Validation failed.`)
    }

    return null
  }

  const result = checkType.bind(null, false)
  result.isRequired = checkType.bind(null, true)

  return result
}

export const validatedString = (...validators) => validated(PropTypes.string, validators)
export const validatedNumber = (...validators) => validated(PropTypes.number, validators)
export const positiveInteger = validatedNumber(
  (v) => v > 0,
  (v) => v.isInteger()
)
export const brightCoveId = validatedString((v) => /^\d{13}$/.test(v))
export const uuid = validatedString((v) =>
  /^[0-9A-F]{8}-[0-9A-F]{4}-[1-5][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(v)
)

export const literal = (l) => PropTypes.oneOf([l])

export const typename = {
  __typename: PropTypes.string,
}

export const conferenceYear = validatedString((v) => !/^20((19)|([23]\d))$/.test(v))

// todo: Get a list of valid values and use `PropTypes.oneOf` instead
export const contentVisibility = PropTypes.shape({
  status: PropTypes.string,
  workflowState: PropTypes.string,
})

export const imageAttributes = PropTypes.shape({
  filename: PropTypes.string,
  height: positiveInteger,
  publicUrl: PropTypes.string,
  src: PropTypes.string,
  width: positiveInteger,

  ...typename,
})

export const objectTypeDetail = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
})

export const socialAccount = PropTypes.shape({
  platform: PropTypes.string,
  urlOrHandle: PropTypes.string,
})

export const duration = validatedString((v) => /^(\d{2}:)*?\d{2}$/.test(v))

export const imageDetail = PropTypes.shape({
  alt: PropTypes.string,
  attributes: imageAttributes,
  contentLocale: PropTypes.string,
  creators: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  custom: PropTypes.shape({
    image: imageAttributes,
  }),
  source: PropTypes.string,
  url: PropTypes.string,
  visibility: contentVisibility,
  ...typename,
})

export const brightcoveVideoProvider = PropTypes.shape({
  accountId: brightCoveId,
  liveStream: PropTypes.shape({
    accountId: brightCoveId,
    label: PropTypes.string,
    liveStreamId: PropTypes.oneOfType([brightCoveId, literal('onDemand')]),
  }),
  playerId: PropTypes.string,
  providerType: literal('brightcove'),
  videoDuration: duration,
  videoId: brightCoveId,
})

export const hostedVideoProvider = PropTypes.shape({
  cdnUrl: PropTypes.string,
  providerType: PropTypes.string, // todo: get the actual value
  videoDuration: duration,
})

export const html5VideoProvider = PropTypes.shape({
  files: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.string,
      height: positiveInteger,
      mimeType: PropTypes.string,
      width: positiveInteger,
    })
  ),
  providerType: PropTypes.string, // todo: get the actual value
})

export const webinarVideoProvider = PropTypes.shape({
  date: PropTypes.number,
  providerType: literal('WEBINAR'),
  url: PropTypes.string,
  videoDuration: duration,
})

export const youtubeVideoProvider = PropTypes.shape({
  providerType: literal('youtube'),
  videoDuration: positiveInteger, // this one is millis
  videoId: PropTypes.string,
})

export const videoProvider = PropTypes.oneOfType([
  brightcoveVideoProvider,
  hostedVideoProvider,
  html5VideoProvider,
  webinarVideoProvider,
  youtubeVideoProvider,
])

export const videoDetail = PropTypes.shape({
  contentLocale: PropTypes.string,
  creators: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  headline: PropTypes.string,
  id: uuid,
  subHeadline: PropTypes.string,
  thumbnail: imageDetail,
  type: objectTypeDetail,
  url: PropTypes.string,
  videoDuration: duration,
  videoProvider: PropTypes.arrayOf(videoProvider),
  visibility: contentVisibility,
  ...typename,
})

export const videoModule = PropTypes.shape({
  description: PropTypes.string,
  title: PropTypes.string,
  video: videoDetail,
  ...typename,
})

export const conferencePromo = PropTypes.shape({
  conferenceYear,
  contentId: uuid,
  contentLocale: PropTypes.string, // todo: add a validator
  contentType: objectTypeDetail,
  // embedding this because of circular dependency
  creators: PropTypes.arrayOf(
    PropTypes.shape({
      biography: PropTypes.string,
      creatorType: PropTypes.string,
      honors: PropTypes.string,
      id: uuid,
      name: PropTypes.string,
      title: PropTypes.string,
      type: objectTypeDetail,
      url: PropTypes.string,
      visibility: contentVisibility,
      ...typename,
    })
  ),
  date: PropTypes.number, // todo: add a validator?
  description: PropTypes.string,
  image: imageDetail,
  overlineText: PropTypes.string,
  title: PropTypes.string,
  thumbnail: imageDetail,
  url: PropTypes.string,
  videoDuration: duration,
  video: videoProvider,
  ...typename,
})

export const creator = PropTypes.shape({
  biography: PropTypes.string,
  creatorType: PropTypes.string,
  honors: PropTypes.string,
  id: uuid,
  name: PropTypes.string,
  profileImage: imageDetail,
  secondaryLocale: PropTypes.string,
  socialAccounts: PropTypes.arrayOf(socialAccount),
  submissions: PropTypes.arrayOf(conferencePromo),
  title: PropTypes.string,
  type: objectTypeDetail,
  url: PropTypes.string,
  visibility: contentVisibility,
  ...typename,
})
