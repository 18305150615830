import { brightcoveConfig } from '../../../config'

const ON_DEMAND = 'onDemand'

const isScheduledOnDemand = (videoProvider) => videoProvider?.liveStream?.livestreamId === ON_DEMAND

export default function getBrightcoveIds(videoProvider, isLive) {
  if (videoProvider?.providerType !== 'brightcove') return {}

  const { liveStream } = videoProvider
  if (liveStream && isLive && !isScheduledOnDemand(videoProvider)) {
    return {
      videoId: liveStream.livestreamId,
      accountId: liveStream.accountId,
    }
  }

  return {
    videoId: videoProvider.videoId,
    accountId: brightcoveConfig.master.accountId,
  }
}
