import ContentVisibility from './ContentVisibility'
import CalendarModule from './CalendarModule'
import { ConferencePromo, LeadConferencePromo } from './ConferencePromo'
import { LeadListModule, ListModule } from './ListModule'
import RichTextModule from './RichTextModule'
import VideoModule from './VideoModule'
import ImageOptionDetail from './ImageOptionDetail'
import VideoLeadDetail from './VideoLeadDetail'
import Seo from './Seo'

const OneOffPageDetail = `
  ${CalendarModule}
  ${ConferencePromo}
  ${ListModule}
  ${RichTextModule}
  ${VideoModule}
  ${LeadListModule}
  ${LeadConferencePromo}
  ${ImageOptionDetail}
  ${VideoLeadDetail}
  ${ContentVisibility}
  ${Seo}
  fragment OneOffPageDetail on OneOffPageDetail {
    alternateLocales
    content {
      ...CalendarModule
      ...ConferencePromo
      ...ListModule
      ...RichTextModule
      ...VideoModule
    }
    contentLocale
    description
    id
    lead {
      ...LeadListModule
      ...LeadConferencePromo
      ...ImageOptionDetail
      ...VideoLeadDetail
    }
    name
    seo {
      ...Seo
    }
    visibility {
      ...ContentVisibility
    }
  }
`
export default OneOffPageDetail
