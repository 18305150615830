import { baseUrl, targetEnv } from '@fs/zion-config'
import { whiteListUrlConfig } from '../../config'

const FAMILYSEARCH_URL = 'familysearch.org'
const ROOTSTECH_PATH = '/rootstech'
const whiteListUrlDomains = whiteListUrlConfig

export function getRelativePath(url) {
  if (!url) return null
  try {
    const promoUrl = new URL(url, baseUrl)
    if (whiteListUrlDomains.includes(promoUrl.hostname)) {
      return url
    }
  } catch (error) {
    // move on
  }
  const urlParts = url?.split('/rootstech/')
  const suffix = urlParts?.[1]
  const relativePart = suffix ? `/${suffix}` : null

  const value = relativePart ?? url
  return value
}

export const isExternalUrl = (simpleUrl) => {
  try {
    const url = new URL(simpleUrl)
    if (url.hostname === 'localhost') {
      return false
    }
    if (
      !url.host.includes(FAMILYSEARCH_URL) ||
      (url.host.includes(FAMILYSEARCH_URL) && !url.pathname.includes(ROOTSTECH_PATH))
    ) {
      return true
    }
  } catch {
    // the url probably doesn't have a host, so we can assume it's meant to be internal
  }
  return false
}

// targetEnv is always local when running locally, this will allow us to use int, beta, and prod in a dev environment
export const getEnv = () => {
  // possible values should be integration, beta, www
  const env = baseUrl?.split('https://')?.[1]?.split('.')?.[0] || targetEnv || 'integration'
  return env
}

/**
 * Gets all of the query params that are not being used by the application at the given point.
 *
 * For example, the search page does not use the param ?showFlags. This function will return an object that will contain `showFlags`
 * @param {Object} query
 * @param {Function} condition is sent the key for the param and should return false if the query is not expected; true if it is
 * @returns all the query params that aren't being used by the applicable logic
 */
export const getUnusedQueryParams = (query, condition) => {
  const unusedParams = Object.entries(query).reduce((acc, [key, value]) => {
    if (!condition(key)) {
      acc[key] = value || true
    }
    return acc
  }, {})

  return unusedParams
}
