import { LeadImage } from './ImageDetail'

const ImageOptionDetail = `
  ${LeadImage}
  fragment ImageOptionDetail on ImageOptionDetail {
    imageDetail {
      ...LeadImage
    }
  }
`

export default ImageOptionDetail
