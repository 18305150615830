import React from 'react'
import { css } from '@emotion/core'
import { Bleed, Card, Separator, colors, Row, MarginBox } from '@fs/zion-ui'
import { MenuClose } from '@fs/zion-icon'
import { TabletButton } from '../buttons'

// NOTE: This is supposed to look like skeletons, but skeletons flash and we want
//       it to be static. Hacking the skeletons wasn't any better than all this
//       custom code

const headerCss = css`
  background-color: ${colors.gray05};
`
const gridCss = css`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 11fr;
  margin-block-end: 16px;
`
const contentCss = css`
  margin: 18px;
`
const circleCss = css`
  background-color: ${colors.gray10};
  border-radius: 5em;
  width: 24px;
  height: 24px;
`
const senderCss = css`
  background-color: ${colors.gray10};
  height: 18px;
  width: 60%;
  margin-block: 3px;
  border-radius: 4px;
`
const bodyCss = css`
  background-color: ${colors.gray20};
  margin-block-start: 8px;
  border-radius: 4px;
`
const footerCss = css`
  background-color: ${colors.gray05};
  margin-block-start: -36px;
`

const Message = ({ size = 'sm' }) => {
  return (
    <div css={gridCss}>
      <div css={circleCss} />
      <div>
        <div css={senderCss} />
        <div css={bodyCss} style={{ height: size === 'sm' ? 42 : 98 }} />
      </div>
    </div>
  )
}

const EmbeddedRTChatFallback = ({ children, onClose }) => {
  return (
    <Card>
      <Bleed all>
        <MarginBox marginX="nano" css={headerCss}>
          <Separator size="nano" />
          <Row alignX="end">
            <TabletButton emphasis="high" Icon={MenuClose} onClick={onClose} />
          </Row>
          <Separator size="nano" />
        </MarginBox>

        <div css={contentCss}>
          <Message size="lg" />
          <Message />
          <Message />
        </div>

        <div css={footerCss}>
          <Separator />
          {children}
          <Separator />
        </div>
      </Bleed>
    </Card>
  )
}

export default EmbeddedRTChatFallback
