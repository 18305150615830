import React, { forwardRef } from 'react'
import { css } from '@emotion/core'
import { Ellipsis, Row, Paragraph, H4, usePageWidth } from '@fs/zion-ui'
import { Link } from '@fs/zion-router'
import { removeHtml } from '@fs/zion-frontend-friends'
import { trackLink } from '@fs/zion-analytics'
import { getRelativePath, isExternalUrl } from '../helpers'
import NewCard from './NewCard'
import { placeholderSeafoamJpg } from '../../images'

const mdWidthImageCss = css`
  background-color: #ffffff;
  border-radius: 4px;
  height: 70px;
  object-fit: cover;
  object-position: center;
  width: 70px;
`
const newTabProps = {
  target: '_blank',
  rel: 'noopener noreferrer',
}

const buttonCss = css`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
`
const noUnderlineHackCss = css`
  & a {
    text-decoration: none;
  }
`

const CButton = forwardRef(({ linkName, onClick, ...props }, ref) => {
  const handleClick = (e) => {
    trackLink(e)
    onClick && onClick(e)
  }
  return (
    <div
      role="button"
      ref={ref}
      {...props}
      data-link-name={linkName}
      onClick={handleClick}
      onKeyDown={handleClick}
      tabIndex={0}
      css={buttonCss}
    />
  )
})

const scrubText = (text) => {
  return removeHtml(text)
    ?.replace(/&nbsp;/g, ' ')
    .replace('&amp;', '&')
}

// FIXME: this should probably be replaced by something, ask UX
const CardListItem = ({
  as: TagName,
  largeMobileImg,
  smallMobileImg,
  heading,
  image,
  description,
  to,
  external,
  ...props
}) => {
  let imgOverrides
  if (largeMobileImg) {
    imgOverrides = {
      backgroundColor: '#fff',
      height: 110,
      objectFit: 'contain',
      width: 110,
    }
  } else if (smallMobileImg) {
    imgOverrides = {
      backgroundColor: '#fff',
      objectFit: 'contain',
    }
  }

  return (
    <div css={noUnderlineHackCss}>
      <TagName linkName={`Card: ${heading}`} {...props} to={to} {...(external ? newTabProps : {})}>
        <Row alignY="top" gutters="xs">
          <img src={image} alt="" aria-hidden css={mdWidthImageCss} style={imgOverrides} noShrinkX loading="lazy" />
          <div growX style={{ marginTop: -8 }}>
            <H4 size="H5">{heading}</H4>
            <Paragraph size="sm">
              <Ellipsis lineClamp={2}>{scrubText(description)}</Ellipsis>
            </Paragraph>
          </div>
        </Row>
      </TagName>
    </div>
  )
}

export default function Card({
  image = placeholderSeafoamJpg,
  heading,
  description,
  // Badges/Buttons
  contentLocale,
  conferenceYear,
  duration,
  startTimestamp,
  isLive,
  workflowState,
  // ----
  contentId,
  isPlaylistable,
  isInPlaylist,
  noCrop = false,
  largeMobileImg = false,
  'data-testid': dataTestId,
  smallMobileImg = false,
  to: url,
  percentageWatched,
  // linkPage,
  ...props
}) {
  // using `useIsMdWidth()` was causing problems with not re-rendering on page size changes
  const atWidth = usePageWidth()
  const isMdWidth = atWidth({ default: true, xl: false })
  const external = isExternalUrl(url)
  const TagName = url ? Link : CButton
  const to = external ? url : getRelativePath(url)
  const scrubbedHeading = scrubText(heading)
  if (!heading) {
    return null
  }
  if (isMdWidth) {
    return (
      <CardListItem
        as={TagName}
        image={image}
        heading={scrubbedHeading}
        description={description}
        largeMobileImg={largeMobileImg}
        smallMobileImg={smallMobileImg}
        to={to}
        external={external}
        // linkPage={linkPage} // Need to verify the page is passed in relevant places in the app.
      />
    )
  }

  return (
    <NewCard
      contentLocale={contentLocale}
      data-testid={dataTestId}
      description={description}
      duration={duration}
      external={external}
      heading={scrubbedHeading}
      image={image}
      isInPlaylist={isInPlaylist}
      isLive={isLive}
      isPlaylistable={isPlaylistable}
      workflowState={workflowState}
      noCrop={noCrop}
      contentId={contentId}
      startTimestamp={startTimestamp}
      to={to}
      conferenceYear={conferenceYear}
      percentageWatched={percentageWatched}
      {...props}
    />
  )
}
