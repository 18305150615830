import React from 'react'
import { Row } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { useUserData } from '../../hooks'
import AgreePrivacyTermsField from './AgreePrivacyTermsField'
import ReceiveMessagesField from './ReceiveMessagesField'

export default function AgreementAndSubscriptionFields({ implicitSubscribe }) {
  const user = useUserData(true)
  const [t] = useTranslation()

  return (
    <>
      {!user.signedIn && (
        <Row gutters="xs">
          <span size="sm">*</span>
          <AgreePrivacyTermsField />
        </Row>
      )}
      {!implicitSubscribe ? (
        <Row>
          <div />
          <ReceiveMessagesField />
        </Row>
      ) : (
        <Row>
          <div />
          {t(
            'conference-announce.subscribe.receive-messages',
            'I would like to receive messages from RootsTech by FamilySearch, including news about future events to the email address or mobile number I provided.'
          )}
        </Row>
      )}
    </>
  )
}
