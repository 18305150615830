import React, { Children } from 'react'
import 'react-multi-carousel/lib/styles.css'
import { css } from '@emotion/core'
import Arrow from './Arrow'
import Dot from './Dot'

const navigationCss = css`
  --dot-diameter: 8px;
  --dot-margin-block-start: calc(var(--dot-diameter) * 3);
  --dot-content-area: calc(var(--dot-diameter) + var(--dot-margin-block-start));

  --children-content-area: calc(100% - var(--dot-content-area));

  // We don't have access to the button size, so we estimate it
  --estimated-button-size: 42px;
`
const arrowsCss = css`
  position: absolute;
  display: flex;
  justify-content: space-between;
  pointer-events: none;

  // put the buttons in the middle
  top: calc(var(--children-content-area) / 2 - var(--estimated-button-size) / 2);

  --content-margin: 2%;
  --content-width: calc(100% - var(--content-margin) * 2);
  width: var(--content-width);
  margin-inline: var(--content-margin);
`
const dotsCss = css`
  width: fit-content;
  margin: auto;
  margin-block-start: var(--dot-margin-block-start);
  display: flex;
  gap: 8px;
`
const scrimCss = css`
  position: absolute;
  height: var(--children-content-area);
  width: var(--estimated-button-size);
  top: 0;
  pointer-events: none;
`
const scrimLeftCss = css`
  ${scrimCss}
  background: linear-gradient(to right, rgba(0, 0, 0), transparent);
`
const scrimRightCss = css`
  ${scrimCss}
  inset-inline-end: 0;
  background: linear-gradient(to left, rgba(0, 0, 0), transparent);
`

const Navigation = ({ next, previous, goToSlide, carouselState, flavor, infinite }) => {
  const { currentSlide, totalItems } = carouselState

  // when the carousel is infinite, it adds duplicate slides at the beginning and end
  const firstSlideIndex = infinite ? 2 : 0
  const lastSlideIndex = totalItems - (infinite ? 3 : 1)

  const isStart = currentSlide === firstSlideIndex
  const isEnd = currentSlide === lastSlideIndex

  const onDotClick = (index) => {
    goToSlide(index)
  }

  const onNext = () => {
    if (isEnd && infinite) {
      goToSlide(firstSlideIndex)
    } else {
      next()
    }
  }

  const onPrevious = () => {
    if (isStart && infinite) {
      goToSlide(lastSlideIndex)
    } else {
      previous()
    }
  }

  const isBeginning = infinite || !isStart
  const isFinish = infinite || !isEnd

  return (
    <div css={navigationCss}>
      {isBeginning && <div css={scrimLeftCss} />}
      {isFinish && <div css={scrimRightCss} />}

      <div css={arrowsCss}>
        {/* this div is necessary to keep the forward button on the right */}
        <div>{isBeginning && <Arrow direction="backward" flavor={flavor} onClick={onPrevious} />}</div>
        {isFinish && <Arrow direction="forward" flavor={flavor} onClick={onNext} />}
      </div>

      <div css={dotsCss}>
        {Children.toArray(
          // if there are 10 slides, we want 10 dots, not 9
          [...Array(lastSlideIndex + 1)]
            .map((_, index) => {
              if (infinite && (index === 0 || index === 1)) {
                return null
              }
              return <Dot active={index === currentSlide} onClick={() => onDotClick(index)} />
            })
            .filter(Boolean)
        )}
      </div>
    </div>
  )
}

export default Navigation
