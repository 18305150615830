import { videoDurationStringToMs, getNowTimestamp } from './dateTimeHelpers'

export function getIsLive({ date: videoStartTimestamp, videoDuration, now = getNowTimestamp() }) {
  videoDuration = videoDurationStringToMs(videoDuration)
  const videoEndTimestamp = videoStartTimestamp + videoDuration
  const isLive = now >= videoStartTimestamp && now <= videoEndTimestamp
  return isLive
}

export function getIsPastBroadcast({ date: videoStartTimestamp, videoDuration }) {
  videoDuration = videoDurationStringToMs(videoDuration)
  const nowTimestamp = getNowTimestamp()
  const videoEndTimestamp = videoStartTimestamp + videoDuration
  const isPastBroadcast = nowTimestamp > videoEndTimestamp
  return isPastBroadcast
}
