import { ConferencePromo } from './ConferencePromo'

const CalendarItemDetail = `
  ${ConferencePromo}
  fragment CalendarItemDetail on CalendarItemDetail {
    date
    item {
      ...ConferencePromo
    }
  }
`
export default CalendarItemDetail
