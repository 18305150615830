import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FormSelect, useZionFormContext } from '@fs/zion-form'
import useCountries from '../../components/dashboard/hooks/useCountries'

export const NAME = 'country'

export function CountryField({ disabled, value }) {
  const [t] = useTranslation()
  const { countries } = useCountries()

  const { setValue } = useZionFormContext()

  useEffect(() => {
    if (value && countries) setValue(NAME, value)
  }, [countries, setValue, value])

  return (
    <FormSelect
      name={NAME}
      label={`${t('form.field.regional-country.label', 'Country or Location')}: *`}
      // remove `countryCode` from the DOM
      options={countries.map(({ countryCode, ...rest }) => ({ ...rest }))}
      disabled={disabled}
    />
  )
}
