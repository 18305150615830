import React from 'react'
import PropTypes from 'prop-types'
import { WhiteTheme, Paragraph, Separator, useContainerWidth, MarginBox } from '@fs/zion-ui'
import { css } from '@emotion/core'
import SectionHeading from './SectionHeading'
import SafeRichText from '../SafeRichText'
import { validFlavors } from '../../config'
import ExpanderGroup from '../ExpanderGroup'

const containerCss = css`
  background: var(--image) no-repeat top center/cover;
  border-radius: 8px;
`

export default function SectionCard({
  overline,
  heading,
  subHeading,
  description,
  actions,
  flavor = 'flavorless',
  headingSize = 'H3',
  image,
  graphic,
  children,
  // expander props
  expandable,
  expanderProps,

  ...props
}) {
  const atWidth = useContainerWidth()
  const marginSize = atWidth({ default: 'xs', lg: 'sm' })

  // if the children are interactive, like a TextField, then putting it in another component breaks functionality
  const content = (
    <>
      <WhiteTheme>
        <SafeRichText as={Paragraph} dirtyInput={description} />
      </WhiteTheme>
      {children}
      {!(description && children && graphic) && <Separator size="xs" />}
    </>
  )

  const headingProps = {
    heading,
    overline,
    subHeading,
    actions,
    flavor,
    size: headingSize,
  }

  return (
    <MarginBox
      marginX={marginSize}
      marginY={marginSize}
      css={containerCss}
      style={{ '--image': `url(${image})` }}
      {...props}
    >
      {expandable ? (
        <ExpanderGroup headingProps={headingProps} {...expanderProps}>
          {content}
        </ExpanderGroup>
      ) : (
        <>
          <SectionHeading {...headingProps} />
          {content}
        </>
      )}
    </MarginBox>
  )
}

SectionCard.propTypes = {
  flavor: PropTypes.oneOf(validFlavors),
  image: PropTypes.string,
  overline: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subHeading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  action: PropTypes.node,
  graphic: PropTypes.string,
  scrim: PropTypes.bool,
  hideDivider: PropTypes.bool,
  children: PropTypes.node,
}
