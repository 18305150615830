import React, { useEffect, Fragment } from 'react'
import { withRouter } from '@fs/zion-router'

// FIXME: memory leak when testing
function ScrollToTop({ history, children }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    })
    return () => {
      unlisten()
    }
  }, [history])

  return <>{children}</>
}

export default withRouter(ScrollToTop)
