import { useUser } from '@fs/zion-user'
import { useEffect } from 'react'
import { getCurrentUserAccessRequisition } from '../../../api'
import { useLazyRequest } from '../../useRequest'

export default function useUserAccessRequisition() {
  const { cisId, userLoading } = useUser()
  const [request, { loading, data }] = useLazyRequest(getCurrentUserAccessRequisition, { loading: true })

  useEffect(() => {
    if (userLoading) return

    request(cisId)
  }, [cisId, request, userLoading])

  return { loading: loading || userLoading, data }
}
