import React from 'react'
import { Image, Row, WhiteTheme } from '@fs/zion-ui'
import { css } from '@emotion/core'
import { boolAttr } from '@fs/zion-frontend-friends'
import { useTranslation } from 'react-i18next'
import { formatDuration } from '../../helpers'
import { Badge, LanguageBadge } from '../../Badge'
import WatchProgressBar from '../../WatchProgressBar'

const containerCss = css`
  position: relative;
  overflow: hidden;
  min-width: 178px;
  aspect-ratio: 16 / 9;
  display: flex;
  width: 100%;
`
const badgesContainerCss = css`
  position: absolute;
  bottom: 12px;
  padding-inline: var(--bleedable-sides);
  width: 100%;
`

const ImageBadges = ({ comingSoon = false, contentLocale, duration }) => {
  const [t] = useTranslation()
  duration = formatDuration(duration)
  const [seconds = 0, minutes = 0, hours = 0] = duration?.split(':').reverse() || []
  const durationIsZero = Number(duration?.replace(':', '')) === 0
  const prettyDuration = `${hours > 0 ? `${hours}:` : ''}`
    .concat(`${minutes > 0 ? minutes : '00'}:`)
    .concat(`${seconds > 0 ? seconds : '00'}`)

  return (
    <Row>
      <div growX>
        <LanguageBadge contentLocale={contentLocale} />
      </div>
      {duration && !durationIsZero && (
        <Badge
          flavor="charcoal"
          label={
            <time dateTime={`PT${hours}H${minutes}M${seconds}S`}>
              <WhiteTheme>{prettyDuration}</WhiteTheme>
            </time>
          }
          aria-label={t('badge.duration.label', 'Video duration is {duration}', { duration: prettyDuration })}
        />
      )}
      {comingSoon && (
        <Badge
          flavor="charcoal"
          label={<WhiteTheme>{t('badge.coming-soon.label', 'Coming Soon')}</WhiteTheme>}
          aria-label={t('badge.coming-soon.label', 'Coming Soon')}
        />
      )}
    </Row>
  )
}

export default function VideoThumbnail({
  thumbnail,
  duration,
  percentageWatched,
  comingSoon,
  contentLocale,
  isSession,
  heading,
  fill,
}) {
  const [t] = useTranslation()
  return (
    <div css={containerCss} is-session={boolAttr(isSession)}>
      <Image
        src={thumbnail}
        alt={t('card.image.alt.prefix', 'Thumbnail for {heading}', { heading })}
        loading="lazy"
        style={{ objectPosition: fill ? 'unset !important' : undefined }}
      />
      <div css={badgesContainerCss}>
        <ImageBadges comingSoon={comingSoon} contentLocale={contentLocale} duration={duration} />
      </div>
      <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
        <WatchProgressBar label={heading} percentageWatched={percentageWatched} />
      </div>
    </div>
  )
}
