import { useStatusOverlay } from '@fs/zion-ui'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import notifySentry from '../lib/helpers/sentryNotifier'

export default function useErrorHandlerOverlay(error) {
  const showStatusOverlay = useStatusOverlay()
  const [t] = useTranslation()
  useEffect(() => {
    if (error) {
      showStatusOverlay({
        message: t(
          'generic.api.error.message',
          'We’re sorry, but there’s an issue loading the content. Please try again shortly while we investigate.'
        ),
        type: 'error',
        dismissable: true,
      })
      notifySentry(error)
    }
  }, [error, showStatusOverlay, t])
}
