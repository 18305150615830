import { ConferencePromo } from './ConferencePromo'
import Link from './Link'
import { GraphicsImage, LogoImage } from './ImageDetail'
import { ExpoVideoDetail, VideoDetail } from './VideoDetail'
import SocialAccount from './SocialAccount'
import ContentVisibility from './ContentVisibility'
import AttachmentDetail from './AttachmentDetail'
import CalendarModule from './CalendarModule'
import { ListModule } from './ListModule'
import RichTextModule from './RichTextModule'
import VideoModule from './VideoModule'
import Seo from './Seo'

export const MinimumExhibitorDetail = `
  ${LogoImage}
  ${ContentVisibility}
  ${AttachmentDetail}
  fragment MinimumExhibitorDetail on ExhibitorDetail {
    contentLocale
    id
    name
    companyDescription
    companyWebsite {
      url
    }
    downloadableResources {
      ...AttachmentDetail
    }
    exhibitorAssociatedUsers
    url
    logo {
      ...LogoImage
    }
    sourceId
    sponsor
    sponsorLevel
    visibility {
      ...ContentVisibility
    }
  }
`

export const DashboardExhibitorDetail = `
  ${ConferencePromo}
  ${LogoImage}
  ${ContentVisibility}
  ${AttachmentDetail}
  fragment DashboardExhibitorDetail on ExhibitorDetail {
    contentLocale
    id
    name
    companyDescription
    companyWebsite {
      url
    }
    downloadableResources {
      ...AttachmentDetail
    }
    exhibitorAssociatedUsers
    promotions {
      ...ConferencePromo
    }
    url
    logo {
      ...LogoImage
    }
    sourceId
    sponsor
    sponsorLevel
    visibility {
      ...ContentVisibility
    }
  }
`

export const ExhibitorDetail = `
  ${ConferencePromo}
  ${Link}
  ${ExpoVideoDetail}
  ${LogoImage}
  ${GraphicsImage}
  ${VideoDetail}
  ${SocialAccount}
  ${ContentVisibility}
  ${CalendarModule}
  ${ListModule}
  ${RichTextModule}
  ${VideoModule}
  ${AttachmentDetail}
  ${Seo}
  fragment ExhibitorDetail on ExhibitorDetail {
    additionalExperiences {
      ...ConferencePromo
    }
    alternateLocales
    companyContactEmail
    companyContactUsPage {
      ...Link
    }
    companyDescription
    companyFaqs {
      ...Link
    }
    companySupportPage {
      ...Link
    }
    companyWebsite {
      ...Link
    }
    content {
      ...CalendarModule
      ...ConferencePromo
      ...ListModule
      ...RichTextModule
      ...VideoModule
    }
    contentLocale
    downloadableResources {
      ...AttachmentDetail
    }
    graphics {
      ...GraphicsImage
    }
    id
    logo {
      ...LogoImage
    }
    name
    promotions {
      ...ConferencePromo
    }
    seo {
      ...Seo
    }
    socialAccounts {
      ...SocialAccount
    }
    sponsor
    sponsorLevel
    supportingChat
    url
    visibility {
      ...ContentVisibility
    }
    welcomeVideo {
      ...ExpoVideoDetail
    }
  }
`
