import { useRef } from 'react'

export default function useOnce() {
  const hasRun = useRef(false)

  const onOnce = (callback) => {
    if (hasRun.current) return

    callback?.()
    hasRun.current = true
  }

  return onOnce
}
