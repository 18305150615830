import { useChatOverlay, buildChatRoute, CHAT_ROUTE_KEY } from '@fs/zion-chat'
import { chatConfig } from '../config'

export default function useVendorChatOverlay({ vendorUrl, vendorName = '', imageUrl = '' }) {
  const supportChatInformation = {
    name: vendorName,
    imageUrl,
    externalId: vendorUrl,
    eventLabel: chatConfig.EVENT_LABEL,
  }

  const chatOverlay = useChatOverlay(buildChatRoute(CHAT_ROUTE_KEY.CREATE_VENDOR_CHAT), supportChatInformation)
  if (chatOverlay) {
    return chatOverlay
  }
  return null
}
