import React, { Children } from 'react'
import { Grid, Row, Separator, Subheading, useContainerWidth, H3 } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { PillButton } from '../buttons'
import { FeatureCard } from '../card'

export default function CardPromos({ promoList, noActions }) {
  const [t] = useTranslation()
  const atWidth = useContainerWidth()
  const { title, secondaryTitle, items } = promoList
  const flavors = ['mango', 'seafoam']

  return items ? (
    <>
      <H3 centered>
        {title}
        {secondaryTitle && (
          <>
            <Separator size="xs" />
            <Subheading>{secondaryTitle}</Subheading>
          </>
        )}
      </H3>
      <Separator size="lg" />
      <Grid columns={12}>
        {Children.toArray(
          items.map((item, i) => {
            const actions = (
              <Row gutters="xs" alignY="middle" wrap>
                {item.ctaText ? (
                  <div>
                    <PillButton to={item.url} linkName={`${title}: ${item.ctaText}`}>
                      {item.ctaText}
                    </PillButton>
                  </div>
                ) : null}
                <PillButton
                  emphasis="low"
                  to={item.url?.includes('registration/online') ? '/registration/online' : '/registration/in-person'}
                >
                  {t('common-ui:learn-more.action', 'Learn More')}
                </PillButton>
              </Row>
            )
            return (
              <FeatureCard
                columns={atWidth({ default: 12, md: 6 })}
                flavor={flavors[i]}
                actions={!noActions && actions}
                {...item}
              />
            )
          })
        )}
      </Grid>
    </>
  ) : null
}
