import React, { useRef, lazy, Suspense } from 'react'
import { boolAttr } from '@fs/zion-frontend-friends'
import { IconButton, useMenuOverlay, useOverlay } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { PlaceWorld } from '@fs/zion-icon'
import LanguageOverlay from './LanguageOverlay'

const LangPicker = lazy(() => import('@fs/zion-lang-picker'))

export default function LanguageButton({ onClick = () => {}, size = 'sm' }) {
  const [t] = useTranslation()
  const ref = useRef()
  const overlay = useMenuOverlay()
  const langOverlay = useOverlay()
  const handleClick = (e) => {
    onClick(e)
    overlay.handleClick(e)
  }

  return (
    <>
      <IconButton
        ref={ref}
        size={size}
        Icon={PlaceWorld}
        active={boolAttr(overlay.isOpen)}
        title={t('common-ui:language.label', 'Language')}
        data-testid="menu-language"
        onClick={handleClick}
        linkName="Language"
      />

      {(overlay.isOpen || overlay.transitioning) && (
        <LanguageOverlay
          onLangClick={() => {
            overlay.close()
            langOverlay.handleClick()
          }}
          {...overlay}
        />
      )}
      <Suspense fallback={<span />}>
        {(langOverlay.isOpen || langOverlay.transitioning) && <LangPicker overlay={langOverlay} />}
      </Suspense>
    </>
  )
}
