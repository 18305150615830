import React, { useRef, useState } from 'react'
import { css } from '@emotion/core'
import { useResizeObserver } from '@fs/zion-frontend-friends'

const containerCss = css`
  max-width: calc(100vw - var(--scrollbar-width));
`
/**
 * The HorizontalScroller does some weird styling that doesn't respond to any resizing. This is a wrapper around the page that can provide structure for that component to make it responsive.
 */
const PageContainer = ({ children }) => {
  const ref = useRef()
  const [scrollbarWidth, setScrollbarWidth] = useState()
  useResizeObserver(ref, () => {
    setScrollbarWidth(window.innerWidth - document.body.clientWidth)
  })

  return (
    <div ref={ref} style={{ '--scrollbar-width': `${scrollbarWidth}px` }} css={containerCss}>
      {children}
    </div>
  )
}

export default PageContainer
