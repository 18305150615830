const scheduleConfig = {
  workflowStates: [
    '0-ff8d0ed0-509f-11ed-bbe0-b7ddeba1268a',
    '1-21ac8fe0-50a0-11ed-bbe0-b7ddeba1268a',
    '2-68e486b0-50a0-11ed-bbe0-b7ddeba1268a',
    '3-8f469370-50a0-11ed-bbe0-b7ddeba1268a',
    '4-ce0eda30-50a1-11ed-bbe0-b7ddeba1268a',
    '5-f05a7450-50a1-11ed-bbe0-b7ddeba1268a',
    '6-05944300-50a2-11ed-bbe0-b7ddeba1268a',
    '7-1935e580-50a2-11ed-bbe0-b7ddeba1268a',
    '8-31709820-50a2-11ed-bbe0-b7ddeba1268a',
    '9-695a6810-50a2-11ed-bbe0-b7ddeba1268a',
    '10-7e3e75a0-50a2-11ed-bbe0-b7ddeba1268a',
    '11-99431db0-50a2-11ed-bbe0-b7ddeba1268a',
    '12-ed359820-5166-11ed-8342-338b071c3bfc',
    '13-11889b00-5167-11ed-8342-338b071c3bfc',
    '14-41bf4990-5167-11ed-8342-338b071c3bfc',
    '15-565bede0-5167-11ed-8342-338b071c3bfc',
    '16-7ad58b90-5167-11ed-8342-338b071c3bfc',
    '17-8e181e20-5167-11ed-8342-338b071c3bfc',
    '18-aa665d30-5167-11ed-8342-338b071c3bfc',
    '19-267e6660-5168-11ed-8342-338b071c3bfc',
    '20-34d33920-5168-11ed-8342-338b071c3bfc',
    '21-4a132250-5168-11ed-8342-338b071c3bfc',
    '22-4ebe5ae0-5168-11ed-8342-338b071c3bfc',
    '23-1ec4bfe0-5169-11ed-8342-338b071c3bfc',
    '24-faaadb20-5178-11ed-9307-5372b3c6b9f5',
    '25-fd3b22f0-5178-11ed-9307-5372b3c6b9f5',
    '26-fdbf6e20-5178-11ed-9307-5372b3c6b9f5',
    '27-9ffa3120-5179-11ed-9a41-579a20e8b181',
    '28-a49b7ea0-5179-11ed-9a41-579a20e8b181',
    '29-ae81aca0-5179-11ed-9a41-579a20e8b181',
    '30-d7c0c1a0-5179-11ed-9a41-579a20e8b181',
    '31-e713ca80-5179-11ed-9a41-579a20e8b181',
    '32-fed2bd70-5179-11ed-9a41-579a20e8b181',
    '33-0b5abf70-517a-11ed-9a41-579a20e8b181',
    '34-44489c70-517b-11ed-9a41-579a20e8b181',
    '35-6c9d5170-517b-11ed-9a41-579a20e8b181',
    '36-91592840-517b-11ed-9a41-579a20e8b181',
    '37-97d661b0-517b-11ed-9a41-579a20e8b181',
    '38-9d556500-517b-11ed-9a41-579a20e8b181',
    '39-a3cb4b70-517b-11ed-9a41-579a20e8b181',
    '40-c0b50730-517b-11ed-9a41-579a20e8b181',
    '41-c38bf4f0-517b-11ed-9a41-579a20e8b181',
    '42-ccfd1a00-517b-11ed-9a41-579a20e8b181',
    '43-cfd73c10-517b-11ed-9a41-579a20e8b181',
    '44-d2d7aad0-517b-11ed-9a41-579a20e8b181',
    '45-7c2e5840-517c-11ed-9a41-579a20e8b181',
    '46-7f63e0c0-517c-11ed-9a41-579a20e8b181',
    '47-82ab6aa0-517c-11ed-9a41-579a20e8b181',
    '48-854660d0-517c-11ed-9a41-579a20e8b181',
    '49-8938a9a0-517c-11ed-9a41-579a20e8b181',
    '50-8cbf8670-517c-11ed-9a41-579a20e8b181',
    '51-91e03d20-517c-11ed-9a41-579a20e8b181',
    '52-95bdec80-517c-11ed-9a41-579a20e8b181',
    '53-997d1760-517c-11ed-9a41-579a20e8b181',
    '54-a02b24d0-517c-11ed-9a41-579a20e8b181',
    '55-a3e03d90-517c-11ed-9a41-579a20e8b181',
    '67-440e0580-531d-11ed-bafe-db1ec555fd66',
    '68-4ca915e0-531d-11ed-bafe-db1ec555fd66',
    '69-0279ab00-535f-11ed-89f1-b5840d7c88e8',
    '70-0b068af0-53a9-11ed-9e5f-65571a12dd2f',
    '71-4b65e1e0-53a9-11ed-9e5f-65571a12dd2f',
    '72-9cb48c90-53a9-11ed-9e5f-65571a12dd2f',
    '76-c862e080-12f7-11ef-ae39-bda99d568b45',
  ],
  previewLimit: 50,
}

export default scheduleConfig
