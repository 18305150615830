import { VideoDetail } from './VideoDetail'

const VideoMutation = `
  ${VideoDetail}
  fragment VideoMutation on VideoMutation {
    result {
      ...VideoDetail
    }
  }
`
export default VideoMutation
