import { usePermission } from '@fs/zion-permissions'
import useErrorHandlerOverlay from './useErrorHandlerOverlay'

export default function usePermissionsAccess() {
  const [hasSpeakerAccess, , e1] = usePermission('RootsTechSpeakerAccessPermission', 'RootsTech')
  const [hasExhibitorAccess, , e2] = usePermission('RootsTechExhibitorAccessPermission', 'RootsTech')
  const [hasSponsorAccess, , e3] = usePermission('RootsTechSponsorAccessPermission', 'RootsTech')
  const [hasHomelandAccess, , e4] = usePermission('RootsTechHomelandAccessPermission', 'RootsTech')
  const [hasContentAdminAccess, , e5] = usePermission('RootsTechContentAdminAccessPermission', 'RootsTech')
  const [hasSuperAdminAccess, , e6] = usePermission('RootsTechSuperAdminAccessPermission', 'RootsTech')
  useErrorHandlerOverlay(e1)
  useErrorHandlerOverlay(e2)
  useErrorHandlerOverlay(e3)
  useErrorHandlerOverlay(e4)
  useErrorHandlerOverlay(e5)
  useErrorHandlerOverlay(e6)
  return {
    hasSpeakerAccess,
    hasExhibitorAccess,
    hasSponsorAccess,
    hasHomelandAccess,
    hasSuperAdminAccess,
    hasContentAdminAccess,
  }
}
