import React, { Children, useState } from 'react'
import { ExpandableListItem, ListItem, MenuOverlay } from '@fs/zion-ui'
import { useEvent } from '@fs/zion-frontend-friends'
import { ThingTicket } from '@fs/zion-icon'
import { useNavBarItems } from '../../hooks'
import { filterNavItems } from '../../lib/helpers/menuHelper'

const MiniEventsItems = ({ dense, onClick, removeFiller = false }) => {
  const navItems = useNavBarItems()
  const primaryText = 'Mini Events'
  const testId = 'mini-events'

  const items = [
    {
      'data-testid': `menu-${testId}-immigration`,
      linkName: `${primaryText}: Immigration`,
      primaryText: 'Immigration',
      onClick,
      to: '/events/immigration',
      dense,
      startElement: removeFiller ? undefined : <ListItem.Filler />,
    },
    {
      'data-testid': `menu-${testId}-dna-day`,
      linkName: `${primaryText}: DNA Day`,
      primaryText: 'DNA Day',
      onClick,
      to: '/events/dna-day-2023/',
      dense,
      startElement: removeFiller ? undefined : <ListItem.Filler />,
    },
    {
      'data-testid': `menu-${testId}-women`,
      linkName: `${primaryText}: Women`,
      primaryText: 'Women',
      onClick,
      to: '/events/mothers-and-women-in-genealogy/',
      dense,
      startElement: removeFiller ? undefined : <ListItem.Filler />,
    },
    {
      'data-testid': `menu-${testId}-fathers-day`,
      linkName: `${primaryText}: Father's Day`,
      primaryText: "Father's Day",
      onClick,
      to: "/events/father's-day-classes/",
      dense,
      startElement: removeFiller ? undefined : <ListItem.Filler />,
    },
    {
      'data-testid': `menu-${testId}-juneteenth`,
      linkName: `${primaryText}: Juneteenth`,
      primaryText: 'Juneteenth',
      onClick,
      to: '/events/juneteenth-at-familysearch/',
      dense,
      startElement: removeFiller ? undefined : <ListItem.Filler />,
    },
    {
      'data-testid': `menu-${testId}-all-webinars`,
      linkName: `${primaryText}: All Webinars`,
      primaryText: 'All Webinars',
      onClick,
      to: '/events/',
      dense,
      startElement: removeFiller ? undefined : <ListItem.Filler />,
    },
  ]

  const filteredItems = filterNavItems(items, navItems)
  return Children.toArray(filteredItems.map((item) => <ListItem {...item} />))
}

export const MiniEventsExpandableList = ({ dense, onClick }) => {
  const [open, setOpen] = useState(false)
  const handleClick = useEvent(() => {
    setOpen(false)
    onClick()
  })
  const primaryText = 'Mini Events'
  const testId = 'mini-events'

  return (
    <ExpandableListItem
      primaryText={primaryText}
      startElement={<ListItem.Icon Icon={ThingTicket} />}
      data-testid={`menu-${testId}`}
      dense={dense}
      open={open}
      setOpen={setOpen}
      linkName={`${primaryText}: ${open ? 'open' : 'close'}`}
      selected={open}
    >
      <MiniEventsItems dense={dense} onClick={handleClick} />
    </ExpandableListItem>
  )
}

export const MiniEventsMenuOverlay = ({ dense, removeFiller, ...overlay }) => {
  return (
    <MenuOverlay {...overlay}>
      <MiniEventsItems onClick={overlay.close} dense={dense} removeFiller={removeFiller} />
    </MenuOverlay>
  )
}
