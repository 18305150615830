import { LeadVideoDetail } from './VideoDetail'

const VideoLeadDetail = `
  ${LeadVideoDetail}
  fragment VideoLeadDetail on VideoLeadDetail {
    videoDetail {
      ...LeadVideoDetail
    }
  }
`

export default VideoLeadDetail
