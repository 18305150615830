import axios from '@fs/zion-axios'
import { targetEnv } from '@fs/zion-config'
import { relativesConfig } from '../../config'
import SERVICE from './serviceName'

const { eventIds } = relativesConfig

// DOCS: https://www.familysearch.org/service/orchestration/relatives-at-event/
const BASE = `${SERVICE}/relatives-at-event/event`

const getEventId = () => {
  if (targetEnv === 'prod') return eventIds.PROD
  if (targetEnv === 'beta') return eventIds.BETA
  return eventIds.INT
}

const EVENT = `${BASE}/${getEventId()}`
const COUNTRIES = `${EVENT}/places/countries`

export const getEventAttendeeCounts = async () => {
  return axios.get(`${EVENT}/attendees/counts`)
}
export const getRaeCountries = async () => {
  return axios.get(`${COUNTRIES}`)
}
export const getRaeProvinces = async (countryId) => {
  return axios.get(`${COUNTRIES}/${countryId}/states-provinces`)
}
