import React from 'react'
import { Row, Separator, H2 } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import Countdown from '../CountDown/CountDown'
import { stageSeafoamJpg } from '../../images'
import useIsMdWidth from '../../hooks/useIsMdWidth'

const backgroundCss = css`
  background-image: url(${stageSeafoamJpg});
  aspect-ratio: 16 / 9;
  display: grid;
  place-content: center;
  background-size: cover;
`

const VideoCountdown = ({ startTimestamp }) => {
  const [t] = useTranslation()
  const isMdWidth = useIsMdWidth()

  return (
    <div css={backgroundCss}>
      <Separator />
      <H2 supplementary centered>
        {t('main-stage.video-begins', 'Your video begins in:')}
      </H2>
      <Separator size={isMdWidth ? 'md' : 'xl'} />
      <Row alignX="center">
        <Countdown startTimestamp={startTimestamp} timesToShow={{ hours: true, minutes: true }} color="blue" noReload />
      </Row>
      <Separator />
    </div>
  )
}

export default VideoCountdown
