import React from 'react'
import { useTranslation } from 'react-i18next'
import ErrorBoundary from '@fs/zion-error-boundary'
import useCalendarData from '../../../components/schedule/hooks/useCalendarData'
import ScheduledPlayer from '../ScheduledPlayer'

const MainStagePlayer = () => {
  const [t] = useTranslation()
  const { loading: mainStageLoading, data: mainStageData } = useCalendarData('main-stage')

  return (
    <ErrorBoundary>
      <ScheduledPlayer
        sessions={mainStageData}
        loading={mainStageLoading}
        heading={t('main-stage.heading', 'Main Stage')}
        noSessionText={t('calendar.content.none', 'There are no scheduled events today.')}
      />
    </ErrorBoundary>
  )
}

export default MainStagePlayer
