import React from 'react'
import { Expander, ExpanderContent, ExpanderHeader, Separator } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import SectionHeading from '../section-card/SectionHeading'

export default function ExpanderGroup({ children, initiallyOpen = true, linkName, headingProps, ...props }) {
  const [t] = useTranslation()
  return (
    <Expander initiallyOpen={initiallyOpen} linkName={linkName} {...props}>
      <ExpanderHeader
        openedTitle={t('search.filter-expander-open.area-label', 'Click to collapse {heading}', {
          heading: headingProps.heading,
        })}
        closedTitle={t('search.filter-expander-closed.area-label', 'Click to expand {heading}', {
          heading: headingProps.heading,
        })}
      >
        <SectionHeading {...headingProps} />
      </ExpanderHeader>
      <ExpanderContent>
        <Separator size="nano" />
        {children}
        <Separator size="nano" />
      </ExpanderContent>
    </Expander>
  )
}
