import React from 'react'
import 'react-multi-carousel/lib/styles.css'
import ReactMultiCarousel from 'react-multi-carousel'
import { css } from '@emotion/core'
import PropTypes from 'prop-types'
import Navigation from './Navigation'
import { flavors } from '../../config'

const containerCss = css`
  position: relative;

  & ul > li {
    margin-inline: 0;
  }
`

// This is required by the component, but we kind of high jack it since we only show one at a time; it doesn't matter that much
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1213 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1213, min: 750 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 750, min: 0 },
    items: 1,
  },
}

/**
 * A carousel that displays one item at a time.
 */
export default function SingleViewCarousel({
  flavor = flavors.default,
  autoplay = false,
  autoplaySpeed = 5000,
  children,
  'data-testid': dataTestId = 'single-view-carousel',
  infinite = false,
}) {
  return (
    <div css={containerCss}>
      <ReactMultiCarousel
        data-testid={dataTestId}
        draggable
        autoPlay={autoplay}
        autoPlaySpeed={autoplaySpeed}
        responsive={responsive}
        arrows={false}
        showDots={false}
        infinite={infinite}
        renderButtonGroupOutside
        customButtonGroup={<Navigation flavor={flavor} infinite={infinite} />}
      >
        {children}
      </ReactMultiCarousel>
    </div>
  )
}

SingleViewCarousel.propTypes = {
  flavor: PropTypes.string,
  autoplay: PropTypes.bool,
  autoplaySpeed: PropTypes.number,
  children: PropTypes.node.isRequired,
  'data-testid': PropTypes.string,
  infinite: PropTypes.bool,
}
