import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import PossibleLink from '../PossibleLink'

const StrongLink = forwardRef(function StrongLink({ children, ...props }, ref) {
  return (
    <PossibleLink ref={ref} {...props}>
      <strong>{children}</strong>
    </PossibleLink>
  )
})

StrongLink.propTypes = {
  children: PropTypes.string,
}

export default StrongLink
