import { gql } from '@apollo/client'
import { brightspotConfig } from '../../config'
import { escapeBackslash, stripHtml, brEncodeAttribute } from '../helpers'
import CreatorDetail from './fragments/CreatorDetail'
import Pagination from './fragments/Pagination'
import gqlSanitize from './stringUtils'
import { MinimumCreatorDetail } from './fragments/ConferencePromo'

const { defaults } = brightspotConfig.query
const { profileImage, promoImage, thumbnailImage } = brightspotConfig.imageSizes

export function getCreatorDetail() {
  return gql`
    ${CreatorDetail}
    query CreatorDetail(
      $pathOrId: ID,
      $profileImage_crop: Boolean = false,
      $profileImage_height: Int = ${defaults.imageSize},
      $profileImage_width: Int = ${defaults.imageSize},
      $promoImage_crop: Boolean = false,
      $promoImage_height: Int = ${defaults.imageSize},
      $promoImage_width: Int = ${defaults.imageSize},
      $thumbnailImage_crop: Boolean = false,
      $thumbnailImage_height: Int = ${defaults.imageSize},
      $thumbnailImage_width: Int = ${defaults.imageSize}) {
      CreatorDetail(id: $pathOrId) {
        ...CreatorDetail
      }
    }
  `
}

export function getCreatorMutation() {
  return gql(`
  ${CreatorDetail}
  mutation GetCreatorMutation(
    $target: MutationTargetInput!,
    $profileImage_crop: Boolean = false,
    $profileImage_height: Int = ${defaults.imageSize},
    $profileImage_width: Int = ${defaults.imageSize},
    $promoImage_crop: Boolean = false,
    $promoImage_height: Int = ${defaults.imageSize},
    $promoImage_width: Int = ${defaults.imageSize},
    $thumbnailImage_crop: Boolean = false,
    $thumbnailImage_height: Int = ${defaults.imageSize},
    $thumbnailImage_width: Int = ${defaults.imageSize}) {
    CreatorMutation(_target: $target) {
      result {
        ...CreatorDetail
      }
    }
  }`)
}

export function mutateCreator() {
  return gql(`
    ${CreatorDetail}
    mutation MutateCreator(
      $target: MutationTargetInput,
      $associatedUser: String,
      $workflow: WorkflowInput,
      $biography: String,
      $company: String,
      $contactInformation: ContactInput,
      $contentLocale: String,
      $creatorType: creatorType,
      $honors: String,
      $name: String,
      $profileImage: ID,
      $secondaryLocale: String,
      $socialAccounts: [CreatorSocialInput],
      $title: String,
      $profileImage_crop: Boolean = false,
      $profileImage_height: Int = ${defaults.imageSize},
      $profileImage_width: Int = ${defaults.imageSize},
      $promoImage_crop: Boolean = false,
      $promoImage_height: Int = ${defaults.imageSize},
      $promoImage_width: Int = ${defaults.imageSize},
      $thumbnailImage_crop: Boolean = false,
      $thumbnailImage_height: Int = ${defaults.imageSize},
      $thumbnailImage_width: Int = ${defaults.imageSize}) {
      CreatorMutation(
        _target: $target,
        _workflow: $workflow,
        associatedUser: $associatedUser,
        biography: $biography,
        company: $company,
        contactInformation: $contactInformation,
        contentLocale: $contentLocale,
        creatorType: $creatorType,
        honors: $honors
        name: $name,
        profileImage: $profileImage,
        secondaryLocale: $secondaryLocale,
        socialAccounts: $socialAccounts,
        title: $title) {
        result {
          ...CreatorDetail
        }
      }
    }
  `)
}

export function searchCreators() {
  return gql(`
    ${MinimumCreatorDetail}
    ${Pagination}
    query CreatorSearch(
      $creatorTypes: [String] = ["INDIVIDUAL", "SPONSOR", "EXHIBITOR"],
      $ids: [String],
      $associatedUser: String,
      $pageRequest: PaginationInput = ${gqlSanitize(defaults.pageRequest)},
      $visibilityRequest: VisibilityInput = ${gqlSanitize(defaults.visibilityRequest)},
      $profileImage_crop: Boolean = false,
      $profileImage_height: Int = ${defaults.imageSize},
      $profileImage_width: Int = ${defaults.imageSize}) {
      CreatorSearch(
        creatorTypes: $creatorTypes, 
        ids: $ids, 
        associatedUser: $associatedUser, 
        pageRequest: $pageRequest, 
        visibilityRequest: $visibilityRequest
        ) {
          paginationData {
            ...Pagination
          }
          results {
            ...MinimumCreatorDetail
          }
      }
    }
  `)
}

export function queryPublishedCreators() {
  return gql(`
    ${MinimumCreatorDetail}
    ${Pagination}
    query PublishedCreators(
      $creatorTypes: [String],
      $visibilityRequest: VisibilityInput = ${gqlSanitize(defaults.visibilityRequest)},
      $pageRequest: PaginationInput = ${gqlSanitize(defaults.pageRequest)},
      $profileImage_crop: Boolean = false,
      $profileImage_height: Int = ${defaults.imageSize},
      $profileImage_width: Int = ${defaults.imageSize}) {
      CreatorSearch(
        creatorTypes: $creatorTypes,
        visibilityRequest: $visibilityRequest,
        pageRequest: $pageRequest) {
        results {
          ...MinimumCreatorDetail
        }
        paginationData {
          ...Pagination
        }
      }
    }
  `)
}

export async function getCreatorByIdAndLocale(client, { id, locale }) {
  if (!id) return []

  const variables = {
    target: { id, locale },
    ...profileImage,
    ...promoImage,
    ...thumbnailImage,
  }

  // NOTE: this is a mutation so we can to query the creator by locale
  const response = await client.mutate({ mutation: getCreatorMutation(), variables })
  return response?.data?.CreatorMutation?.result
}

export async function getPublishedCreators(client, { page, limit = defaults.pageRequest.limit }) {
  const variables = {
    creatorTypes: ['INDIVIDUAL'],
    visibilityRequest: { status: ['PUBLISHED'] },
    pageRequest: { page, limit },
    ...profileImage,
  }
  const response = await client.query({ query: queryPublishedCreators(), variables })
  return response?.data?.CreatorSearch
}

export async function createCreator(client, creator) {
  const contactInformation = {
    countryTag: creator?.contactInformation?.country ? creator.contactInformation.country : '',
    email: creator?.contactInformation?.email ? creator.contactInformation.email : '',
  }

  let socialAccounts = []
  if (creator?.socialAccounts) {
    socialAccounts = creator.socialAccounts.map((socialAccount) => ({
      platform: socialAccount.platform,
      urlOrHandle: socialAccount.urlOrHandle ? socialAccount.urlOrHandle : '',
    }))
  }

  const variables = {
    biography: creator?.biography ? brEncodeAttribute(escapeBackslash(stripHtml(creator.biography))) : '',
    associatedUser: creator?.cisId,
    contactInformation,
    company: creator?.company ? creator.company : '',
    honors: creator?.honors ? escapeBackslash(creator.honors) : '',
    name: creator?.name ? escapeBackslash(creator.name) : '',
    profileImage: creator?.profileImageId ? creator.profileImageId : '',
    secondaryLocale: creator?.secondaryLocale ? creator.secondaryLocale : '',
    socialAccounts,
    title: creator?.title ? escapeBackslash(creator.title) : '',
  }

  if (creator?.creatorType) {
    variables.creatorType = creator.creatorType
  }

  if (creator?.transition) {
    variables.workflow = { action: 'TRANSITION', targetState: { next: true } }
  }

  // NOTE - 2021-12-02 - This is a hack because BS broke us with their deploy yesterday
  // If we have a session id we are doing an update.  Otherwise, it is a create
  if (creator?.id) {
    variables.target = { id: creator.id, locale: creator.locale }
  } else {
    variables.contentLocale = creator?.locale ? creator.locale : ''
  }

  const response = await client.mutate({ mutation: mutateCreator(), variables })
  return response?.data?.CreatorMutation?.result
}

export async function updateCreator(client, creator) {
  return createCreator(client, creator)
}
