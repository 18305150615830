import React from 'react'
import { WhiteTheme } from '@fs/zion-ui'
import { Badge } from './Badge'

export default function Classroom({ classroom }) {
  return classroom ? (
    <Badge
      data-testid={`item-${classroom}`}
      flavor="flavorless"
      keyline="left"
      label={<WhiteTheme>{classroom}</WhiteTheme>}
    />
  ) : null
}
