import SearchFilter from './SearchFilter'
import Pagination from './Pagination'
import { MinDashboardSessionDetail, MinimumSessionDetail } from './SessionDetail'

export const SessionSearch = `
  ${SearchFilter}
  ${Pagination}
  ${MinimumSessionDetail}
  fragment SessionSearch on SessionSearch {
    filters {
      ...SearchFilter
    }
    paginationData {
      ...Pagination
    }
    results {
      ...MinimumSessionDetail
    }
  }
`

export const DashboardSessionSearch = `
  ${SearchFilter}
  ${Pagination}
  ${MinDashboardSessionDetail}
  fragment DashboardSessionSearch on SessionSearch {
    filters {
      ...SearchFilter
    }
    paginationData {
      ...Pagination
    }
    results {
      ...MinDashboardSessionDetail
    }
  }
`
