import React from 'react'
import { useTranslation } from 'react-i18next'
import SiteBanner from '../SiteBanner'

const SubRegionBanner = ({ pagePath }) => {
  const [t] = useTranslation()
  const regionSubPathRegex = /\/regions\/[^/]+$/

  return pagePath.match(regionSubPathRegex) ? (
    <SiteBanner
      heading={t(
        'region-banner.subpath.heading',
        'To find this and other region pages, click the menu and choose "Select Region/Country."'
      )}
      dismissProps={{ children: t('site-banner.dismiss.action', 'Dismiss') }}
    />
  ) : null
}

export default SubRegionBanner
