import PropTypes from 'prop-types'
import React from 'react'
import { Card, WhiteTheme, Row, Bleed, MarginBox } from '@fs/zion-ui'
import { css } from '@emotion/core'
import { flavors, validFlavors } from '../config'

const rainbowCardCss = css`
  height: 100%;
  &:focus,
  &:focus-within,
  &:active,
  &:hover {
      background: var(--gradient);
    }
  }
`

export default function RainbowHoverCard({
  children,
  flavor = flavors.default,
  size = 'nano',
  proportional = false,
  ...props
}) {
  return (
    <Card {...props}>
      <Bleed css={rainbowCardCss} all style={{ '--gradient': flavors[flavor].gradient }}>
        <MarginBox marginY={size} marginX={proportional ? size : 'none'}>
          <Row alignX="center" alignY="middle">
            <WhiteTheme>{children}</WhiteTheme>
          </Row>
        </MarginBox>
      </Bleed>
    </Card>
  )
}

RainbowHoverCard.propTypes = {
  children: PropTypes.node,
  flavor: PropTypes.oneOf(validFlavors),
  size: PropTypes.oneOf(['xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs', 'nano', 'hide']),

  /** whether or not to make the margin x the same size as margin y */
  proportional: PropTypes.bool,
}
