import { gql } from '@apollo/client'
import { brightspotConfig } from '../../config'
import { DashboardExhibitorDetail, ExhibitorDetail, MinimumExhibitorDetail } from './fragments/ExhibitorDetail'
import gqlSanitize from './stringUtils'
import Pagination from './fragments/Pagination'

const { query, imageSizes } = brightspotConfig
const { defaults } = query
const { promoImage, expoThumbnailImage, graphicsImage, thumbnailImage, logoImage, profileImage } = imageSizes

export function getExhibitorDetail() {
  return gql(`
    ${ExhibitorDetail}
    query ExhibitorDetail(
      $pathOrId: ID,
      $profileImage_crop: Boolean = false,
      $profileImage_height: Int = ${defaults.imageSize},
      $profileImage_width: Int = ${defaults.imageSize},
      $promoImage_crop: Boolean = false,
      $promoImage_height: Int = ${defaults.imageSize},
      $promoImage_width: Int = ${defaults.imageSize},
      $thumbnailImage_crop: Boolean = false,
      $thumbnailImage_height: Int = ${defaults.imageSize},
      $thumbnailImage_width: Int = ${defaults.imageSize},
      $expoThumbnailImage_crop: Boolean = false,
      $expoThumbnailImage_height: Int = ${defaults.imageSize},
      $expoThumbnailImage_width: Int = ${defaults.imageSize},
      $graphicsImage_crop: Boolean = false,
      $graphicsImage_height: Int = ${defaults.imageSize},
      $graphicsImage_width: Int = ${defaults.imageSize},
      $logoImage_crop: Boolean = false,
      $logoImage_height: Int = ${defaults.imageSize},
      $logoImage_width: Int = ${defaults.imageSize}) {
      ExhibitorDetail(id: $pathOrId) {
        ...ExhibitorDetail
      }
    }
  `)
}

export function getExhibitorPreviewDetail() {
  return gql(`
    ${ExhibitorDetail}
    ${Pagination}
    query ExhibitorSearch(
      $ids: [String],
      $associatedUser: String
      $pageRequest: PaginationInput = ${gqlSanitize(defaults.pageRequest)},
      $visibilityRequest: VisibilityInput = ${gqlSanitize(defaults.visibilityRequest)},
      $profileImage_crop: Boolean = false,
      $profileImage_height: Int = ${defaults.imageSize},
      $profileImage_width: Int = ${defaults.imageSize},
      $promoImage_crop: Boolean = false,
      $promoImage_height: Int = ${defaults.imageSize},
      $promoImage_width: Int = ${defaults.imageSize},
      $thumbnailImage_crop: Boolean = false,
      $thumbnailImage_height: Int = ${defaults.imageSize},
      $thumbnailImage_width: Int = ${defaults.imageSize},
      $expoThumbnailImage_crop: Boolean = false,
      $expoThumbnailImage_height: Int = ${defaults.imageSize},
      $expoThumbnailImage_width: Int = ${defaults.imageSize},
      $graphicsImage_crop: Boolean = false,
      $graphicsImage_height: Int = ${defaults.imageSize},
      $graphicsImage_width: Int = ${defaults.imageSize},
      $logoImage_crop: Boolean = false,
      $logoImage_height: Int = ${defaults.imageSize},
      $logoImage_width: Int = ${defaults.imageSize}) {
      ExhibitorSearch(
        ids: $ids, 
        associatedUsers: $associatedUser, 
        pageRequest: $pageRequest,
        visibilityRequest: $visibilityRequest
        ) {
          paginationData {
            ...Pagination
          }
          results {
            ...ExhibitorDetail
          }
      }
    }
  `)
}

export function getExhibitorMutation() {
  return gql(`
  ${ExhibitorDetail}
  mutation GetExhibitorMutation(
    $target: MutationTargetInput!,
    $profileImage_crop: Boolean = false,
    $profileImage_height: Int = ${defaults.imageSize},
    $profileImage_width: Int = ${defaults.imageSize},
    $promoImage_crop: Boolean = false,
    $promoImage_height: Int = ${defaults.imageSize},
    $promoImage_width: Int = ${defaults.imageSize},
    $thumbnailImage_crop: Boolean = false,
    $thumbnailImage_height: Int = ${defaults.imageSize},
    $thumbnailImage_width: Int = ${defaults.imageSize},
    $expoThumbnailImage_crop: Boolean = false,
    $expoThumbnailImage_height: Int = ${defaults.imageSize},
    $expoThumbnailImage_width: Int = ${defaults.imageSize},
    $graphicsImage_crop: Boolean = false,
    $graphicsImage_height: Int = ${defaults.imageSize},
    $graphicsImage_width: Int = ${defaults.imageSize},
    $logoImage_crop: Boolean = false,
    $logoImage_height: Int = ${defaults.imageSize},
    $logoImage_width: Int = ${defaults.imageSize}) {
    ExhibitorMutation(_target: $target) {
      result {
        ...ExhibitorDetail
      }
    }
  }`)
}

export function searchExhibitors() {
  return gql(`
    ${DashboardExhibitorDetail}
    ${Pagination}
    query ExhibitorSearch(
      $ids: [String],
      $associatedUser: String
      $pageRequest: PaginationInput = ${gqlSanitize(defaults.pageRequest)},
      $visibilityRequest: VisibilityInput = ${gqlSanitize(defaults.visibilityRequest)},
      $profileImage_crop: Boolean = false,
      $profileImage_height: Int = ${defaults.imageSize},
      $profileImage_width: Int = ${defaults.imageSize},
      $promoImage_crop: Boolean = false,
      $promoImage_height: Int = ${defaults.imageSize},
      $promoImage_width: Int = ${defaults.imageSize},
      $thumbnailImage_crop: Boolean = false,
      $thumbnailImage_height: Int = ${defaults.imageSize},
      $thumbnailImage_width: Int = ${defaults.imageSize},
      $logoImage_crop: Boolean = false,
      $logoImage_height: Int = ${defaults.imageSize},
      $logoImage_width: Int = ${defaults.imageSize}) {
      ExhibitorSearch(
        ids: $ids, 
        associatedUsers: $associatedUser, 
        pageRequest: $pageRequest,
        visibilityRequest: $visibilityRequest
        ) {
          paginationData {
            ...Pagination
          }
          results {
            ...DashboardExhibitorDetail
          }
      }
    }
  `)
}

export function queryExhibitorNames() {
  return gql(`
  ${MinimumExhibitorDetail}
  ${Pagination}
  query ExhibitorSearch(
    $visibilityRequest: VisibilityInput = ${gqlSanitize(defaults.visibilityRequest)},
    $pageRequest: PaginationInput = ${gqlSanitize(defaults.pageRequest)},
    $logoImage_crop: Boolean = false,
    $logoImage_height: Int = ${defaults.imageSize},
    $logoImage_width: Int = ${defaults.imageSize}) {
    ExhibitorSearch(
      visibilityRequest: $visibilityRequest, 
      pageRequest: $pageRequest) {
        paginationData {
          ...Pagination
        }
        results {
          ...MinimumExhibitorDetail
        }
        __typename
    }
  }`)
}

export const vendorSearch = () => {
  return gql(`
    ${MinimumExhibitorDetail}
    ${Pagination}
    query ExhibitorSearch(
      $ids: [String],
      $logoImage_crop: Boolean = false,
      $logoImage_height: Int = ${defaults.imageSize},
      $logoImage_width: Int = ${defaults.imageSize},
      $pageRequest: PaginationInput = ${gqlSanitize(defaults.pageRequest)},
      $visibilityRequest: VisibilityInput = ${gqlSanitize(defaults.visibilityRequest)}) {
      ExhibitorSearch(
        ids: $ids, 
        visibilityRequest: $visibilityRequest,
        pageRequest: $pageRequest) {
          paginationData {
            ...Pagination
          }
          results {
            ...MinimumExhibitorDetail
          }
      }
    }
  `)
}

export function mutateExhibitor() {
  return gql(`
    ${ExhibitorDetail}
    mutation ExhibitorMutation(
      $target: MutationTargetInput,
      $workflow: WorkflowInput,
      $associatedUsers: String,
      $companyContactEmail: String,
      $companyContactUsPage: String,
      $companyDescription: String,
      $companyFaqs: String,
      $companySupportPage: String,
      $companyWebsite: String,
      $contentLocale: String,
      $downloadableResources: [ID],
      $graphics: [ID],
      $logo: ID,
      $name: String,
      $promotions: [PromoInput],
      $sponsor: Boolean,
      $sponsorLevel: String,
      $socialAccounts: [CreatorSocialInput],
      $welcomeVideo: ID,
      $profileImage_crop: Boolean = false,
      $profileImage_height: Int = ${defaults.imageSize},
      $profileImage_width: Int = ${defaults.imageSize},
      $promoImage_crop: Boolean = false,
      $promoImage_height: Int = ${defaults.imageSize},
      $promoImage_width: Int = ${defaults.imageSize},
      $thumbnailImage_crop: Boolean = false,
      $thumbnailImage_height: Int = ${defaults.imageSize},
      $thumbnailImage_width: Int = ${defaults.imageSize},
      $expoThumbnailImage_crop: Boolean = false,
      $expoThumbnailImage_height: Int = ${defaults.imageSize},
      $expoThumbnailImage_width: Int = ${defaults.imageSize},
      $graphicsImage_crop: Boolean = false,
      $graphicsImage_height: Int = ${defaults.imageSize},
      $graphicsImage_width: Int = ${defaults.imageSize},
      $logoImage_crop: Boolean = false,
      $logoImage_height: Int = ${defaults.imageSize},
      $logoImage_width: Int = ${defaults.imageSize}) {
      ExhibitorMutation(
        _target: $target,
        _workflow: $workflow,
        associatedUsers: $associatedUsers,
        companyContactEmail: $companyContactEmail,
        companyContactUsPage: $companyContactUsPage,
        companyDescription: $companyDescription,
        companyFaqs: $companyFaqs,
        companySupportPage: $companySupportPage,
        companyWebsite: $companyWebsite,
        contentLocale: $contentLocale,
        downloadableResources: $downloadableResources,
        graphics: $graphics,
        logo: $logo,
        name: $name,
        promotions: $promotions,
        socialAccounts: $socialAccounts,
        sponsor: $sponsor,
        sponsorLevel: $sponsorLevel
        welcomeVideo: $welcomeVideo) {
        result {
          ...ExhibitorDetail
        }
      }
    }
  `)
}

export async function getExhibitorByIdAndLocale(client, { id, locale }) {
  const variables = {
    target: { id, locale },
    ...promoImage,
    ...thumbnailImage,
    ...logoImage,
    ...expoThumbnailImage,
    ...graphicsImage,
    ...profileImage,
  }

  // NOTE: this is a mutation so we can to query the creator by locale
  const response = await client.mutate({ mutation: getExhibitorMutation(), variables })
  return response?.data?.ExhibitorMutation?.result
}

export async function createExhibitor(client, exhibitor) {
  let socialAccounts = []
  if (exhibitor?.socialAccounts) {
    socialAccounts = exhibitor.socialAccounts.map((socialAccount) => ({
      platform: socialAccount.platform,
      urlOrHandle: socialAccount.urlOrHandle ? socialAccount.urlOrHandle : '',
    }))
  }

  const variables = {
    companyDescription: exhibitor.companyDescription,
    companyWebsite: exhibitor.companyWebsite ? exhibitor.companyWebsite : null,
    logo: exhibitor.logo,
    name: exhibitor.name,
    socialAccounts,
    ...promoImage,
    ...thumbnailImage,
    ...logoImage,
    ...expoThumbnailImage,
    ...graphicsImage,
    ...profileImage,
  }

  if (exhibitor?.exhibitorAssociatedUsers) {
    variables.associatedUsers = exhibitor?.exhibitorAssociatedUsers
  }
  if (exhibitor.sponsor !== null) {
    variables.sponsor = exhibitor.sponsor
  }
  if (exhibitor.sponsorLevel !== null) {
    variables.sponsorLevel = exhibitor.sponsorLevel
  }
  if (exhibitor?.transition) {
    variables.workflow = { action: 'TRANSITION', targetState: { next: true } }
  }
  if (exhibitor?.companyContactEmail) {
    variables.companyContactEmail = exhibitor.companyContactEmail
  }
  if (exhibitor?.companyContactUsPage) {
    variables.companyContactUsPage = exhibitor.companyContactUsPage
  }
  if (exhibitor?.companyFaqs) {
    variables.companyFaqs = exhibitor.companyFaqs
  }
  if (exhibitor?.companySupportPage) {
    variables.companySupportPage = exhibitor.companySupportPage
  }
  if (exhibitor?.downloadableResources) {
    variables.downloadableResources = exhibitor.downloadableResources
  }

  variables.graphics = exhibitor?.graphics
  variables.welcomeVideo = exhibitor?.welcomeVideo

  // If we have an exhibitor id we are doing an update.  Otherwise, it is a create
  if (exhibitor?.id) {
    variables.target = { id: exhibitor.id, locale: exhibitor.locale }
  } else {
    variables.contentLocale = exhibitor?.locale ? exhibitor.locale : ''
  }

  if (exhibitor?.promotions) {
    variables.promotions = {
      _id: exhibitor.promotions?.id || '',
      description: exhibitor.promotions?.description,
      title: exhibitor.promotions?.title,
      item: { type: 'EXTERNAL', external: { url: exhibitor.promotions?.url } },
      image: exhibitor.promotions?.thumbnail || '',
    }
  }

  const response = await client.mutate({ mutation: mutateExhibitor(), variables })
  return response?.data?.ExhibitorMutation?.result
}

export async function updateExhibitor(client, exhibitor) {
  return createExhibitor(client, exhibitor)
}
