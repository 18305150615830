import React from 'react'
import { Divider, H3, Separator, Subheading, colors } from '@fs/zion-ui'
import { css } from '@emotion/core'
import SafeRichText from '../SafeRichText'
import { flavors } from '../../config'
import BorderCard from './BorderCard'

const subheadingCss = css`
  color: ${colors.yellow80};
`

export default function FeatureCard({ title, overlineText, description, flavor = flavors.default, actions }) {
  return (
    <BorderCard flavor={flavor} topSeparator={false} hideDivider margins="sparse">
      <H3>
        {title}
        <Separator size="xxs" />
        <Subheading>
          <span css={subheadingCss}>
            <SafeRichText as="span" dirtyInput={overlineText} />
          </span>
        </Subheading>
      </H3>
      <Divider marginY="xxs" />
      <SafeRichText size="sm" dirtyInput={description} />
      {actions && <Separator />}
      {actions}
    </BorderCard>
  )
}
