import * as Yup from 'yup'
import { useZionForm } from '@fs/zion-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'

export default function usePlaylistForm(playlist) {
  const [t] = useTranslation()
  const requiredString = t('generic.required', 'Required')

  const playlistSchema = Yup.object().shape({
    name: Yup.string().required(requiredString),
    description: Yup.string().trim(),
  })
  const methods = useZionForm({
    mode: 'onSubmit',
    resolver: yupResolver(playlistSchema),
    defaultValues: {
      name: playlist.name,
      description: playlist.description,
    },
  })

  return { methods }
}
