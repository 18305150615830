import { Bleed, Paragraph, Image } from '@fs/zion-ui'
import React from 'react'

export default function FullWidthImage({ image, alt = '', caption }) {
  return (
    <Bleed sides as="figure">
      <Image src={image} alt={alt} style={{ aspectRatio: '3 / 2' }} />
      <Paragraph as="figcaption" size="sm">
        {caption}
      </Paragraph>
    </Bleed>
  )
}
