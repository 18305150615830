const brightcoveConfig = {
  liveStream: {
    accountId: '710857117001',
    testVideoId: '6298856962001',
  },
  master: {
    accountId: '1241706627001',
    testVideoId: '6298850459001',
  },
}

const translateVideoConfig = {
  youtube: { providerType: 'youtube', videoId: 'CadZpJ7AlvQ' },
  brightcove: { providerType: 'youtube', videoId: 'PexANw32ywM' },
}

export { brightcoveConfig, translateVideoConfig }
