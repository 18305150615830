import SearchFilterItem from './SearchFilterItem'

const SearchFilter = `
  ${SearchFilterItem}
  fragment SearchFilter on SearchFilter {
    active
    key
    label
    options {
      ...SearchFilterItem
    }
  }
`
export default SearchFilter
