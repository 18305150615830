import axios from '@fs/zion-axios'
import SERVICE from './serviceName'

// DOCS: https://www.familysearch.org/service/tree/ftuser/

const BASE = `${SERVICE}/ftuser`

const axiosInstance = axios.create()

export default function getCurrentUser(disableCache) {
  const options = disableCache
    ? {
        headers: {
          'Cache-Control': 'no-cache',
        },
      }
    : undefined
  return axiosInstance.get(`${BASE}/users/CURRENT`, options)
}
