import React from 'react'
import { LayoutBand, H3, Subheading } from '@fs/zion-ui'
import BackButtonRow from './BackButtonRow/BackButtonRow'
import ResponsiveSeparator from './ResponsiveSeparator'

export default function NoContentFound({ heading, subHeading, actions, noBackButton }) {
  return (
    <LayoutBand maxWidth="sm">
      {!noBackButton && <BackButtonRow />}
      <ResponsiveSeparator />
      <H3 centered>
        {heading}
        <Subheading>{subHeading}</Subheading>
      </H3>
      <ResponsiveSeparator />
      {actions}
    </LayoutBand>
  )
}
