const supportConfig = {
  chatLocales: ['de', 'en', 'es', 'fr', 'it', 'ja', 'ko', 'pt', 'ru', 'zh', 'zh-hans'],
  communityLinks: {
    de: 'https://community.familysearch.org/de',
    en: 'https://community.familysearch.org/en',
    es: 'https://community.familysearch.org/es',
    fj: 'https://community.familysearch.org/en',
    fr: 'https://community.familysearch.org/fr',
    hu: 'https://community.familysearch.org/en/categories/magyar',
    id: 'https://community.familysearch.org/en',
    it: 'https://community.familysearch.org/it',
    ja: 'https://community.familysearch.org/ja',
    km: 'https://community.familysearch.org/en',
    ko: 'https://community.familysearch.org/ko',
    mn: 'https://community.familysearch.org/en',
    nl: 'https://community.familysearch.org/en/categories/nederlands',
    no: 'https://community.familysearch.org/en/categories/norsk',
    pt: 'https://community.familysearch.org/pt',
    ru: 'https://www.facebook.com/groups/familysearchrus',
    sm: 'https://community.familysearch.org/en',
    th: 'https://community.familysearch.org/en',
    tl: 'https://community.familysearch.org/en',
    to: 'https://community.familysearch.org/en',
    vi: 'https://community.familysearch.org/en',
    zh: 'https://community.familysearch.org/zh',
    'zh-hans': 'https://community.familysearch.org/zh',
  },
}

export default supportConfig
