import React from 'react'
import { FlowGrid, Separator, useContainerWidth, WhiteTheme, H2, Subheading } from '@fs/zion-ui'
import { getCustomImage, isExternalUrl } from './helpers'
import RainbowBand from './RainbowBand'
import SectionDescription from './section-card/SectionDescription'
import NewCard from './card/NewCard'
import ResponsiveSeparator from './ResponsiveSeparator'

export default function RainbowBandCardSection({ data, flavor }) {
  const atWidth = useContainerWidth()

  return (
    <RainbowBand maxWidth="md" as="section" flavor={flavor}>
      {data?.title ? <ResponsiveSeparator /> : <Separator size={atWidth({ default: 'xs', sm: 'sm' })} />}
      <H2 size="H1" centered>
        {data?.title}
        <WhiteTheme>
          <Subheading>
            <strong>{data?.secondaryTitle}</strong>
          </Subheading>
        </WhiteTheme>
      </H2>
      {data?.description && (
        <>
          <Separator size="xs" />
          <SectionDescription fullWidth centered bold>
            {data?.description}
          </SectionDescription>
        </>
      )}
      <Separator size="lg" />
      <FlowGrid columnCount={atWidth({ default: 1, lg: 2 })}>
        {data?.items.map((item) => {
          return (
            <NewCard
              key={item?.title}
              heading={item?.title}
              description={item?.description}
              image={getCustomImage(item)}
              to={item?.url}
              external={isExternalUrl(item?.url)}
            />
          )
        })}
      </FlowGrid>
      <ResponsiveSeparator />
    </RainbowBand>
  )
}
