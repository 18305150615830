import {
  getPlaylists,
  createPlaylist,
  addPlaylistItem,
  updatePlaylist,
  deletePlaylist,
  removePlaylistItem,
  updatePlaylistItemsOrder,
  getPlaylistItems,
  getPlaylistsForItem,
  followPlaylist,
  addMultipleItemsToPlaylist,
} from '../api/rootstech/playlists'

export default function usePlaylistsApi() {
  return {
    getPlaylistsApi: getPlaylists,
    createPlaylistApi: createPlaylist,
    deletePlaylistApi: deletePlaylist,
    updatePlaylistApi: updatePlaylist,
    addToPlaylistApi: addPlaylistItem,
    bulkAddToPlaylistApi: addMultipleItemsToPlaylist,
    removeFromPlaylistApi: removePlaylistItem,
    reorderPlaylistApi: updatePlaylistItemsOrder,
    getPlaylistItemsApi: getPlaylistItems,
    getPlaylistsForItemApi: getPlaylistsForItem,
    followPlaylistApi: followPlaylist,
  }
}
