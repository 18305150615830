import { i18n } from '@fs/zion-locale'
import { FLAGS, navigationConfig } from '../../config'
import { useFlagsContext } from '../../providers/RootstechFlagsProvider'

const useNavBarItems = () => {
  const { [FLAGS.SITE_EXPERIENCE]: siteExperienceFlag } = useFlagsContext()

  return (
    navigationConfig[siteExperienceFlag.treatment]?.[i18n.language] ??
    navigationConfig[siteExperienceFlag.treatment]?.default ??
    []
  )
}

export default useNavBarItems
