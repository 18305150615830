import React from 'react'
import { css } from '@emotion/core'
import { Button } from '@fs/zion-ui'
import { MenuNewWindow } from '@fs/zion-icon'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const contentCss = css`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`
const videoWrapperCss = css`
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 56.25%;
  box-shadow: 0px 0px 48px #000000bf;
`

const scrimCss = css`
  width: 100%;
  height: 100%;
  background: #00000059;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const Scrim = ({ children }) => {
  return <div css={scrimCss}>{children}</div>
}

const webinarCss = css`
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & > div > span {
    margin-bottom: 20px;
    padding: 10px 20px;
  }
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

export default function WebinarPlayer({ thumbnail, url }) {
  const [t] = useTranslation()

  return (
    <div style={{ aspectRatio: '16/9', width: '100%' }}>
      <div css={contentCss}>
        <div css={videoWrapperCss}>
          <div css={webinarCss} style={{ backgroundImage: `url(${thumbnail})` }}>
            <Scrim>
              <span>
                {t('sessions.webinar.viewing', 'This session is available for viewing using an external site.')}
              </span>
              {url && (
                <Button
                  to={url}
                  emphasis="high"
                  external
                  target="_blank"
                  Icon={MenuNewWindow}
                  linkName="Webinar: View this Session"
                >
                  {t('sessions.webinar.view', 'View this session')}
                </Button>
              )}
            </Scrim>
          </div>
        </div>
      </div>
    </div>
  )
}

WebinarPlayer.propTypes = {
  url: PropTypes.string,
  thumbnail: PropTypes.string,
}
