const marketoConfig = {
  subscriptionForms: {
    de: 1256,
    en: 1253,
    es: 1262,
    fr: 1255,
    it: 1257,
    ja: 1258,
    ko: 1259,
    pt: 1260,
    ru: 1261,
    zh: 1254,
    'zh-hans': 1322,
    'zh-hant': 1254,
  },
  sponsorForms: {
    de: 1247,
    en: 1173,
    es: 1227,
    fr: 1248,
    it: 1249,
    ja: 1250,
    ko: 1251,
    pt: 1230,
    ru: 1252,
    zh: 1246,
    'zh-hans': 1245,
    'zh-hant': 1246,
  },
  registerOnlineForms: {
    de: 1029,
    en: 1003,
    es: 1027,
    fr: 1026,
    it: 1030,
    ja: 1032,
    ko: 1034,
    pt: 1028,
    ru: 1031,
    zh: 1033,
    'zh-hans': 1033,
    'zh-hant': 1033,
  },
  registerDiscoveryDayForms: {
    de: 1343,
    en: 1343,
    es: 1343,
    fr: 1343,
    it: 1343,
    ja: 1343,
    ko: 1343,
    pt: 1343,
    ru: 1343,
    zh: 1343,
    'zh-hans': 1343,
    'zh-hant': 1343,
  },
  registerExpoHallForms: {
    de: 1342,
    en: 1342,
    es: 1342,
    fr: 1342,
    it: 1342,
    ja: 1342,
    ko: 1342,
    pt: 1342,
    ru: 1342,
    zh: 1342,
    'zh-hans': 1342,
    'zh-hant': 1342,
  },
  registerYoungAdultForms: {
    de: 1344,
    en: 1344,
    es: 1344,
    fr: 1344,
    it: 1344,
    ja: 1344,
    ko: 1344,
    pt: 1344,
    ru: 1344,
    zh: 1344,
    'zh-hans': 1344,
    'zh-hant': 1344,
  },
  proposalForms: {
    de: 1140,
    en: 1185,
    es: 1136,
    fr: 1139,
    it: 1142,
    ja: 1161,
    ko: 1159,
    pt: 1134,
    ru: 1098,
    zh: 1155,
    'zh-hans': 1157,
    'zh-hant': 1155,
  },
}

export default marketoConfig
