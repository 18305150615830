import React, { createContext, useContext, useState, useEffect } from 'react'
import { usePermissionsAccess, useStableObject } from '../../../hooks'

const DashboardPermissionsContext = createContext()
export const useDashboardPermissionsContext = () => useContext(DashboardPermissionsContext)

export const DashboardPermissionsProvider = ({ children }) => {
  const permissions = usePermissionsAccess()
  const [tempPermissions, setPermissions] = useState(permissions)

  // This can potentially cause race condition when the server returns the permissions state it may override the toggled state.
  const updatePermissions = (name, checked) => {
    setPermissions((oldPermissions) => {
      return { ...oldPermissions, [name]: checked }
    })
  }

  const stablePermissions = useStableObject(permissions)
  useEffect(() => {
    setPermissions(stablePermissions)
  }, [stablePermissions])

  const { hasSuperAdminAccess, hasContentAdminAccess } = tempPermissions

  const hasDashboardAccess = Object.values(tempPermissions).some(Boolean)
  const hasDashboardAuthorizationAccess = hasContentAdminAccess || hasSuperAdminAccess

  return (
    <DashboardPermissionsContext.Provider
      // FIXME: This is a temporary fix. We need to remove this eslint-disable line and fix the issue.
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        permissions: { ...tempPermissions, hasDashboardAccess, hasDashboardAuthorizationAccess },
        updatePermissions,
      }}
    >
      {children}
    </DashboardPermissionsContext.Provider>
  )
}
