import React from 'react'
import { DialogOverlay, useOverlay } from '@fs/zion-ui'
import { PlaceWorld } from '@fs/zion-icon'
import { useTranslation } from 'react-i18next'
import ChewableButton from './ChewableButton'
import { VideoProvider } from '../video'
import { translateVideoConfig } from '../../config'

export default function AutoTranslateButton({ providerType = 'youtube', asIconButton, size = 'sm' }) {
  const overlay = useOverlay()
  const [t] = useTranslation()
  const videoProvider = translateVideoConfig[providerType]

  return (
    <>
      <ChewableButton
        Icon={PlaceWorld}
        data-testid="cc-instructions"
        onClick={overlay.handleClick}
        emphasis="low"
        size={size}
        linkName="Auto-Translate"
        asIconButton={asIconButton}
      >
        {t('auto-translate.action', 'Auto-translate')}
      </ChewableButton>
      {(overlay.isOpen || overlay.transitioning) && (
        <DialogOverlay
          title={t('auto-translate.heading', 'Auto-translate guide')}
          {...overlay}
          size="xl"
          closeOnClickAway
        >
          <VideoProvider videoProvider={videoProvider} />
        </DialogOverlay>
      )}
    </>
  )
}
