import React from 'react'
import { css } from '@emotion/core'
import { format } from '@fs/zion-locale/date-fns'
import { Separator, Row, useContainerWidth, H2, Subheading, Paragraph } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { PillButton } from '../buttons'
import { stageSeafoamJpg } from '../../images'
import CountDown from './CountDown'
import ResponsiveSeparator from '../ResponsiveSeparator'
import { getNowTimestamp } from '../helpers/dateTimeHelpers'

const backgroundCss = css`
  background-image: url(${stageSeafoamJpg});
  aspect-ratio: 16 / 9;
  display: grid;
  place-content: center;
  background-size: cover;
  padding: var(--bleedable-sides);
`

export default function CountdownPlaceholder({ startTimestamp }) {
  const { t } = useTranslation()
  const atWidth = useContainerWidth()
  const nowTimestamp = getNowTimestamp()
  const showCountdown = startTimestamp > nowTimestamp

  return (
    <div css={backgroundCss}>
      <Separator />

      <H2 centered size={atWidth({ default: 'H4', sm: 'H3' })}>
        <Paragraph size="lg">{`${t('countdown.placeholder.begins-in', 'Your video begins in')}:`}</Paragraph>
        <Separator size="xs" />
        {showCountdown && (
          <Row alignX="center">
            <CountDown startTimestamp={startTimestamp} />
          </Row>
        )}
        <Separator size="xs" />
        <Subheading>{format(new Date(startTimestamp), 'PPPPp (z)')}</Subheading>
      </H2>
      <Separator size="xs" />
      <Row alignX="center">
        <PillButton linkName="View Schedule" data-testid="button-save-draft" emphasis="medium" to="/schedule">
          {t('countdown.placeholder.view-schedule', 'View Schedule')}
        </PillButton>
      </Row>
      <ResponsiveSeparator />
    </div>
  )
}
