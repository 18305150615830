import CountryTagDataReference from './CountryTagDataReference'

const ContactInformation = `
  ${CountryTagDataReference}
  fragment ContactInformation on CreatorContactInformation {
    email
    countryTags {
      ...CountryTagDataReference
    }
  }
`
export default ContactInformation
