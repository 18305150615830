import React, { Children } from 'react'
import { i18n } from '@fs/zion-locale'
import { useTranslation } from 'react-i18next'
import { ListItem } from '@fs/zion-ui'
import { getNativeLanguageName } from '../../../lib'

const LanguageItems = ({ dense = false, onClick, onLangClick, removeFiller = false }) => {
  const userLocale = i18n.language
  const langText = getNativeLanguageName(userLocale)
  const [t] = useTranslation()

  const items = [
    {
      'data-testid': 'menu-region',
      linkName: 'Menu: Region',
      primaryText: t('navigation.region.label', 'Select Region/Country'),
      onClick,
      to: '/regions/',
      dense,
      startElement: removeFiller ? undefined : <ListItem.Filler />,
    },
    {
      'data-testid': 'menu-language',
      linkName: 'Menu: Language',
      primaryText: t('navigation.language.label', 'Language — {lang}', {
        lang: langText,
      }),
      onClick: onLangClick,
      dense,
      startElement: removeFiller ? undefined : <ListItem.Filler />,
    },
  ]

  return Children.toArray(items.map((item) => <ListItem {...item} />))
}

export default LanguageItems
