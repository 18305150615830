import React from 'react'
import { H2, Separator, Subheading } from '@fs/zion-ui'
import { useTranslation, Trans } from 'react-i18next'
import { Link } from '@fs/zion-router'
import ResponsiveSeparator from '../../../lib/ResponsiveSeparator'

export default function LibraryHeading() {
  const [t] = useTranslation()
  return (
    <>
      <ResponsiveSeparator />
      <H2 centered>
        {t('home.on-demand.title', 'Learn from the On-Demand Library')}
        <Separator />
        <Subheading>
          <Trans
            i18nKey="home.on-demand.description"
            defaults="Browse featured content below, or browse the <searchLink>on-demand library</searchLink>."
            components={{
              searchLink: <Link to="/library" linkName="Browse Library" />,
            }}
          />
        </Subheading>
      </H2>
    </>
  )
}
