const KB = 1024
const MB = KB * KB
const GB = MB * KB

const memoryConfig = {
  maxVideoSize: 5 * GB,
  maxThumbnailSize: 15 * MB,
  maxLogoSize: 15 * MB,
  maxGraphicsSize: 15 * MB,
}

export { memoryConfig, KB, MB, GB }
