import React, { createContext, useEffect } from 'react'
import { useQueryParams } from '@fs/zion-router'
import { usePlaylistsContext } from './PlaylistsProvider'

const AutoAddToPlaylistContext = createContext()

export const AutoAddToPlaylistProvider = ({ children }) => {
  const { query, removeQuery } = useQueryParams()
  const { addPlaylistItem } = usePlaylistsContext()

  useEffect(() => {
    if (query.addToPlaylist) {
      addPlaylistItem({ contentId: query.addToPlaylist, type: query.playlistItemType })
      removeQuery('addToPlaylist', 'playlistItemType')
    }
  }, [query, removeQuery, addPlaylistItem])

  return <AutoAddToPlaylistContext.Provider value={null}>{children}</AutoAddToPlaylistContext.Provider>
}

export default AutoAddToPlaylistProvider
