import axios from '@fs/zion-axios'
import SERVICE from './serviceName'

// DOCS: https://www.familysearch.org/service/user/profile/
// https://fhconfluence.churchofjesuschrist.org/pages/viewpage.action?spaceKey=WWSupport&title=Project+-+User+Profile

const BASE = `${SERVICE}/profile`

export default function updateProfile(cisId, profile) {
  return axios.put(`${BASE}/users/${cisId}`, profile)
}
