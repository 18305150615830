import PropTypes from 'prop-types'
import React from 'react'
import { Button, Row, Separator } from '@fs/zion-ui'
import { useHistory } from '@fs/zion-router'
import { Arrow } from '@fs/zion-icon'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

const whiteTextHackCss = css`
  & * {
    color: #ffffff !important;
  }
`
export default function BackButtonRow({ backText, children, ...props }) {
  const [t] = useTranslation()
  const history = useHistory()
  return (
    <>
      <Separator size="xxs" />
      <Row alignY="middle" {...props}>
        <div css={whiteTextHackCss} growX>
          {history.length > 1 && (
            <Button
              linkName="Back"
              onClick={history.goBack}
              Icon={Arrow}
              iconDirection="backward"
              data-testid="back-button"
              emphasis="low"
              size="sm"
            >
              {backText || t('common-ui:back.action', 'Back')}
            </Button>
          )}
        </div>
        {children}
      </Row>
      <Separator size="xxs" />
    </>
  )
}

BackButtonRow.propTypes = {
  backText: PropTypes.string,
  children: PropTypes.node,
}
