import React from 'react'
import { css } from '@emotion/core'
import { NoticeLoading } from '@fs/zion-icon'
import { PropTypes } from 'prop-types'

const spinnerCss = css`
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: white;
`
export default function LoadingSpinner({ size = 'lg' }) {
  return (
    <div css={spinnerCss} data-testid="spinner">
      <NoticeLoading size={size} />
    </div>
  )
}

LoadingSpinner.propTypes = {
  /** Size of icon */
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
}
