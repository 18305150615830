import { useCallback, useEffect, useState } from 'react'
import { useUser } from '@fs/zion-user'

import { postUserApproval } from '../api'

const SUCCESSFUL = 200
const FORBIDDEN = 403

export default function useChatApproval() {
  const { signedIn, cisId, userLoading } = useUser()
  const [loading, setLoading] = useState(userLoading)
  const [isChatApproved, setIsChatApproved] = useState(false)

  const getData = useCallback(async () => {
    const response = await postUserApproval({ cisIds: [cisId] })
    if (response?.status !== SUCCESSFUL && response?.status === FORBIDDEN) {
      setIsChatApproved(false)
      setLoading(false)
    } else if (response?.status === SUCCESSFUL) {
      setIsChatApproved(true)
      setLoading(false)
    }
  }, [cisId])

  useEffect(() => {
    if (signedIn && !userLoading) {
      getData()
    } else if (!signedIn && !userLoading) {
      setIsChatApproved(true)
      setLoading(false)
    }
  }, [getData, signedIn, userLoading])

  return { isChatApproved, loading }
}
