/* istanbul ignore file */
import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { getSessionId } from '@fs/zion-session'
import * as tus from 'tus-js-client'
import { useTranslation } from 'react-i18next'
import { ProgressOverlayStatus, useProgressOverlay } from '@fs/zion-ui'

const { IN_PROGRESS, SUCCESSFUL, FAILED } = ProgressOverlayStatus
const headerMessageIcu = `
{totalFileCount, plural,
  =1 {
    {status, select,
      stillProcessing {Uploading 1 file}
      allCompleted {1 file uploaded}
      allFailed {Upload failed}
      other {Uploading 1 file}
    }
  }
  other {
    {status, select,
      stillProcessing {Uploading {inProgressFileCount} of {totalFileCount} files}
      allCompleted {{successfulFileCount} of {totalFileCount} files uploaded}
      allFailed {Upload failed}
      other {Uploading {inProgressFileCount} of {totalFileCount} files}
    }
  }
}
`

/**
 * Groups the uploads by status.  This is used to help display the ProgressOverlay header message.
 */
const getUploadsGroupedByStatus = (uploadItems) => {
  return uploadItems.reduce(
    (counts, uploadItem) => {
      counts[uploadItem.status]++
      counts.totalFileCount++
      return counts
    },
    { [IN_PROGRESS]: 0, [SUCCESSFUL]: 0, [FAILED]: 0, totalFileCount: 0 }
  )
}

/**
 * Creates the ProgressOverlay header message.
 */
function getHeaderMessage(statusCounts, t) {
  const inProgressFileCount = statusCounts[IN_PROGRESS]
  const successfulFileCount = statusCounts[SUCCESSFUL]
  const failedFileCount = statusCounts[FAILED]
  const totalFileCount = statusCounts.totalFileCount
  const values = { status: 'allCompleted', totalFileCount, inProgressFileCount, successfulFileCount }

  if (inProgressFileCount > 0) {
    values.status = 'stillProcessing'
  } else if (failedFileCount === totalFileCount) {
    values.status = 'allFailed'
  }

  return t('upload-progress.header', headerMessageIcu, values)
}

/**
 * Split Tus config values and custom file uploader config values into separate objects.
 */
const splitConfig = (config) => {
  const { onValidate, onCancel, onAfterUploaded, setInProgressMessage, ...tusConfig } = config
  const uploadConfig = {
    onValidate,
    onCancel,
    onAfterUploaded,
    setInProgressMessage,
  }
  return { uploadConfig, tusConfig }
}

/**
 * Sets the content type on the Tus config object.
 */
const setAuthHeader = (tusConfig) => {
  const sessionId = getSessionId()
  tusConfig.headers = { Authorization: `Bearer ${sessionId}`, ...tusConfig.headers }
}

const setMetadata = (tusConfig, file) => {
  tusConfig.metadata = {
    filename: file.name,
    mediaType: file.type,
    extra: tusConfig.metadata,
  }
}

const setDefaultTusConfig = (clonedConfig, file) => {
  setAuthHeader(clonedConfig)
  setMetadata(clonedConfig, file)
  const { endpoint, uploadSize, overridePatchMethod, chunkSize, removeFingerprintOnSuccess, addRequestId } =
    clonedConfig
  clonedConfig.endpoint = endpoint || `/service/memories/memmrus/uploads`
  clonedConfig.uploadSize = uploadSize || file.size
  clonedConfig.overridePatchMethod = overridePatchMethod !== undefined ? overridePatchMethod : true
  clonedConfig.chunkSize = chunkSize !== undefined ? chunkSize : 5000 // hasLowBandwidth ? 5000 : 3000000, // max: 5368709120,
  clonedConfig.removeFingerprintOnSuccess = removeFingerprintOnSuccess !== undefined ? removeFingerprintOnSuccess : true
  clonedConfig.addRequestId = addRequestId !== undefined ? addRequestId : true
  // Determine if we want to retry based on server response.

  // Don't try again.
  // 401 - Unauthorized
  // Try again.
  // 408 - Timeout, probably try again
  // 500, 501, 502, 503, 504
  // clonedConfig.onShouldRetry = (err, retryAttempt, options) => {
  //   console.log('Error', err)
  //   console.log('Request', err.originalRequest)
  //   console.log('Response', err.originalResponse)

  //   let status = err.originalResponse ? err.originalResponse.getStatus() : 0
  //   // Do not retry if the status is a 403.
  //   if (status === 403) {
  //     return false
  //   }

  //   // For any other status code, we retry.
  //   return true
  // }
}

/**
 * Creates the Tus config for a single file.
 */
const cloneConfig = (tusConfig, file) => {
  const clonedConfig = { ...tusConfig }
  setDefaultTusConfig(clonedConfig, file)
  return clonedConfig
}

/**
 * Verify that the Tus config has the required properties.
 */
const validationTusConfig = () => {
  if (!tus.isSupported || !tus.canStoreURLs) {
    throw new Error('Resumable upload not supported by this browser.')
  }
  // Not sure what else we want to validate here.
}

/**
 * Check if the error is a Cancel object.
 */
const isUploadCancelled = (error) => error?.constructor?.name === 'Cancel'

const useResumableUploadProgress = () => {
  const { createProgressItem, setHeaderMessage, setOnDismiss } = useProgressOverlay()
  const [t] = useTranslation()
  const [uploadItems, setUploadItems] = useState([])
  const [uploadId, setUploadId] = useState(0)

  useEffect(() => {
    setOnDismiss(() => setUploadItems([]))
  }, [setOnDismiss])

  useEffect(() => {
    const statusCounts = getUploadsGroupedByStatus(uploadItems)
    const headerMessage = getHeaderMessage(statusCounts, t)
    setHeaderMessage(headerMessage)
  }, [uploadId, uploadItems, setHeaderMessage, t])

  const uploadFile = useCallback(
    async (file, tusConfig, uploadConfig) => {
      const clonedConfig = cloneConfig(tusConfig, file)
      const { setInProgressMessage, onCancel } = uploadConfig
      const uploadItem = { id: uploadId, status: IN_PROGRESS, file }
      setUploadId((current) => current + 1)
      setUploadItems((currentUploadItems) => [...currentUploadItems, uploadItem])
      const inProgressMessage = setInProgressMessage?.(uploadItem.file)
      const updateProgressItem = createProgressItem({
        message: inProgressMessage?.trim()?.length
          ? inProgressMessage
          : t('upload.processing', 'Processing {fileName}', { fileName: uploadItem.file.name }),
        status: IN_PROGRESS,
        percent: 0,
      })

      if (uploadConfig.onValidate) {
        let message = await Promise.resolve(uploadConfig.onValidate?.({ file, updateProgressItem }))
        /** If no progress message is returned, use the default. */
        if (!message?.trim?.()?.length) {
          message = t('upload.validating', 'Validating {fileName}', {
            fileName: uploadItem.file.name,
          })
        } else if (message.includes('{fileName}')) {
          message = message.replace('{fileName}', uploadItem.file.name)
        }
        updateProgressItem({
          message,
          status: IN_PROGRESS,
        })
      }

      updateProgressItem({
        message: inProgressMessage?.trim()?.length
          ? inProgressMessage
          : t('upload.uploading', 'Uploading {fileName}', { fileName: uploadItem.file.name }),
        status: IN_PROGRESS,
        percent: 0,
      })

      /** Used by TUS and when there is any other error in the process. */
      const handleError = async (error) => {
        const status = FAILED
        let message = await Promise.resolve(clonedConfig.onError?.({ error, file: uploadItem.file }))
        /** If no progress message is returned, use the default. */
        if (!message?.trim?.()?.length) {
          message = t('upload-progress.message.failed', 'Failed to upload {fileName}', {
            fileName: uploadItem.file.name,
          })
        }
        updateProgressItem({ message, status })

        setUploadItems((currentUploadItems) => {
          const index = currentUploadItems.indexOf(uploadItem)
          if (index > -1) {
            currentUploadItems[index] = { ...uploadItem, status }
          }
          return [...currentUploadItems]
        })
      }
      // FIXME: refactor to remove async from promise executor
      // eslint-disable-next-line no-async-promise-executor
      const uploadPromise = new Promise(async (resolve, reject) => {
        try {
          const upload = new tus.Upload(file, {
            ...clonedConfig,
            onError: handleError,
            onProgress: (bytesUploaded, bytesTotal) => {
              let percent = ((bytesUploaded / bytesTotal) * 100).toFixed(2)
              /** Limit percent to 99. The success handler will set it to 100.
               *  This is so that uploads that take a while to return an error won't show 100% and then change to a Failed status. */
              percent = percent > 99 ? 99 : percent
              updateProgressItem({ percent, status: IN_PROGRESS })
            },
            // onChunkComplete: (chunkSize, bytesAccepted, bytesTotal) => {
            //   let percent = ((bytesAccepted / bytesTotal) * 100).toFixed(2)
            /** Limit percent to 99. The success handler will set it to 100.
             *  This is so that uploads that take a while to return an error won't show 100% and then change to a Failed status. */
            //   percent = percent > 99 ? 99 : percent
            //   updateProgressItem({ percent, status: IN_PROGRESS })
            // },
            onSuccess: async () => {
              if (uploadConfig.onAfterUploaded) {
                await uploadConfig.onAfterUploaded({
                  f: file,
                  url: upload.url,
                  updateProgressItem,
                })
              }
              const status = SUCCESSFUL
              let message = await Promise.resolve(
                clonedConfig.onSuccess?.({ file: uploadItem.file, url: upload.url, updateProgressItem })
              )
              /** If no message is returned, use the default. */
              if (!message?.trim?.()?.length) {
                message = t('upload-progress.message.success', 'Successfully uploaded {fileName}', {
                  fileName: uploadItem.file.name,
                })
              } else if (message.includes('{fileName}')) {
                message = message.replace('{fileName}', uploadItem.file.name)
              }
              updateProgressItem({ message, percent: 100, status })

              setUploadItems((currentUploadItems) => {
                const index = currentUploadItems.indexOf(uploadItem)
                currentUploadItems[index] = { ...uploadItem, status }
                return [...currentUploadItems]
              })
              resolve({ file, url: upload.url, updateProgressItem })
            },
          })

          updateProgressItem({
            onCancel: async () => {
              let shouldCancelUpload = true
              if (uploadItem.status === IN_PROGRESS) {
                shouldCancelUpload = await onCancel?.(uploadItem.file)

                if (shouldCancelUpload || shouldCancelUpload === undefined) {
                  upload.abort(true) // apiCancel('File upload canceled by user.') // // upload.abort.bind(this, true)

                  /** Remove the canceled uploadItem from the list. */
                  setUploadItems((currentUploadItems) => {
                    const index = currentUploadItems.indexOf(uploadItem)
                    if (index > -1) {
                      currentUploadItems.splice(index, 1)
                    }
                    return [...currentUploadItems]
                  })
                }
              }
              if (shouldCancelUpload) {
                setUploadItems((currentUploadItems) =>
                  currentUploadItems.filter((currentFileUpload) => uploadItem.id !== currentFileUpload.id)
                )
              }

              return shouldCancelUpload
            },
          })
          upload.start()
        } catch (error) {
          handleError(error)
          // const errorWrapper = new Error()
          // errorWrapper.originalError = error
          // errorWrapper.setError = setError
          // errorWrapper.file = file
          reject(error)
        }
      })

      return uploadPromise
    },
    [uploadId, t, createProgressItem]
  )

  /**
   * The entry point to upload a list of files.
   */
  const uploadFiles = useCallback(
    async (config, files) => {
      const { uploadConfig, tusConfig } = splitConfig(config)
      validationTusConfig(tusConfig)
      /** Perform the file uploads */
      const results = await Promise.allSettled(
        files.map(async (file) => {
          let uploadResult

          try {
            uploadResult = await uploadFile(file, tusConfig, uploadConfig)
          } catch (error) {
            if (isUploadCancelled(error.originalError)) {
              error.file = file
            } else {
              error.setError(error.originalError)
            }
            return Promise.reject(error)
          }

          // try {
          //   /** The 'onAfterUploaded' callback allows the caller to perform some action after each file is uploaded. */
          //   // if (uploadConfig.onAfterUploaded) {
          //   //   const { result, updateProgressItem, url } = uploadResult
          //   //   additionalProcessing = await uploadConfig.onAfterUploaded({ result, file, url, updateProgressItem })
          //   // }
          // } catch (error) {
          //   // uploadResult.setError(error)
          //   const errorWrapper = new Error()
          //   errorWrapper.originalError = error
          //   errorWrapper.file = file
          //   return Promise.reject(errorWrapper)
          // }

          /** Don't update the progress item if the caller wants to do some additional processing after all file uploads have completed.
           *  If additional processing will be done by the caller, they will be responsible for updating the progress item. */
          // if (!additionalProcessing) {
          //   /** Since no errors where caught and no additional processing will be done, go ahead and set the progress item as successful. */
          //   uploadResult.setSuccess()
          // }
          return uploadResult
        })
      )

      /** After all file uploads have completed, whether successful, failed, or canceled, package up the results and return them. */
      const uploadResults = { successful: [], failed: [], canceled: [] }
      results.forEach((result) => {
        if (result.status === 'fulfilled') {
          uploadResults.successful.push(result.value)
        } else if (isUploadCancelled(result.reason.originalError)) {
          uploadResults.canceled.push({ error: result.reason.originalError, file: result.reason.file })
        } else {
          uploadResults.failed.push({ error: result.reason.originalError, file: result.reason.file })
        }
      })

      return uploadResults
    },
    [uploadFile]
  )

  return { uploadFiles, uploadFile }
}

export default useResumableUploadProgress

export const UploadType = (props) => <div {...props} />

UploadType.propTypes = {
  /** The value can be a single File object or an array of File objects. */
  // eslint-disable-next-line react/forbid-prop-types
  files: PropTypes.oneOf([File, [File]]).isRequired,
}

export const ConfigType = (props) => <div {...props} />

ConfigType.propTypes = {
  /** Defaults to use Memories Resumable Upload Service endpoint. The endpoint you want to use for uploading your files. */
  endpoint: PropTypes.string,
  /** Defaults to true. Set to false if your service endpoint supports PATCH. */
  overridePatchMethod: PropTypes.bool,
  /** Defaults to 50000. Amount of bytes to send in each chunk. Adjust accordingly for low bandwidth situations. */
  chunkSize: PropTypes.number,
  /** Defaults to true. Removes the local storage item once a resumable upload has completed. */
  removeFingerprintOnSuccess: PropTypes.bool,
  /** */
  addRequestId: PropTypes.bool,

  /** This callback function is called for each file before attempting to upload it.  It receives the file as its only parameter.  Throwing an
   *  `Error` or returning `Promise.reject(error)` will trigger the `onError` callback to be called. */
  onValidate: PropTypes.func,

  /** This callback function is called for each file that is successfully uploaded.  It will not be called for files that fail to upload.
   *  As its only parameter, it receives this object - `{result, file, updateProgressItem}`.  This contains the `Tus` result, the uploaded
   *  file, and a function for manually updating the `ProgressOverlay`.
   *
   *  If this callback completes successfully, the `ProgressOverlay` will be automatically updated to a successful status.  To prevent this
   *  automatic update, return `true` from this callback.  Then, once all files have been process, you will be able to manually update the
   * `ProgressOverlay` using the `updateProgressItem` function.
   *
   *  If this callback throws an `Error` or returns `Promise.reject(error)`, the `ProgressOverlay` will be automatically updated to a failed status. */
  onAfterUploaded: PropTypes.func,

  /** This callback function is called for each file that is uploaded successfully.  It receives the `Tus` response and the file as parameters.
   *  You can return a string from this callback if you want a custom success message displayed in the `ProgressOverlay`. */
  onSuccess: PropTypes.func,

  /** This callback function is called for each file that fails to upload.  It receives the error and the file as parameters.  You can return a
   *  string from this callback if you want a custom error message displayed in the `ProgressOverlay`. */
  onError: PropTypes.func,

  /** This callback function is called if the user clicks on the cancel button for this file in the `ProgressOverlay`.  It receives the
   *  file object as its only parameter.  Only files in progress can be canceled. */
  onCancel: PropTypes.func,

  /** This callback function is called for each file right before the upload begins. It receives the file object as its only parameter.  Use this
   * callback to provide a custom message to display in the `ProgressOverlay` while the file upload is in progress. */
  setInProgressMessage: PropTypes.string,
}
