import React from 'react'
import { IconButton, colors } from '@fs/zion-ui'
import { css } from '@emotion/core'
import { boolAttr } from '@fs/zion-frontend-friends'

const iconButtonHackCss = css`
  &:not([color]) {
    button,
    a {
      --button-text-color: ${colors.gray100};
      --button-background: ${colors.gray20};
      --button-hover-background: ${colors.transparent.gray40};
      --button-active-background: ${colors.transparent.gray20};
    }
  }
`

export default function TabletButton({ color, ...props }) {
  return (
    <div css={iconButtonHackCss} color={boolAttr(color)}>
      <IconButton color={color} {...props} />
    </div>
  )
}
