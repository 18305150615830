import StageDetail from './StageDetail'
import ContentVisibility from './ContentVisibility'

const CalendarDetail = `
  ${StageDetail}
  ${ContentVisibility}
  fragment CalendarDetail on CalendarDetail {
    title
    stages {
      ...StageDetail
    }
    visibility {
      ...ContentVisibility
    }
  }
`
export default CalendarDetail
