import React from 'react'
import { useTranslation } from 'react-i18next'
import { PageHead } from '@fs/zion-head'
import NoLandingPageFound from './NoLandingPageFound'
import { placeholderSeafoamJpg } from '../../images'
import PageContent from './content/PageContent'
import { PageLead } from './lead/PageLead'
import LoadingSpinner from '../LoadingSpinner'
import PageContainer from '../PageContainer'
import { getBrowserSeoLocales } from '../helpers'

const HtmlPageHead = ({ name, description, alternateLocales }) => {
  const [t] = useTranslation()
  const rtTitle = t('site-title', 'RootsTech')
  return (
    <PageHead
      title={name ? [name, rtTitle].join(' \u2022 ') : rtTitle}
      description={description}
      image={{ url: placeholderSeafoamJpg, width: 1200, height: 630 }}
      siteName={t('global.rootstech', 'RootsTech')}
      type="website"
      twitterSite="@RootsTechConf"
      includeHreflang
      hreflangConfig={{ languages: getBrowserSeoLocales(alternateLocales) }}
    />
  )
}

export default function GenericPage({ loading, data, flavor, rootPath }) {
  return (
    <>
      {loading && !data && <LoadingSpinner />}
      {!loading && !data && <NoLandingPageFound rootPath={rootPath} flavor={flavor} />}
      {!loading && data && (
        <>
          <HtmlPageHead name={data.name} description={data.description} alternateLocales={data.alternateLocales} />
          <PageContainer>
            <PageLead data={data} flavor={flavor} />
            <PageContent content={data.content} flavor={flavor} />
          </PageContainer>
        </>
      )}
    </>
  )
}
