/* eslint-disable no-underscore-dangle */
export const isConferencePromo = (item) => item.__typename === 'ConferencePromo'
export const isVideoLeadDetail = (item) => item.__typename === 'VideoLeadDetail'
export const isCalendarModule = (item) => item.__typename === 'CalendarModule'
export const isRichTextModule = (item) => item.__typename === 'RichTextModule'
export const isListModule = (item) => item.__typename === 'ListModule'
export const isVideoModule = (item) => item.__typename === 'VideoModule'
// right now, these are not in BSP
export const isCountdownModule = (item) => item.__typename === 'CountdownModule'
export const isLanguageBannerModule = (item) => item.__typename === 'LanguageBannerModule'
export const isMainStageModule = (item) => item.__typename === 'MainStageModule'

export const isWebinarVideoProvider = (item) => item.__typename === 'WebinarVideoProvider'
export const isYoutubeVideoProvider = (item) => item.__typename === 'YoutubeVideoProvider'
export const isBrightcoveVideoProvider = (item) => item.__typename === 'RootsTechBrightcoveVideoProvider'

export const isImageOptionDetail = (item) => item.__typename === 'ImageOptionDetail'
export const isSessionDetail = (item) => item.__typename === 'SessionDetail'
export const isSeriesDetail = (item) => item.__typename === 'SeriesDetail'
export const isClientContent = (item) => item.__typename === 'ClientContent'
export const isCreatorDetail = (item) => item.__typename === 'CreatorDetail'
export const isExhibitorDetail = (item) => item.__typename === 'ExhibitorDetail'

export const isVideoConferencePromo = (promo) => promo.contentType?.name === 'Video'
export const isSeriesConferencePromo = (promo) => promo.contentType?.name === 'Series'
export const isSessionConferencePromo = (promo) => promo.contentType?.name === 'Session'
export const isExhibitorConferencePromo = (promo) => promo.contentType?.name === 'Exhibitor'
export const isPageConferencePromo = (promo) => promo.contentType?.name === 'Page'
export const isExternalConferencePromo = (promo) => promo.contentType === null
export const isAttachmentConferencePromo = (promo) => promo.contentType?.name === 'Attachment'
export const isCreatorConferencePromo = (promo) => promo.contentType?.name === 'Creator'

export const isDraft = (item) => item?.visibility?.status === 'DRAFT'
export const isSubmitted = (item) => item?.visibility?.status === 'SUBMITTED'
export const isPublished = (item) => item?.visibility?.status === 'PUBLISHED'
