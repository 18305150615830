import { gql } from '@apollo/client'
import { CountryTagDataReference } from './fragments'

export default function getBsCountries() {
  return gql(`
    ${CountryTagDataReference}
    query QueryCountries {
      ConferenceDataViewModel {
        countryTags {
          ...CountryTagDataReference
        }
      }
    }
  `)
}
