import axios from '@fs/zion-axios'
import { sgBaseUrl } from '@fs/zion-config'
import SERVICE from './serviceName'

const BASE = `${SERVICE}/rtc/storage`
const SESSION_STATE_CHANGE = `${BASE}/webhooks/session-state-change`

const contentTypeMap = {
  mp4: 'video/mp4',
  mpeg: 'video/mpeg',
  qt: 'video/quicktime',
  mov: 'video/quicktime',
  webm: 'video/webm',
}

function fixedEncodeURIComponent(str) {
  // NOTE: escapes apostrophe, then properly encodes that character.
  return encodeURIComponent(str).replace(/[!'()*]/g, (c) => {
    return `%${c.charCodeAt(0).toString(16)}`
  })
}

export const getUploadUrl = async (cmsId, fileName) => {
  const extension = fileName.split('.').at(-1)
  const options = { headers: { 'Content-Type': contentTypeMap[extension] } }

  return axios.put(`${BASE}/uploads/${cmsId}/${fixedEncodeURIComponent(fileName)}`, {}, options)
}

export const getS3Url = (sessionId, fileName) => {
  return `${sgBaseUrl}${BASE}/uploads/${sessionId}/${fixedEncodeURIComponent(fileName)}`
}

export const changeSessionState = (sessionId) => {
  return `${SESSION_STATE_CHANGE}/${sessionId}`
}
