import React from 'react'
import { css } from '@emotion/core'

const noBulletCss = css`
  list-style: none;
`
export default function NoBulletList({ children }) {
  return (
    <ul style={{ listStyle: 'none' }} css={noBulletCss}>
      {children}
    </ul>
  )
}
