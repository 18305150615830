import React, { useState } from 'react'
import { ExpandableListItem, ListItem } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { PlaceWorld } from '@fs/zion-icon'
import LanguageItems from './LanguageItems'

const LanguageExpandableList = ({ dense, onClick, onLangClick }) => {
  const [t] = useTranslation()
  const [open, setOpen] = useState(false)

  return (
    <ExpandableListItem
      primaryText={t('navigation.language-and-regions.label', 'Language and Regions')}
      startElement={<ListItem.Icon Icon={PlaceWorld} />}
      data-testid="menu-languageAndRegions"
      dense={dense}
      open={open}
      setOpen={setOpen}
      linkName={`Language: ${open ? 'open' : 'close'}`}
      selected={open}
    >
      <LanguageItems dense={dense} onClick={onClick} onLangClick={onLangClick} />
    </ExpandableListItem>
  )
}

export default LanguageExpandableList
