import { useCallback, useEffect, useState } from 'react'
import { getWatchHistoryEnabled, setWatchHistoryEnabled } from '../../../api'
import { useRequest, useLazyRequest } from '../../useRequest'

export default function useHistoryEnabled() {
  const [isEnabled, setIsEnabled] = useState()
  const { loading, data } = useRequest(getWatchHistoryEnabled)
  const [setEnabled] = useLazyRequest(setWatchHistoryEnabled)

  const { enabled } = data || {}
  useEffect(() => {
    if (!loading) {
      setIsEnabled(enabled)
    }
  }, [enabled, loading])

  const enable = useCallback(() => {
    setEnabled(true)
    setIsEnabled(true)
  }, [setEnabled])
  const disable = useCallback(() => {
    setEnabled(false)
    setIsEnabled(false)
  }, [setEnabled])

  return { loading, isEnabled, enable, disable }
}
