import React from 'react'
import { Theme } from '@fs/zion-ui'

export default function BlackText({ children, ...props }) {
  return (
    <Theme theme="complementary" transparent {...props}>
      {children}
    </Theme>
  )
}
