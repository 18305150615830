import ImageAttributes from './ImageAttributes'

const ConferenceCustomImageCrop = `
  ${ImageAttributes}
  fragment ConferenceCustomImageCrop on ConferenceCustomImageCrop {
    image {
      ...ImageAttributes
    }
  }
`
export default ConferenceCustomImageCrop
