import { gql } from '@apollo/client'
import { SeriesDetail } from './fragments/SeriesDetail'
import { brightspotConfig } from '../../config'

const { defaults } = brightspotConfig.query

export default function getSeriesDetail() {
  return gql(`
    ${SeriesDetail}
    query SeriesDetail(
      $pathOrId: ID,
      $leadThumbnailImage_crop: Boolean = false,
      $leadThumbnailImage_height: Int = ${defaults.imageSize},
      $leadThumbnailImage_width: Int = ${defaults.imageSize},
      $promoImage_crop: Boolean = false,
      $promoImage_height: Int = ${defaults.imageSize},
      $promoImage_width: Int = ${defaults.imageSize},
      $logoImage_crop: Boolean = false,
      $logoImage_height: Int = ${defaults.imageSize},
      $logoImage_width: Int = ${defaults.imageSize},
      $thumbnailImage_crop: Boolean = false,
      $thumbnailImage_height: Int = ${defaults.imageSize},
      $thumbnailImage_width: Int = ${defaults.imageSize},
      $profileImage_crop: Boolean = false,
      $profileImage_height: Int = ${defaults.imageSize},
      $profileImage_width: Int = ${defaults.imageSize}) {
        SeriesDetail(id: $pathOrId) {
          ...SeriesDetail
        }
    }
  `)
}
