import { useEffect, useState } from 'react'
import { useUser } from '@fs/zion-user'
import getSessionIds from './helpers'
import { getHistoryEntries } from '../../api'
import useStableObject from '../useStableObject'
import { deepCopy } from '../../lib/helpers'
import { getChunkedPlaylistsByItems } from '../../lib/helpers/playlistsHelpers'
import notifySentry from '../../lib/helpers/sentryNotifier'

const augmentItem = ({ data, path, item, key }) => {
  if (!item) {
    return
  }

  if (path.length === 0) {
    data[key] = item
    return
  }

  let current = data
  for (let i = 0; i < path.length - 1; i++) {
    current = current[path[i]]
  }
  current[path.at(-1)][key] = item
}

const augment = async (data) => {
  if (!data) {
    return data
  }
  const idMap = getSessionIds(data)
  const ids = Object.keys(idMap)
  const requests = [getChunkedPlaylistsByItems, getHistoryEntries]
  const responses = await Promise.allSettled(requests.map((req) => req(ids)))
    .then((results) => {
      return results.map(({ status, value, reason }) => {
        if (status === 'fulfilled') {
          return value
        }

        notifySentry(reason)
        return []
      })
    })
    .catch((error) => {
      console.error('There was an error getting the data for the detail augmentation')
      notifySentry(error)
      // return the same number of requests, but empty arrays
      return requests.map(() => [])
    })

  // for each id, augment the data
  const mutableData = deepCopy(data)
  Object.entries(idMap).forEach(([id, path]) => {
    // handle playlists
    const playlistItem = responses[0][id]?.results
    augmentItem({ data: mutableData, path, item: playlistItem, key: 'playlists' })

    // handle watch history
    const whatHistoryItem = responses[1].find((entry) => entry.sessionId === id)
    augmentItem({ data: mutableData, path, item: whatHistoryItem, key: 'watchHistory' })
  })

  return mutableData
}

/**
 * This is used to get data from a service and augment it into the detail data so that we can batch all the ids at the top and save on network requests. The data that is augmented is then used in components down the chain to display applicable information.
 *
 * @param {Object} params.data - The initial data to be processed.
 * @param {boolean} params.loading - The loading state.
 * @returns {Object} return.data - The processed or initial data.
 * @returns {boolean} return.loading - The processing or loading state.
 */
const useDetailAugmentation = ({ data, loading }) => {
  const [processing, setProcessing] = useState(true)
  const [state, setState] = useState(data)
  const { signedIn } = useUser()

  const updateState = async (d, signal) => {
    setProcessing(true)
    const newState = await augment(d)
    if (!signal.aborted) {
      setState(newState)
      setProcessing(false)
    }
  }

  const stableData = useStableObject(data)
  useEffect(() => {
    if (!signedIn) {
      setState(stableData)
      setProcessing(false)
      return () => {}
    }

    const controller = new AbortController()
    const signal = controller.signal
    if (!loading) {
      updateState(stableData, signal)
    }

    return () => {
      // NOTE: It is possible to save the previous state when the new state is some form of empty. For example, the first render is the previous results, and it requests data for the services. the second render has empty data, so it updates instantly. Then the first render gets the data back and updates the state again. Using an abort controller will handle this case.
      controller.abort()
    }
  }, [loading, signedIn, stableData])

  return { data: state, loading: loading || processing }
}

export default useDetailAugmentation
