import React from 'react'
import { buildChatRoute, CHAT_ROUTE_KEY, useChatOverlay } from '@fs/zion-chat'
import ChatButton from '../ChatButton'

const chatRoute = buildChatRoute(CHAT_ROUTE_KEY.CREATE_SUPPORT_CHAT)

const AskUsAnythingButton = ({ onClick, ...props }) => {
  const chatOverlay = useChatOverlay(chatRoute)

  const onChatClick = (e) => {
    onClick?.()
    chatOverlay.handleClick(e)
  }

  return <ChatButton onClick={onChatClick} {...props} />
}

export default AskUsAnythingButton
