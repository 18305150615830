import React from 'react'
import { TimeTravelProvider } from './TimeTravelProvider'
import { CountryProvider } from './CountryProvider'
import { DashboardPermissionsProvider } from '../../components/dashboard/providers/DashboardPermissionsProvider'

const QualityAssuranceProviders = ({ children }) => {
  return (
    <TimeTravelProvider>
      <CountryProvider>
        <DashboardPermissionsProvider>{children}</DashboardPermissionsProvider>
      </CountryProvider>
    </TimeTravelProvider>
  )
}

export default QualityAssuranceProviders
