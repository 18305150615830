import React from 'react'
import { WhiteTheme } from '@fs/zion-ui'
import { format } from '@fs/zion-locale/date-fns'
import PropTypes from 'prop-types'
import { useFeatureFlag } from '@fs/zion-flags'
import { Badge } from './Badge'
import { getI18nDate, getNowTimestamp } from './helpers/dateTimeHelpers'
import { day, conferenceConfig } from '../config'
// import { propTypes as brightspot } from '../api/brightspot'

const sevenDays = 7 * day

export default function Timestamp({ timestamp, conferenceYear, isActive = false }) {
  // short-circuit to simplify the logic
  const scheduleFlag = useFeatureFlag('rootstech_schedule')

  if ((!timestamp || timestamp < 0) && !conferenceYear) {
    return <div />
  }

  const nowTimestamp = getNowTimestamp()
  const isPast =
    // if conference year is null, the current year is always bigger and we'll treat it as "past"
    (conferenceYear && conferenceConfig.year > Number(conferenceYear)) ||
    (timestamp && nowTimestamp > timestamp) ||
    false
  const isLive = !!timestamp

  const finalTimestamp = !isPast && !isLive ? conferenceConfig.startTimestamp : timestamp

  /**
   * 1. if it's in the past...
   *   a. don't show the time, timezone, or weekday.
   *   b. show the year
   * 2. if it's in the future and...
   *   a. it's in the next 7 days, show the weekday, time, and timezone.
   *   b. it's further out than the next seven days, show the month, day, year, time, and timezone
   */
  const formatOptions = {
    time: !isPast || isActive,
    timezone: !isPast || isActive,
    weekday: (!isPast || isActive) && finalTimestamp - nowTimestamp < sevenDays,
    year: isPast || `${new Date(finalTimestamp).getFullYear()}` !== conferenceYear,
  }

  const showTimestamp = isActive || (finalTimestamp > nowTimestamp && scheduleFlag.isOn) // currently running or in the future

  const label = showTimestamp ? (
    <time dateTime={format(finalTimestamp, 'yyyy-MM-dd h:MM')}>{getI18nDate(finalTimestamp, formatOptions)}</time>
  ) : (
    conferenceYear
  )
  const testId = timestamp && timestamp > 0 ? 'timestamp' : 'year'

  return label ? (
    // This div needs to stay so the outside Row can growX if needed
    <div>
      <Badge
        data-testid={`item-${testId}`}
        flavor="flavorless"
        keyline="left"
        label={<WhiteTheme>{label}</WhiteTheme>}
      />
    </div>
  ) : null
}

/* eslint-disable react/destructuring-assignment */
Timestamp.propTypes = {
  timestamp: PropTypes.number,
  // conferenceYear: brightspot.conferenceYear,
  isActive: PropTypes.bool,
}
/* eslint-enable react/destructuring-assignment */
