import React from 'react'
import { css } from '@emotion/core'
import { boolAttr } from '@fs/zion-frontend-friends'
import PropTypes from 'prop-types'
import { flavors, validFlavors, scrimGradient } from '../../config'

const gradientCss = css`
  --background-color: var(--gradient);
  &[solid] {
    --background-color: var(--solid);
  }
  background: var(--scrim), var(--background-color);
`

export default function SectionGradient({ flavor = flavors.default, noScrim, solid, children }) {
  return (
    <div
      style={{
        '--scrim': noScrim ? flavors.flavorless.filmedGradient : scrimGradient,
        '--gradient': flavors[flavor].gradient,
        '--solid': flavors[flavor].solid,
      }}
      css={gradientCss}
      solid={boolAttr(solid)}
    >
      {children}
    </div>
  )
}

SectionGradient.propTypes = {
  flavor: PropTypes.oneOf(validFlavors),
  noScrim: PropTypes.bool,
  children: PropTypes.node.isRequired,
}
