import { Bleed, Paragraph, H3, Card, Image, Separator, WhiteTheme, Row, Ellipsis, colors } from '@fs/zion-ui'
import React from 'react'
import { css } from '@emotion/core'
import { ContentLock, ContentUnlock } from '@fs/zion-icon'
import { getCustomImage } from '../../../helpers'
import { StrongLink } from '../../../link'
import SafeRichText from '../../../SafeRichText'
import { DraftBadge, PublishedBadge, SubmittedBadge } from '../../../Badge'

const cardHackCss = css`
  & > :nth-of-type(n) {
    border-radius: 20em;
  }
`

export default function SpeakerCard({ title, description, url, locale, badge, placeholder, lockedState, ...speaker }) {
  return (
    <div data-testid="speaker-card">
      <div css={cardHackCss}>
        <Card to={url}>
          <Bleed all>
            <Image src={getCustomImage(speaker, placeholder)} alt="" style={{ aspectRatio: '1 / 1' }} />
          </Bleed>
        </Card>
      </div>
      {lockedState && (
        <div style={{ marginTop: -48 }}>
          <Row alignX="end" guttersY="none">
            {lockedState === 'lock' ? (
              <ContentLock background="black" color="white" size="sm" />
            ) : (
              <ContentUnlock background="black" color="white" size="sm" />
            )}
          </Row>
        </div>
      )}

      <Separator size="xxs" />
      {badge && ['SUBMITTED', 'DRAFT', 'PUBLISHED', 'WORKFLOW'].includes(badge) && (
        <>
          <Row alignX="center" guttersY="none">
            {badge === 'DRAFT' && <DraftBadge />}
            {(badge === 'SUBMITTED' || badge === 'WORKFLOW') && <SubmittedBadge />}
            {badge === 'PUBLISHED' && <PublishedBadge />}
          </Row>
          <Separator size="xxs" />
        </>
      )}

      <Row alignX="center" guttersY="none">
        <WhiteTheme>
          <H3 size="H6" centered data-testid="card-header">
            <StrongLink noUnderline to={url}>
              {title}
            </StrongLink>
          </H3>
        </WhiteTheme>
      </Row>
      <Paragraph centered size="sm">
        <span style={{ color: colors.gray80 }}>
          <Ellipsis lineClamp={3} noTooltip>
            <SafeRichText as="span" dirtyInput={description} />
          </Ellipsis>
        </span>
      </Paragraph>
    </div>
  )
}
