import { ConferencePromo } from './ConferencePromo'

const SeriesSection = `
  ${ConferencePromo}
  fragment SeriesSection on SeriesSection {
    description
    id
    items {
      ...ConferencePromo
    }
    title
  }
`
export default SeriesSection
