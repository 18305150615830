/* eslint-disable default-param-last */
// FIXME: ^^refactor params
import { conferenceConfig } from '../../../config'
import { videoDurationStringToMs, roundTimestampDown, getNowTimestamp } from '../../helpers'

const WEBINAR = 'WEBINAR'
const COUNTDOWN = 'countdown'
const MINUTE = 'minute'

/**
 * Calculates when the given video should end.
 * @param {Object} currentSession
 * @param {Object} nextSession
 */
export const getSessionEndTime = (currentSession, nextSession) => {
  if (!currentSession) return null

  const { videoDuration } = currentSession.item

  const videoDurationMs = videoDurationStringToMs(videoDuration)
  const durationEndTime = currentSession.date + videoDurationMs

  return Math.min(durationEndTime, nextSession?.date ?? Infinity)
}

/**
 * @param {number} date the start of the next session; the time to countdown to
 */
const getCountdownSession = (date) => ({
  date,
  item: {
    video: { videoProvider: { providerType: COUNTDOWN } },
  },
})

/**
 * when processing, a current session could still be live, even though the start date has passed
 */
const getIsContextualLive = (sessionStartTime, sessionEndTime, now = getNowTimestamp()) => {
  return sessionStartTime <= now && sessionEndTime >= now
}

const filterSessions = (sessions, now = getNowTimestamp(), isInteractive) => {
  return (
    sessions
      .filter(({ item }) => item && item?.video?.videoProvider?.providerType !== WEBINAR && item?.video)
      .sort((a, b) => a.date - b.date)
      // BS saves seconds when a session is created, but we display minutes. This will round down the timestamps to the nearest minute so the sessions always start at 00 seconds
      .map(({ date, ...rest }) => ({
        date: roundTimestampDown({ to: MINUTE, time: date }),
        ...rest,
      }))
      // only show sessions that are live now and between now and
      // the next amount of hours as defined in the config
      .filter((session, index, array) => {
        const { date } = session
        const isContextualLive = getIsContextualLive(date, getSessionEndTime(session, array[index + 1]), now)

        const isWithinTimeFrame = isInteractive ? true : date <= now + conferenceConfig.playerTimeFrame

        return (isContextualLive || date >= now) && isWithinTimeFrame
      })
  )
}

export const interleaveSessions = (sessions, now = getNowTimestamp(), isInteractive) => {
  if (!sessions) return []
  const interleavedSessions = filterSessions(sessions, now, isInteractive).reduce(
    (acc, session, index, originalArray) => {
      const previousSession = originalArray[index - 1]
      const nextSession = originalArray[index + 1]
      const { date } = session
      const previousSessionEndTime = getSessionEndTime(previousSession, session)

      if (
        (previousSessionEndTime === null || previousSessionEndTime < date) &&
        !getIsContextualLive(date, getSessionEndTime(session, nextSession))
      ) {
        acc.push(getCountdownSession(date))
      }
      acc.push(session)

      return acc
    },
    []
  )

  return interleavedSessions
}
