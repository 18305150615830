import React from 'react'
import { useTranslation } from 'react-i18next'

export default function WatchProgressBar({ label, percentageWatched = 0 }) {
  const [t] = useTranslation()

  if (percentageWatched === 0) {
    return null
  }

  return (
    <div
      aria-label={t('watch-history.progress-bar.label', 'Watch Progress for {label}', { label })}
      style={{ width: '100%', height: 4, background: 'grey' }}
      role="progressbar"
      aria-valuenow={percentageWatched}
    >
      <div style={{ width: `${percentageWatched}%`, height: '100%', background: 'red' }} />
    </div>
  )
}
