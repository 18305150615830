import { getPlaylistsForItem, getPlaylistsByItems } from '../../api/rootstech/playlists'
import { chunkArray, deepCopy } from './functionalHelpers'

/** @deprecated */
export const markItemsInPlaylists = async ({ items, signedIn }) => {
  if (!items) return []

  let clonedItems
  if (Array.isArray(items)) {
    clonedItems = deepCopy(items)
  } else if (items.items) {
    clonedItems = deepCopy(items.items)
  } else {
    return []
  }

  if (!signedIn) return clonedItems

  const itemsWithContentId = clonedItems.filter((item) => item.contentId !== null)

  if (itemsWithContentId.length === 0) return clonedItems

  const requests = itemsWithContentId.map((item) => getPlaylistsForItem({ sessionId: item.id, signedIn }))
  const results = await Promise.all(requests).then((responses) =>
    responses.map((response) => response.data.results.length > 0)
  )

  for (let i = 0; i < itemsWithContentId.length; i++) {
    itemsWithContentId[i].isInPlaylist = results[i]
  }

  return clonedItems
}

/** @deprecated */
export const markGroupsOfItemsInPlaylists = async ({ items, signedIn }) => {
  if (!items) return []

  const clonedItems = deepCopy(items)
  return Promise.all(clonedItems.map((series) => markItemsInPlaylists({ items: series, signedIn })))
}

const CHUNK_LIMIT = 25

export const getChunkedPlaylistsByItems = async (sessionIds = []) => {
  const chunkedRequests = chunkArray(sessionIds, CHUNK_LIMIT)
  const requests = chunkedRequests.map((ids) => getPlaylistsByItems({ ids }))
  const responses = await Promise.all(requests)

  // The response is returned as a map of sessionIds to playlists
  const flattenedData = responses
    .map(({ data }) => data)
    // combine all responses into a single object
    .reduce((acc, item) => ({ ...acc, ...item }), {})

  // Filter out any ids that don't have any playlists
  const filteredData = Object.fromEntries(Object.entries(flattenedData).filter(([, { results }]) => results.length > 0))

  return filteredData
}
