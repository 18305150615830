const LABS_PREF = 'fslabs.labs'

const FLAGS_WITH_PREFS = {
  PREVIEW: 'rootstech_preview',
  SITE_EXPERIENCE: 'rootstech_site_experience',
  EXPOHALL: 'rootstech_expohall',
}
const FLAGS_WITHOUT_PREFS = {
  VOLUNTEER: 'rootstech_volunteer',
  VENDOR_LINKING: 'rootstech_vendor_linking',
  DASHBOARD: 'rootstech_dashboard',
  SUBSCRIPTION: 'rootstech_subscription',
  SCHEDULE: 'rootstech_schedule',
  PROMOTIONS: 'rootstech_promotions',
  VENDOR_CHAT: 'rootstech_vendor_chat',
}

export const FLAGS = {
  ...FLAGS_WITH_PREFS,
  ...FLAGS_WITHOUT_PREFS,
}

export const flagsConfig = {
  flagsWithPrefs: [
    { flag: FLAGS.PREVIEW, pref: LABS_PREF },
    { flag: FLAGS.SITE_EXPERIENCE, pref: LABS_PREF },
    { flag: FLAGS.EXPOHALL, pref: LABS_PREF },
  ],
  flagsWithoutPrefs: Object.values(FLAGS_WITHOUT_PREFS),
}
