import { MenuOverlay } from '@fs/zion-ui'
import React from 'react'
import HelpItems from './HelpItems'

const HelpOverlay = ({ ...overlay }) => {
  const onClick = (e) => {
    overlay.handleClick(e)
  }

  return (
    <MenuOverlay {...overlay}>
      <HelpItems onClick={onClick} removeFiller />
    </MenuOverlay>
  )
}

export default HelpOverlay
