import { TextField, InfoSheet, H3, Paragraph, Separator } from '@fs/zion-ui'
import React, { useState } from 'react'
import { useTimeTravel } from '../../../providers'
import { parseISOtoMs, parseMsToISO, roundTimestampDown } from '../../helpers'
import RainbowText from '../../RainbowText'

const DateTimeField = (props) => {
  return (
    <div style={{ colorScheme: 'dark' }}>
      <TextField label="Enter Time:" type="datetime-local" placeholder="Unlock the Temporal Gateway" {...props} />
    </div>
  )
}

export default function TimeTravelInfoSheet({ flavor, ...infoSheet }) {
  const { getNow, setNow } = useTimeTravel()
  const ISONow = parseMsToISO(roundTimestampDown({ time: getNow() }), true)
  const [value, setValue] = useState(ISONow.slice(0, -5))
  const onChange = (e) => {
    setValue(e.target.value)
    setNow(parseISOtoMs(e.target.value))
  }

  return (
    <InfoSheet
      {...infoSheet}
      size="md"
      title={
        <H3 size="H4">
          <RainbowText flavor={flavor}>Back to the future</RainbowText>
        </H3>
      }
    >
      <Paragraph size="sm">Welcome to our website&apos;s interactive time travel feature!.</Paragraph>
      <Paragraph size="sm">
        Enter a specific time and watch as our site magically whisks you away to that moment.
      </Paragraph>
      <Separator />
      <DateTimeField value={value} onChange={onChange} />
    </InfoSheet>
  )
}
