import { useEffect, useState } from 'react'

const hasWindow = typeof window !== 'undefined'
const hasLocalStorage = typeof localStorage !== 'undefined' && localStorage !== null

function getStorageItem(key, defaultValue) {
  const saved = localStorage.getItem(key)
  const initial = JSON.parse(saved)
  return initial || defaultValue
}

export default function useLocalStorage(key, defaultValue) {
  const [value, setValue] = useState(() => {
    return hasLocalStorage && getStorageItem(key, defaultValue)
  })

  useEffect(() => {
    if (!hasWindow || !hasLocalStorage) return undefined
    function handler(e) {
      if (e.storageArea === localStorage && e.key === key) {
        if (e.newValue) {
          setValue(JSON.parse(e.newValue))
        } else {
          setValue(defaultValue)
        }
      }
    }
    window.addEventListener('storage', handler)
    return () => window.removeEventListener('storage', handler)
  })

  useEffect(() => {
    try {
      localStorage.setItem(key, JSON.stringify(value))
    } catch (error) {
      console.error(`Failed to store value for ${key} in localStorage`, error)
    }
  }, [key, value])

  return [value, setValue]
}
