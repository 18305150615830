import React, { Children, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, Separator, Row, Paragraph, colors } from '@fs/zion-ui'
import { useFeatureFlag } from '@fs/zion-flags'
import { NoticeImportant } from '@fs/zion-icon'
import ListPlayer from '../ListPlayer'
import { Badge } from '../../Badge'
import { getIsLive, getNowTimestamp } from '../../helpers'
import { second } from '../../../config'
import PlayerListItem from '../ListPlayer/PlayerListItem'
import useVideoQueue from './hooks'

const useCurrentTime = () => {
  const [time, setTime] = useState(() => new Date().toLocaleTimeString())

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date().toLocaleTimeString())
    }, second)
    return () => clearInterval(timer)
  }, [])

  return time
}
const DebugMessage = () => {
  const time = useCurrentTime()
  return (
    <>
      <Row alignY="middle" guttersX="xxs">
        <NoticeImportant background={colors.red30} />
        <Paragraph growX>Player debugging mode is enabled</Paragraph>
        <Paragraph size="sm">{time}</Paragraph>
        <div />
      </Row>
      <Separator />
    </>
  )
}

export default function ScheduledPlayer({ heading, noSessionText, sessions, loading }) {
  const [playingIndex, setPlayingIndex] = useState(0)
  const [t] = useTranslation()
  const scheduledPlayerQAFlag = useFeatureFlag('rootstech_qa_scheduled_player')
  const isInteractive = scheduledPlayerQAFlag.isOn
  const videoQueue = useVideoQueue({ sessions, loading, isInteractive })
  const currentVideo = videoQueue[playingIndex]

  const isLive = getIsLive({
    date: currentVideo?.date,
    videoDuration: currentVideo?.item?.videoDuration,
  })

  // seek time must be an int in seconds
  // FIXME: handle optional chaining better
  // eslint-disable-next-line no-unsafe-optional-chaining
  const seekStartTime = scheduledPlayerQAFlag.isOn ? 0 : parseInt((getNowTimestamp() - currentVideo?.date) / second, 10)

  return (
    <>
      {isInteractive && <DebugMessage />}
      <ListPlayer
        sessions={videoQueue}
        autoplay
        heading={heading}
        noSessionText={noSessionText || t('calendar.content.none', 'There are no scheduled events today.')}
        loading={loading}
        seekStartTime={seekStartTime}
        countdownStartTime={currentVideo?.date}
        isLive={isLive}
        listItems={Children.toArray(
          videoQueue
            .map((session, index) => {
              const { date, item } = session

              // why not filter these out? because we need to keep the indexes
              // stable if it is interactive
              if (item.video.videoProvider.providerType === 'countdown') {
                return null
              }

              const { videoDuration } = item
              return (
                <>
                  <Divider />
                  <PlayerListItem
                    session={session}
                    playingIdentifier={
                      getIsLive({ date, videoDuration }) && (
                        <>
                          <Separator size="nano" />
                          <Badge flavor="lava" label={t('card.tag.live', 'Live')} />
                        </>
                      )
                    }
                    onClick={isInteractive ? () => setPlayingIndex(index) : null}
                  />
                </>
              )
            })
            // We add in placeholders, we don't want to show those in this list
            .filter(Boolean)
        )}
        playingIndex={playingIndex}
        contentLocale={currentVideo?.item?.video?.contentLocale}
      />
    </>
  )
}
