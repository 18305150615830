import React, { useState, useCallback } from 'react'
import { Separator, Row } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { NoticeLoading } from '@fs/zion-icon'
import { PillButton } from '../buttons'

export default function SeeMoreButton({ onClick }) {
  const [t] = useTranslation()
  const [loading, setLoading] = useState(false)

  const handleClick = useCallback(() => {
    setLoading(true)
    onClick()
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  }, [setLoading, onClick])

  return onClick ? (
    <>
      <Separator size="sm" />
      <Row alignX="center">
        <PillButton
          linkName="See More"
          onClick={handleClick}
          emphasis="medium"
          Icon={loading ? NoticeLoading : undefined}
          disabled={loading}
        >
          {t('search.see-more.label', 'See More')}
        </PillButton>
      </Row>
    </>
  ) : null
}
