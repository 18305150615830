import React, { useState, useRef, useEffect } from 'react'
import { css } from '@emotion/core'
import { IconButton, colors } from '@fs/zion-ui'
import { MenuSearch, MenuClose } from '@fs/zion-icon'
import { useTranslation } from 'react-i18next'
import { boolAttr } from '@fs/zion-frontend-friends'
import { trackLink } from '@fs/zion-analytics'
import { useIsMdWidth } from '../../../../hooks'

const containerCss = css`
  position: relative;
  width: 100%;
`
const searchBarCss = css`
  background-color: ${colors.gray02};
  width: 100%;
  padding-top: 11px;
  padding-inline-end: 56px;
  padding-bottom: 12px;
  padding-inline-start: 65px;
  color: ${colors.gray90};
  font-size: 24px;
  border-radius: 2em;
  border: 0;

  &::placeholder {
    color: ${colors.gray80};
  }

  &[is-md-width] {
    font-size: 14px;
    padding-top: 10px;
    padding-inline-end: 40px;
    padding-bottom: 11px;
    padding-inline-start: 15px;
    padding-top: 10px;
    padding-bottom: 11px;
  }
`
const searchButtonCss = css`
  position: absolute;
  inset-inline-start: 20px;
  top: 8px;

  & button {
    --button-background: ${colors.gray05};
    --button-hover-background: ${colors.transparent.gray10};
  }
`
const clearButtonCss = css`
  position: absolute;
  inset-inline-end: 20px;
  top: 8px;

  &[is-md-width] {
    inset-inline-end: 12px;
    top: 0;
  }
`

export default function BaseSearchBar({
  onSubmit,
  placeholder,
  forceMobile,
  onChange,
  submitDisabled,
  onClear,
  clearOnSubmit = false,
  syncValue,
}) {
  const { t } = useTranslation()
  const isMdWidth = useIsMdWidth(forceMobile ? '' : undefined)
  const [internalValue, setInternalValue] = useState('')
  const inputRef = useRef()

  // This allows the value to update when the parent wants, but each value change is
  // still controlled here
  useEffect(() => {
    syncValue && setInternalValue(syncValue)
  }, [syncValue])

  const handleSubmit = (e) => {
    const searchValue = e.target.value?.trim()
    if (searchValue) {
      onSubmit?.(searchValue)
      trackLink(e)
      if (clearOnSubmit) {
        setInternalValue('')
      }
    }
  }

  const handleClear = () => {
    setInternalValue('')
    onClear?.()
  }

  const handleEnter = (e) => {
    const { key } = e
    if (key === 'Enter') {
      handleSubmit(e)
    }
  }

  const onInputChange = ({ target }) => {
    setInternalValue(target.value)
    onChange?.(target.value)
  }

  return (
    <div css={containerCss}>
      <input
        aria-label={t('search.search-bar.input.label', 'Search Text')}
        maxLength="255"
        type="text"
        value={internalValue}
        onChange={onInputChange}
        css={searchBarCss}
        onKeyUp={handleEnter}
        placeholder={placeholder}
        data-testid="search-bar"
        is-md-width={boolAttr(isMdWidth)}
        onClick={trackLink}
        data-link-name={`Search: ${internalValue}`}
        ref={inputRef}
      />

      {!isMdWidth && (
        <div css={searchButtonCss}>
          <IconButton
            Icon={MenuSearch}
            color="gray"
            size="md"
            title={t('common-ui:search.action', 'Search')}
            onClick={() => handleSubmit({ target: inputRef.current })}
            disabled={submitDisabled}
            data-testid="search-button"
            linkName={`Search: ${internalValue}`}
          />
        </div>
      )}
      {internalValue && (
        <div css={clearButtonCss} is-md-width={boolAttr(isMdWidth)}>
          <IconButton
            Icon={MenuClose}
            color="gray"
            size="md"
            title={t('search.search-bar.clear.action', 'Clear')}
            onClick={handleClear}
            linkName="Clear"
          />
        </div>
      )}
    </div>
  )
}
