import React, { useRef, useState, useEffect, Children } from 'react'
import { Bleed, Separator } from '@fs/zion-ui'
import { css } from '@emotion/core'
import { deepCopy, getCustomImage } from '../../helpers'
import { useOneOffPageData } from '../../../hooks'
import { backgroundSponsorCarouselJpg } from '../../../images'
import { SectionCard } from '../../section-card'

const timeForOneItem = 6 // seconds it takes to go from 100% on screen to 100% off screen

const imageCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--item-width);
`
const containerCss = css`
  --item-width: 175px;
  --gap: 50px;
  display: flex;
  gap: var(--gap);
  width: max-content;
  height: 160px;
  animation: translateLeft ${timeForOneItem}s linear infinite;

  @keyframes translateLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-1 * calc(var(--item-width) + var(--gap))));
    }
  }
`

const InfiniteRotatingCarousel = ({ data }) => {
  const [items, setItems] = useState([])
  const containerRef = useRef()

  useEffect(() => {
    setItems(deepCopy(data))
  }, [data])

  const rotateItems = (e) => {
    // There are multiple animations going on that bubble, this will isolate it to just the animation defined in this component
    if (e.target !== containerRef.current || !items.length) {
      return
    }

    setItems((_items) => {
      const firstElement = _items[0]
      const rotatedItems = [..._items.slice(1), firstElement]
      return rotatedItems
    })
  }

  return (
    <Bleed sides style={{ overflow: 'hidden' }}>
      <div ref={containerRef} css={containerCss} onAnimationIteration={rotateItems}>
        {Children.toArray(
          items.map((item) => (
            <div css={imageCss}>
              {/* Zion's Image caused too much flickering */}
              <img style={{ width: '100%' }} alt="" src={getCustomImage(item)} />
            </div>
          ))
        )}
      </div>
    </Bleed>
  )
}

export default function SponsorCarouselBanner({ heading, description, actions }) {
  const { data } = useOneOffPageData({ path: '/rotating-sponsor-banner' })
  const content = data?.content

  return (
    <SectionCard
      flavor="dusk"
      image={backgroundSponsorCarouselJpg}
      heading={heading}
      description={description}
      actions={actions}
      size="sm"
    >
      <Separator size="sm" />
      <InfiniteRotatingCarousel data={content?.[0]?.items || []} />
    </SectionCard>
  )
}
