import { useEffect, useCallback, useState } from 'react'
import { second, watchHistoryConfig } from '../../../../../config'
import { parseMsToISO } from '../../../../helpers'
import { useWatchHistoryContext } from '../../../../../providers/WatchHistoryProvider'

// different from the shared video duration method because this has the player object
const getVideoDuration = (player, videoProviderType) => {
  if (['youtube', 'brightcove'].includes(videoProviderType)) {
    return parseInt(player.getDuration(), 10)
  }
  return -Infinity
}

export default function useWatchEvent({ videoProviderType, sessionId, startTime }) {
  const { updateEntry } = useWatchHistoryContext()
  const [videoPlayer, setVideoPlayer] = useState()

  const trackHistoryEvent = useCallback(
    (event, isCompleted) => {
      if (!sessionId) return
      const player = event.target || event

      let seekTime = 0
      if (['youtube', 'brightcove'].includes(videoProviderType)) {
        seekTime = parseInt(player.getCurrentTime(), 10)
      }
      const duration = getVideoDuration(player, videoProviderType)
      // if seek time longer than a reasonable length of a session
      if (startTime && seekTime > 7200) {
        seekTime -= startTime
      }
      const isProbablyCompleted = (seekTime / duration) * 100 >= watchHistoryConfig.COMPLETED_THRESHOLD

      seekTime > 0 &&
        updateEntry({
          sessionId,
          completed: isCompleted || isProbablyCompleted,
          position: parseMsToISO(seekTime * second),
        })
    },
    [sessionId, startTime, updateEntry, videoProviderType]
  )

  const onUnload = useCallback(() => {
    if (
      // the player never loaded, so no need to pause it
      !videoPlayer ||
      // brightcove has `hasEnded`
      videoPlayer.hasEnded?.() ||
      // youtube keeps a player state, 0 === ended
      videoPlayer.getPlayerState?.() === 0
    ) {
      return
    }
    trackHistoryEvent(videoPlayer)
  }, [trackHistoryEvent, videoPlayer])

  // When we leave the app
  useEffect(() => {
    window.addEventListener('beforeunload', onUnload)
    return () => {
      window.removeEventListener('beforeunload', onUnload)
    }
  }, [onUnload])

  // when we stay in the app, but leave the page
  useEffect(() => {
    return () => {
      onUnload()
    }
  }, [onUnload])

  return { trackHistoryEvent, setVideoPlayer }
}
