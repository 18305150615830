import { useChatOverlay, buildChatRoute, CHAT_ROUTE_KEY } from '@fs/zion-chat'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { useUser } from '@fs/zion-user'
import zionDebug from '@fs/zion-debug'
import { useIsMdWidth } from '../../hooks'
import PillButton from './PillButton'
import { useLoggedOutOverlay } from '../../providers'

const debug = zionDebug('rootstech:auachatbutton')

const chatRoute = buildChatRoute(CHAT_ROUTE_KEY.CREATE_SUPPORT_CHAT)

export default function AUAChatButton() {
  const [t] = useTranslation()
  const user = useUser()
  const chatOverlay = useChatOverlay(chatRoute)
  const { overlay: loggedOutOverlay } = useLoggedOutOverlay({ showChat: chatRoute, tracking: 'Chat' })
  const isMdWidth = useIsMdWidth()

  let clickHandler
  if (user.signedIn) {
    debug('using chat overlay click handler.', chatOverlay)
    clickHandler = chatOverlay?.handleClick
  } else {
    debug('using logged out overlay click handler', loggedOutOverlay)
    clickHandler = loggedOutOverlay?.handleClick
  }

  return chatOverlay ? (
    <PillButton onClick={clickHandler} emphasis={isMdWidth ? 'high' : 'medium'} linkName="Chat">
      {t('help.overlay.chat.primary', 'Chat')}
    </PillButton>
  ) : (
    // FIXME: This is a temporary fix to prevent layout shift
    <div />
  )
}
