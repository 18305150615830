import React from 'react'
import { ActionChip } from '@fs/zion-ui'

// FROM: https://office365lds-my.sharepoint.com/:x:/r/personal/loosledc_churchofjesuschrist_org/_layouts/15/Doc.aspx?sourcedoc=%7B797F5B62-9F6F-4C67-BE04-83A4BC7635C7%7D&file=Hidden%20Tags.xlsx&wdLOR=c606A75F6-B1A2-ED48-A571-2CE572E8EE98&action=default&mobileredirect=true
// I force lowercase compare because I don't trust the content managers to not change case on me
const blackList = [
  'fsdemo',
  'rootstech connect 2021',
  'youtubelive',
  'mainstage',
  'main stage not approved',
  'main stage approved',
  'main stage',
  'music in video',
  'booth demo',
  'dna booth',
  'advanced presentation',
  'series',
  'testimony',
  'miracles',
]

export default function TopicTag({ tag }) {
  if (blackList.includes(tag.toLowerCase())) return null
  return <ActionChip label={tag} to={`/search/results?text=${encodeURIComponent(tag)}`} noLinkStyles />
}
