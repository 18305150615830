import React from 'react'
import { css } from '@emotion/core'
import { LogoRootstech } from '@fs/zion-icon'
import { useTranslation } from 'react-i18next'
import { H3, Separator, Subheading } from '@fs/zion-ui'
import { boolAttr } from '@fs/zion-frontend-friends'
import { stageSeafoamJpg } from '../../images'
import useIsMdWidth from '../../hooks/useIsMdWidth'

const backgroundCss = css`
  background-image: url(${stageSeafoamJpg});
  filter: grayscale(1);
  aspect-ratio: 16 / 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;

  &[is-md-width] {
    padding: 20px 0;
  }
`
const logoCss = css`
  --icon-size: 9em;
  svg {
    height: var(--icon-size);
    width: var(--icon-size);
  }

  &[is-md-width] {
    --icon-size: 3em;
  }
`
const ErrorPlaceholder = () => {
  const [t] = useTranslation()
  const isMdWidth = useIsMdWidth()

  return (
    <div css={backgroundCss} is-md-width={boolAttr(isMdWidth)}>
      <div css={logoCss} is-md-width={boolAttr(isMdWidth)}>
        <LogoRootstech />
      </div>
      {!isMdWidth && <Separator size="xl" />}
      <H3 centered>
        {t('video.error.heading', 'It looks like something went wrong')}
        <Separator size="xs" />
        <Subheading>
          {t('video.error.subHeading', 'Wait a few minutes and try refreshing your browser to play the video')}
        </Subheading>
      </H3>
    </div>
  )
}

export default ErrorPlaceholder
