import PropTypes from 'prop-types'
import React from 'react'
import { css } from '@emotion/core'
import { LayoutBand } from '@fs/zion-ui'
import { flavors, scrimGradient } from '../config'

const gradientCss = css`
  background-position: right;
  background-size: 50%;

  background: var(--scrim), var(--gradient), var(--background-image);
`

export default function RainbowImageBand({
  flavor = flavors.default,
  noScrim,
  children,
  maxWidth = 'lg',
  backgroundImage = '',
  ...props
}) {
  let bgImage = `url(${backgroundImage})`
  if (Array.isArray(backgroundImage)) bgImage = backgroundImage.map((img) => `url(${img})`).join(', ')

  return (
    <LayoutBand
      maxWidth={maxWidth}
      css={gradientCss}
      style={{
        '--scrim': noScrim ? flavors.flavorless.filmedGradient : scrimGradient,
        '--gradient': flavors[flavor].gradient,
        '--background-image': flavor ? bgImage : 'unset',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
      {...props}
    >
      {children}
    </LayoutBand>
  )
}

RainbowImageBand.propTypes = {
  children: PropTypes.node,
  flavor: PropTypes.string,
  maxWidth: PropTypes.string,
  noScrim: PropTypes.bool,
}
