import { ContentAdd } from '@fs/zion-icon'
import React from 'react'
import { useTranslation } from 'react-i18next'
import PillButton from '../../../lib/buttons/PillButton'

export default function CreatePlaylistButtonV2({ onClick, disabled }) {
  const [t] = useTranslation()

  return (
    <PillButton
      Icon={ContentAdd}
      color="blue"
      disabled={disabled}
      emphasis="low"
      onClick={onClick}
      linkName="Create Playlist"
    >
      {t('playlists.collection.create.action', 'Create Playlist')}
    </PillButton>
  )
}
