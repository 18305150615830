const ImageAttributes = `
  fragment ImageAttributes on ImageAttributes {
    filename
    height
    publicUrl
    src
    width
  }
`
export default ImageAttributes
// NOTE: I don't think we need publicUrl as that one is never sized.
