import { useEffect, useState } from 'react'
import { getSessionEndTime, interleaveSessions } from '../../helpers'
import { getNowTimestamp } from '../../../../helpers'

/**
 * When the end time is hit, remove the first item in the array
 */
const useVideoQueue = ({ sessions, loading, isInteractive }) => {
  const [videoQueue, setVideoQueue] = useState([])

  // format the sessions when we have them
  useEffect(() => {
    if (!loading) setVideoQueue(interleaveSessions(sessions, getNowTimestamp(), isInteractive))
  }, [loading, sessions, isInteractive])

  // change the sessions when the last one ends
  useEffect(() => {
    if (loading || isInteractive) return () => {}

    const sessionEndTime = getSessionEndTime(videoQueue[0], videoQueue[1])
    const timeDifference = sessionEndTime - getNowTimestamp()

    const timeout = setTimeout(() => {
      setVideoQueue((s) => s.slice(1))
    }, timeDifference)

    // There is no next session
    if (timeDifference <= 0) {
      clearTimeout(timeout)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [videoQueue, loading, isInteractive])

  return videoQueue
}

export default useVideoQueue
