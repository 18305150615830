import { ExpoThumbnailImage, LeadThumbnailImage, ThumbnailImage } from './ImageDetail'
import ContentVisibility from './ContentVisibility'
import YoutubeVideoProvider from './YoutubeVideoProvider'
import RootsTechBrightcoveVideoProvider from './RootsTechBrightcoveVideoProvider'
import WebinarVideoProvider from './WebinarVideoProvider'

export const VideoDetail = `
  ${ThumbnailImage}
  ${ContentVisibility}
  ${WebinarVideoProvider}
  ${RootsTechBrightcoveVideoProvider}
  ${YoutubeVideoProvider}
  fragment VideoDetail on VideoDetail {
    contentLocale
    headline
    id
    subHeadline
    thumbnail {
      ...ThumbnailImage
    }
    url
    videoDuration
    videoProvider {
      ...YoutubeVideoProvider
      ...RootsTechBrightcoveVideoProvider
      ...WebinarVideoProvider
    }
    visibility {
      ...ContentVisibility
    }
  }
`
export const LeadVideoDetail = `
  ${LeadThumbnailImage}
  ${ContentVisibility}
  ${WebinarVideoProvider}
  ${RootsTechBrightcoveVideoProvider}
  ${YoutubeVideoProvider}
  fragment LeadVideoDetail on VideoDetail {
    contentLocale
    headline
    id
    subHeadline
    thumbnail {
      ...LeadThumbnailImage
    }
    url
    videoDuration
    videoProvider {
      ...YoutubeVideoProvider
      ...RootsTechBrightcoveVideoProvider
      ...WebinarVideoProvider
    }
    visibility {
      ...ContentVisibility
    }
  }
`

export const ExpoVideoDetail = `
  ${ExpoThumbnailImage}
  ${ContentVisibility}
  ${WebinarVideoProvider}
  ${RootsTechBrightcoveVideoProvider}
  ${YoutubeVideoProvider}
  fragment ExpoVideoDetail on VideoDetail {
    contentLocale
    headline
    id
    subHeadline
    thumbnail {
      ...ExpoThumbnailImage
    }
    url
    videoDuration
    videoProvider {
      ...YoutubeVideoProvider
      ...RootsTechBrightcoveVideoProvider
      ...WebinarVideoProvider
    }
    visibility {
      ...ContentVisibility
    }
  }
`
