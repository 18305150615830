import React, { Children } from 'react'
import { FlowGrid, H2, Separator, useContainerWidth } from '@fs/zion-ui'
import IconCard from './IconCard'

const flavors = ['seafoam', 'mango', 'blueberry', 'fire', 'apple', 'dusk']

export default function IconCardGrid({ data }) {
  const atWidth = useContainerWidth()

  return data ? (
    <>
      <H2 supplementary centered>
        {data.title}
      </H2>
      <Separator />
      <FlowGrid columnCount={atWidth({ default: 1, md: 2, xl: 3 })} lastRowAlignX="center">
        {Children.toArray(
          data.items.map((item, i, array) => <IconCard data={item} flavor={flavors[i % array.length]} />)
        )}
      </FlowGrid>
    </>
  ) : null
}
