import React, { useState } from 'react'
import { ListItem, MenuOverlay, ExpandableListItem } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { LogoRootstech } from '@fs/zion-icon'
import { useEvent } from '@fs/zion-frontend-friends'
import { RootsTechEventItems } from './RootstechEventItems'

export const RootstechEventExpandableList = ({ onClick, dense = false }) => {
  const [t] = useTranslation()
  const [open, setOpen] = useState(false)
  const handleClick = useEvent(() => {
    setOpen(false)
    onClick()
  })

  return (
    <ExpandableListItem
      primaryText={t('navigation.rootstech-event.label', 'RootsTech Event')}
      startElement={<ListItem.Icon Icon={LogoRootstech} />}
      data-testid="menu-rootstech-event"
      dense={dense}
      open={open}
      setOpen={setOpen}
      linkName={`Rootstech Event: ${open ? 'open' : 'close'}`}
      selected={open}
    >
      <RootsTechEventItems onClick={handleClick} dense={dense} />
    </ExpandableListItem>
  )
}

export const RootstechEventMenuOverlay = ({ dense, removeFiller, ...overlay }) => {
  return (
    <MenuOverlay {...overlay}>
      <RootsTechEventItems onClick={overlay.close} dense={dense} removeFiller={removeFiller} />
    </MenuOverlay>
  )
}
