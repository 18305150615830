import React from 'react'
import { Card, Row, Separator, H3, Paragraph } from '@fs/zion-ui'
import { PillButton } from './buttons'
import { getCustomImage } from './helpers'
import ResponsiveSeparator from './ResponsiveSeparator'
import SafeRichText from './SafeRichText'

export default function BackgroundImagePromo({ promo, actions }) {
  return (
    <Card backgroundImage={getCustomImage(promo)}>
      <ResponsiveSeparator />
      <H3 supplementary centered>
        {promo.title}
      </H3>
      <Paragraph centered>
        <SafeRichText as="span" dirtyInput={promo.description} />
      </Paragraph>
      <Separator size="md" />
      <Row alignX="center">
        {promo.ctaText ? (
          <PillButton to={promo?.url} emphasis="medium">
            {promo.ctaText}
          </PillButton>
        ) : null}
      </Row>
      {actions && !promo.ctaText && <div>{actions}</div>}
      <ResponsiveSeparator />
    </Card>
  )
}
