const Agreement = `
  fragment Agreement on Agreement {
    creator {
      id
    }
    date
    id
  }
`
export default Agreement
