import React from 'react'
import { FormTextField } from '@fs/zion-form'
import { useTranslation } from 'react-i18next'

export default function PlaylistTitleTextField() {
  const [t] = useTranslation()
  return (
    <FormTextField
      label={t('playlists.form.title.label', 'Playlist Title')}
      name="name"
      minLength={2}
      maxLength={256}
    />
  )
}
