import { VideoDetail } from './VideoDetail'

const VideoModule = `
  ${VideoDetail}
  fragment VideoModule on VideoModule {
    description
    title
    video {
      ...VideoDetail
    }
  }
`

export default VideoModule
