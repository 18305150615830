import React from 'react'
import { boolAttr } from '@fs/zion-frontend-friends'
import { Paragraph, Row, Separator } from '@fs/zion-ui'
import PropTypes from 'prop-types'
import { useIsMdWidth } from '../../hooks'
import SafeRichText from '../SafeRichText'

export default function SectionDescription({
  children,
  fullWidth,
  bold,
  noMargin,
  as: Tag = Paragraph,
  centered,
  dataTestId,
  isClean,
}) {
  const isMdWidth = useIsMdWidth()
  return (
    <>
      <Row alignX={centered ? 'center' : 'start'}>
        {isClean ? (
          <Tag centered={centered}>{children}</Tag>
        ) : (
          <SafeRichText
            dirtyInput={children}
            as={Tag}
            centered={centered}
            data-testid={dataTestId}
            center-text={boolAttr(centered)}
            full-width={boolAttr(fullWidth)}
            no-margin={boolAttr(noMargin)}
            is-md-width={boolAttr(isMdWidth)}
            bold={boolAttr(bold)}
          />
        )}
      </Row>
      <Separator size="sm" />
    </>
  )
}

SectionDescription.propTypes = {
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
  bold: PropTypes.bool,
}
