import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormCheckbox } from '@fs/zion-form'
import { Separator, Row } from '@fs/zion-ui'
import LegalText from '../../components/footer/LegalText'

export default function AgreePrivacyTermsField() {
  const [t] = useTranslation()
  return (
    <>
      <FormCheckbox
        name="privacyTerms"
        label={t(
          'conference-announce.subscribe.privacy.terms',
          'I agree to the FamilySearch Terms of Use and have read the Privacy Notice.'
        )}
        required
      />
      <Separator size="nano" />
      <Row>
        <div />
        <LegalText centered={false} hideExtraText />
      </Row>
    </>
  )
}
