import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FormSelect, useZionFormContext } from '@fs/zion-form'

export const NAME = 'guests'

export function GuestsField({ disabled, value }) {
  const [t] = useTranslation()
  const { setValue } = useZionFormContext()

  const guestsNumber = Array.from({ length: 10 }, (_, i) => ({
    value: i + 1,
    label: `${i + 1}`,
  }))

  useEffect(() => {
    if (value) setValue(NAME, value)
  }, [setValue, value])

  return (
    <FormSelect
      name={NAME}
      label={`${t('form.field.guests.label', 'Number of Guests')}: *`}
      options={guestsNumber}
      disabled={disabled}
    />
  )
}
