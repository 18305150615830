import { useRef, useEffect } from 'react'

/**
 * A hook that tracks whether the component is currently mounted.
 * @returns {React.MutableRefObject<boolean>} A ref object with a `current` property that is `true` if the component is mounted, and `false` otherwise.
 */
const useCurrent = () => {
  const isCurrent = useRef(true)

  useEffect(() => {
    isCurrent.current = true
    return () => {
      isCurrent.current = false
    }
  })

  return isCurrent
}

export default useCurrent
