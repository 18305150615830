import React, { Children } from 'react'
import { Ghost, List, Separator } from '@fs/zion-ui'
import { SessionCard } from '../card'

export default function CardList({ Component = SessionCard, as = 'li', items, linkPage, ...props }) {
  if (!items) return null

  return (
    <List>
      {Children.toArray(
        items.map((item) => {
          return (
            <Ghost heightEstimate={300}>
              <Component as={as} data={item} linkPage={linkPage} {...props} />
              <Separator />
            </Ghost>
          )
        })
      )}
    </List>
  )
}
