export default {
  /* Main 10 */
  en: 'https://www.comeuntochrist.org/' /* English */,
  de: 'https://www.comeuntochrist.org/deu?lang=deu' /* German | Deutsch (not dutch!) */,
  es: 'https://www.veniracristo.org/?lang=spa' /* Spanish | Espanol */,
  fr: 'https://www.venezauchrist.org/?lang=fra' /* French | Francais */,
  it: 'https://www.comeuntochrist.org/ita?lang=ita' /* Italian | italiano */,
  pt: 'https://www.vindeacristo.org/?lang=por' /* Portuguese | Português */,
  ru: 'https://www.comeuntochrist.org/rus?lang=rus' /* Russian | Русский */,
  ko: 'https://www.comeuntochrist.org/kor?locale=kor' /* Korean |  한국말 */,
  ja: 'https://www.comeuntochrist.org/jpn?lang=jpn' /* Japanese | 日本語 */,
  zh: 'https://www.comeuntochrist.org/zho?lang=zho' /* Chinese | 中文 */,
  /* Other Languages */
  da: 'https://www.comeuntochrist.org/dan?lang=dan' /* Danish | Dansk */,
  el: 'https://www.comeuntochrist.org/ell?lang=ell' /* Greek | Ελληνικά */,
  fi: 'https://www.comeuntochrist.org/fin?lang=fin' /* Finnish | Suomi */,
  hr: 'https://www.comeuntochrist.org/hrv?lang=hrv' /* Croatian | Hrvatski jezik */,
  hu: 'https://www.comeuntochrist.org/hun?lang=hun' /* Hungarian | Magyar */,
  cs: 'https://www.comeuntochrist.org/ces?lang=ces' /* Czech | Česky */,
  hy: 'https://www.comeuntochrist.org/hye?lang=hye' /* Armenian | հայերեն */,
  id: 'https://www.churchofjesuschrist.org/study?lang=ind' /* Indonesian | Indonesia */,
  lv: 'https://www.comeuntochrist.org/lav?lang=lav' /* Latvian | Latvijas */,
  nl: 'https://www.comeuntochrist.org/nld?lang=nld' /* Dutch | Nederlands */,
  no: 'https://www.comeuntochrist.org/nor?lang=nor' /* Norwegian | Norsk */,
  pl: 'https://www.comeuntochrist.org/pol?lang=pol' /* Polish | Polski */,
  ro: 'https://www.comeuntochrist.org/ron?lang=ron' /* Romanian | Română */,
  sq: 'https://www.comeuntochrist.org/alb?lang=alb' /* Albanian | Shqip */,
  sv: 'https://www.comeuntochrist.org/swe?lang=swe' /* Swedish | Svenska */,
  th: 'https://www.comeuntochrist.org/tha?lang=tha' /* Thai | ไทย */,
  uk: 'https://www.comeuntochrist.org/ukr?lang=ukr' /* Ukrainian | українська */,
  /* Additional Languages to be added in 2020 */
  sk: 'https://www.xn--prdtekukristovi-5ob.org/?lang=slk' /* Slovak | slovenčina */,
  km: 'https://www.comeuntochrist.org/kh?lang=khm' /* Cambodian (Khmer) | ខ្មែរ (khmae) */,
  mn: 'https://www.christ.mn/?lang=mon' /* Mongolian | ᠮᠣᠩᠭᠣᠯ ᠬᠡᠯᠡ монгол */,
  /* Languages to be added in 2020 without Come Unto Christ page */
  vi: 'https://www.churchofjesuschrist.org/study?lang=vie' /* Vietnamese | Tiếng Việt (㗂越) */,
  bg: 'https://www.churchofjesuschrist.org/study?lang=bul' /* Bulgarian | Български */,
  sm: 'https://www.churchofjesuschrist.org/study?lang=smo' /* Samoan | Gagana fa‘a Sāmoa */,
  to: 'https://www.churchofjesuschrist.org/study?lang=ton' /* Tongan | Faka-Tonga */,
  fj: 'https://www.churchofjesuschrist.org/study?lang=fij' /* Fijian | Na vosa vaka-Viti */,
}
