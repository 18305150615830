import React from 'react'
import { css } from '@emotion/core'
import { SocialMenu as ZionSocialMenu } from '@fs/zion-social'
import { colors } from '@fs/zion-ui'

const socialButtonHackCss = css`
  & button {
    --button-text-color: white;
    --button-hover-background: ${colors.transparent.gray05};
    --button-active-background: ${colors.transparent.gray10};
    border-radius: 2em;
    padding-inline: 16px;
    padding-block: 16px;
    height: auto;
    font-weight: 600;
    text-transform: none;

    &[iconsize] {
      height: var(--icon-button-size);
    }
  }
`

export default function SocialMenu(props) {
  return (
    <div css={socialButtonHackCss}>
      <ZionSocialMenu {...props} />
    </div>
  )
}
