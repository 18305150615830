import { conferenceConfig } from '../../config'
import { getNowTimestamp } from './dateTimeHelpers'

export default function getSessionAvailability({
  isSeries,
  startTimestamp,
  conferenceYear: conferenceYearStr,
  now = getNowTimestamp(),
}) {
  const conferenceYear = conferenceYearStr ? parseInt(conferenceYearStr, 10) : null
  let canShowVideo = false
  let actualStartTimestamp = startTimestamp ?? null
  const conferenceStartYear = new Date(conferenceConfig.startTimestamp).getFullYear()

  if (!startTimestamp) {
    // No session start time passed from BS
    if (now < conferenceConfig.startTimestamp && conferenceYear >= conferenceStartYear) {
      // If current date is before event start time AND sessionConferenceYear
      // is greater or equal to conferenceStartYear, put up the countdown
      actualStartTimestamp = conferenceConfig.startTimestamp
    }
  }

  if (!isSeries) {
    if (now >= actualStartTimestamp) {
      canShowVideo = true
    }
  } else {
    canShowVideo = true // There is no need to show countdowns on series overview videos
  }

  return { canShowVideo, actualStartTimestamp }
}
