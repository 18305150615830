import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useChatOverlay, CHAT_ROUTE_KEY, buildChatRoute } from '@fs/zion-chat'
import { SocialChat } from '@fs/zion-icon'
import { removeHtml } from '@fs/zion-frontend-friends'
import { useUser } from '@fs/zion-user'
import ChewableButton from './ChewableButton'
import { useLoggedOutOverlay } from '../../providers'

const chatRoute = buildChatRoute(CHAT_ROUTE_KEY.CREATE_SESSION_CHAT)

const sanitize = (str) => {
  if (str) {
    return str?.replace(/[{}[\]<>:@®]/g, '')
  }

  return str
}

const truncateStr = (str, strLength = 256) => {
  if (str?.length > strLength) {
    return `${str?.substring(0, strLength)}...`
  }

  return str
}

export default function SessionChatButton({ name, description, asIconButton, size = 'sm' }) {
  const [t] = useTranslation()
  const user = useUser()
  const shortName = truncateStr(sanitize(name), 96)
  const linkName = 'Session: Chat'
  const { overlay: loginOverlay } = useLoggedOutOverlay({
    showChat: chatRoute,
    chatName: shortName,
    tracking: linkName,
  })
  const supportChatInformation = { name: shortName, description: truncateStr(removeHtml(description)) }
  const chatOverlay = useChatOverlay(chatRoute, supportChatInformation)
  const needsToLogin = !user.signedIn && !user.userLoading
  return name?.length > 0 ? (
    <ChewableButton
      Icon={SocialChat}
      onClick={needsToLogin ? loginOverlay?.handleClick : chatOverlay?.handleClick}
      emphasis="low"
      linkName={linkName}
      size={size}
      asIconButton={asIconButton}
    >
      {t('chat.button', 'Chat')}
    </ChewableButton>
  ) : (
    // FIXME: can this be null instead? Not sure if there needs to be an element for styling or not
    <div />
  )
}

SessionChatButton.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
}
