import { ConferencePromo, MinimumCreatorDetail } from './ConferencePromo'
import CategoryDataReference from './CategoryDataReference'
import AttachmentDetail from './AttachmentDetail'
import { MinimumExhibitorDetail } from './ExhibitorDetail'
import SemaphoreTagDetail from './SemaphoreTagDetail'
import ContentVisibility from './ContentVisibility'
import { LeadVideoDetail } from './VideoDetail'
import SeriesSection from './SeriesSection'
import { LeadThumbnailImage } from './ImageDetail'
import ObjectTypeDetail from './ObjectTypeDetail'

export const MinimumSeriesDetail = `
  ${MinimumCreatorDetail}
  ${MinimumExhibitorDetail}
  ${ObjectTypeDetail}
  ${ConferencePromo}
  fragment MinimumSeriesDetail on SeriesDetail {
    contentLocale
    creators {
      ...MinimumCreatorDetail
    }
    description
    exhibitors {
      ...MinimumExhibitorDetail
    }
    id
    promo {
      ...ConferencePromo
      contentType {
        ...ObjectTypeDetail
      }
    }
    title
    url
  }
`

export const SeriesDetail = `
  ${ConferencePromo}
  ${CategoryDataReference}
  ${MinimumCreatorDetail}
  ${AttachmentDetail}
  ${MinimumExhibitorDetail}
  ${LeadVideoDetail}
  ${SeriesSection}
  ${SemaphoreTagDetail}
  ${LeadThumbnailImage}
  ${ContentVisibility}
  fragment SeriesDetail on SeriesDetail {
    additionalExperiences {
      ...ConferencePromo
    }
    alternateLocales
    categories {
      ...CategoryDataReference
    }
    contentLocale
    creators {
      ...MinimumCreatorDetail
    }
    description
    downloadableResources {
      ...AttachmentDetail
    }
    exhibitors {
      ...MinimumExhibitorDetail
    }
    hideSuggestedContent
    id
    overviewVideo {
      ...LeadVideoDetail
    }
    promo {
      ...ConferencePromo
    }
    sections {
      ...SeriesSection
    }
    semaphoreTags {
      ...SemaphoreTagDetail
    }
    suggestedContent {
      ...ConferencePromo
    }
    title
    url
    videoThumbnailImage {
      ...LeadThumbnailImage
    }
    visibility {
      ...ContentVisibility
    }
  }
`
