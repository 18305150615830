// list items in the nav that you want based on their test id
const defaultWatchVideosItems = [
  'menu-watchVideo-search',
  'menu-watchVideos-my-playlist',
  'menu-watchVideos-watch-history',
]
const navItems = {
  registration: {
    default: ['eventMenu-overview', ...defaultWatchVideosItems],
  },
  countdown: {
    default: ['eventMenu-overview', 'eventMenu-calendar', 'eventMenu-relatives', ...defaultWatchVideosItems],
  },
  conference: {
    default: [
      'eventMenu-overview',
      'eventMenu-calendar',
      'eventMenu-relatives',
      // TODO: add family discovery day when available
      ...defaultWatchVideosItems,
    ],
  },
  afterConference: {
    default: ['eventMenu-overview', 'eventMenu-calendar', 'eventMenu-relatives', ...defaultWatchVideosItems],
  },
  announce: {
    default: ['eventMenu-overview', 'eventMenu-calendar', 'eventMenu-relatives', ...defaultWatchVideosItems],
  },
}

export default navItems
