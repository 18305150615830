import React from 'react'
import { css } from '@emotion/core'
import { MarginBox, colors } from '@fs/zion-ui'
import { flavors } from '../../config'

const buttonCss = css`
  position: fixed;
  bottom: 140px;
  color: ${colors.gray100};
  border: none;
  border-radius: 0 5px 5px 0;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  z-index: 2;

  &:hover {
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.3);
  }
`

export default function FixedButton({ ariaLabel, onClick, Icon, flavor }) {
  return (
    <button
      aria-label={ariaLabel}
      type="button"
      onClick={onClick}
      css={buttonCss}
      style={{ background: flavors[flavor].gradient }}
    >
      <MarginBox marginY="nano">
        <Icon role="none" />
      </MarginBox>
    </button>
  )
}
