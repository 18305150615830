import { i18n } from '@fs/zion-locale'
import { serverConfig, hour, minute, second, watchHistoryConfig, day, conferenceConfig } from '../../config'

export const getNowTimestamp = () => {
  return Date.now() + serverConfig.timestampOffset
}

/**
 * @param {string} duration HH:MM:SS OR MM:SS
 */
export const videoDurationStringToMs = (duration) => {
  if (typeof duration === 'number') return duration
  if (!duration || duration === '0:00' || !duration.includes(':')) return hour

  const [seconds, minutes, hours] = duration.split(':').reverse()
  const ms = minutes * minute + seconds * second

  if (hours) return ms + hours * hour
  return ms
}

export const getI18nDate = (
  dateTime,
  showOpts = { weekday: false, time: false, timezone: false, year: false, long: false }
) => {
  const date = new Date(dateTime)
  const abrevLength = showOpts.long ? 'long' : 'short'
  const options = {
    weekday: showOpts.weekday ? abrevLength : undefined,
    day: showOpts.weekday ? undefined : 'numeric',
    month: showOpts.weekday ? undefined : abrevLength,
    year: !showOpts.weekday && showOpts.year ? 'numeric' : undefined,
    hour: showOpts.time ? 'numeric' : undefined,
    minute: showOpts.time ? '2-digit' : undefined,
    timeZoneName: showOpts.timezone ? abrevLength : undefined,
  }
  const formattedDate = new Intl.DateTimeFormat(i18n.language, options).format(date)
  return formattedDate.replace(/\s/, ' ')
}

export const roundTimestampDown = ({ to = 'minute', time }) => {
  const timeMap = { hour, minute, second }
  const divider = timeMap[to]
  return Math.floor(time / divider) * divider
}

export const parseISODuration = (timestamp) => {
  if (!timestamp) return 0
  const durationRegex = /(-)?PT(?:([.,\d]+)H)?(?:([.,\d]+)M)?(?:([.,\d]+)S)?/

  const matches = durationRegex.exec(timestamp)
  if (!matches) {
    throw new Error('Invalid ISO 8601 duration format')
  }

  const [seconds = 0, minutes = 0, hours = 0] = matches.reverse()

  const totalMilliseconds = parseInt(hours, 10) * hour + parseInt(minutes, 10) * minute + parseInt(seconds, 10) * second

  return totalMilliseconds
}

const parseMsDuration = (ms) => {
  const dt = new Date(ms)
  const units = [
    ['Y', dt.getUTCFullYear() - 1970],
    ['M', dt.getUTCMonth()],
    ['D', dt.getUTCDate() - 1],
    ['T', null], // separates date and time in ISO 8601
    ['H', dt.getUTCHours()],
    ['M', dt.getUTCMinutes()],
    ['S', dt.getUTCSeconds()],
  ]

  let duration = units.reduce((acc, [k, v]) => {
    if (v) {
      acc += v + k
    } else if (k === 'T') {
      acc += k
    }
    return acc
  }, '')

  duration = duration.endsWith('T') ? duration.slice(0, -1) : duration
  return duration ? `P${duration}` : null
}

/**
 * Converts an ISO 8601 timestamp to a specified interval value.
 *
 * @param {string} timestamp - The ISO 8601 formatted timestamp.
 * @returns {number} The converted timestamp into ms.
 */
export const parseISOtoMs = (isoString) => {
  if (isoString.includes('P')) {
    return parseISODuration(isoString)
  }
  return Date.parse(isoString)
}

export const getPercentageWatched = (item) => {
  if (item?.watchHistory?.completed) {
    return 100
  }
  const mostRecentPosition = parseISODuration(item?.watchHistory?.mostRecentPosition) / second
  const durationStr = item?.video?.videoDuration
  // either live or no duration in BS
  if (item?.isLive) {
    const time = getNowTimestamp() - item.date
    const progressBar = time / videoDurationStringToMs(durationStr)

    return progressBar * 100
  }
  if (!durationStr && mostRecentPosition) {
    return watchHistoryConfig.NO_DURATION_PERCENT_COMPLETED
  }

  const timeWatchedDecimal = mostRecentPosition / (videoDurationStringToMs(durationStr) / 1000)
  return timeWatchedDecimal * 100
}

export const parseMsToISO = (ms, shouldReturnWithLocalOffset) => {
  // it should be a duration;
  // 28 days is just a guess, I don't think we'll need a duration longer than that
  if (ms < 28 * day) {
    return parseMsDuration(ms)
  }
  // it should be a date
  let date = new Date(ms)
  if (shouldReturnWithLocalOffset) {
    date = new Date(date.getTime() - date.getTimezoneOffset() * minute)
  }

  return date.toISOString()
}

export const convertMsToTime = (ms) => {
  const seconds = Math.floor(ms / second)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)

  return {
    days: Math.max(days, 0),
    hours: Math.max(hours % 24, 0),
    minutes: Math.max(minutes % 60, 0),
    seconds: Math.max(seconds % 60, 0),
  }
}

export const dateTimeFormat = (date, options) => {
  return new Intl.DateTimeFormat(i18n.language, options).format(new Date(date))
}

const padDuration = (value) => (value < 10 ? `0${value}` : value)

export const formatDuration = (duration) => {
  if (!duration) {
    return duration
  }

  let ms = 0
  if (duration instanceof String || typeof duration === 'string') {
    if (duration.indexOf(':') !== -1) {
      return duration
    }

    if (duration.startsWith('PT')) {
      ms = parseISODuration(duration)
    }
  } else if (Number.isInteger(duration)) {
    ms = duration
  }

  const time = convertMsToTime(ms)

  let result = ''
  if (time.days) {
    result += `${time.days}:`
  }

  result += time.hours ? padDuration(time.hours) : '00'
  result += ':'

  result += time.minutes ? padDuration(time.minutes) : '00'
  result += ':'

  result += time.seconds ? padDuration(time.seconds) : '00'
  return result
}

export const isCurrentlyAvailable = ({ now, timestamp, year }) => {
  if (year && conferenceConfig.year > Number(year)) {
    return true
  }

  if (timestamp) {
    return timestamp && now > timestamp
  }

  // for sessions without a timestamp, they are available as soon as the conference goes live.
  // this handles sessions that were part of the conference that just ended but don't have dates on them
  return year && Number(year) === conferenceConfig.year && now > conferenceConfig.startTimestamp
}
