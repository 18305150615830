import React, { createContext, useEffect, useState } from 'react'
import { useQueryParams } from '@fs/zion-router'
import { useChatOverlay } from '@fs/zion-chat'
import { trackEvent } from '@fs/zion-analytics'
import { useChatContext } from './ChatProvider'

const AutoOpenChatContext = createContext()

export const AutoOpenChatProvider = ({ children }) => {
  const query = useQueryParams()
  const [chatOpened, setChatOpened] = useState(false)
  const { isChatApproved, loading } = useChatContext()

  const chatRoute = query.query.showChat
  const args = []
  if (chatRoute && chatRoute !== 'default') {
    args.push(chatRoute)
    if (query.query.chatName) {
      args.push({ name: query.query.chatName })
    }
  }

  if (query.query.showChat) {
    if (query.query.tracking) {
      trackEvent({ link_name: query.query.tracking, link_page: window.location.pathname, event_name: 'click_action' })
    }
  }

  const chatOverlay = useChatOverlay(...args)

  useEffect(() => {
    if (query.query.showChat && !chatOpened && !chatOverlay.isOpen && !loading) {
      setChatOpened(true)
      query.removeQuery('showChat', 'tracking', 'chatName')

      if (isChatApproved) {
        chatOverlay.handleClick(new Event('click'))
      }
    }
  }, [chatOverlay, query, chatOpened, isChatApproved, loading])

  return <AutoOpenChatContext.Provider value={chatOverlay}>{children}</AutoOpenChatContext.Provider>
}

export default AutoOpenChatProvider
