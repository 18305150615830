import { gql } from '@apollo/client'
import { brightspotConfig } from '../../config'
import ConferenceSiteSearch from './fragments/ConferenceSiteSearch'
import gqlSanitize from './stringUtils'

const { query } = brightspotConfig
const { defaults } = query

export default function getSiteSearch() {
  return gql(`
    ${ConferenceSiteSearch}
    query ConferenceSiteSearch(
      $profileImage_crop: Boolean = false,
      $profileImage_height: Int = ${defaults.imageSize},
      $profileImage_width: Int = ${defaults.imageSize},
      $promoImage_crop: Boolean = false,
      $promoImage_height: Int = ${defaults.imageSize},
      $promoImage_width: Int = ${defaults.imageSize},
      $logoImage_crop: Boolean = false,
      $logoImage_height: Int = ${defaults.imageSize},
      $logoImage_width: Int = ${defaults.imageSize},
      $thumbnailImage_crop: Boolean = false,
      $thumbnailImage_height: Int = ${defaults.imageSize},
      $thumbnailImage_width: Int = ${defaults.imageSize},
      $filters: [FilterInput],
      $visibilityRequest: VisibilityInput = ${gqlSanitize(defaults.visibilityRequest)} ,
      $pageRequest: PaginationInput = ${gqlSanitize(defaults.pageRequest)},
      $queryString: String) {
        ConferenceSiteSearch(
          allowMixedLocales: true
          filters: $filters,
          visibilityRequest: $visibilityRequest
          queryString: $queryString 
          pageRequest: $pageRequest) {
            ...ConferenceSiteSearch
          }
      }
  `)
}
