import ContentVisibility from './ContentVisibility'

const SemaphoreTagDetail = `
  ${ContentVisibility}
  fragment SemaphoreTagDetail on SemaphoreTagDetail {
    contentLocale
    description
    displayName
    id
    visibility {
      ...ContentVisibility
    }
  }
`
export default SemaphoreTagDetail
