import { brightspotConfig } from '../../config'

export default async function loadAllPages(endpoint, client, variables, data) {
  let results = data.results

  const requests = []
  for (let i = 1; i < data.paginationData.pageCount; i++) {
    variables.page = i
    requests.push(endpoint(client, variables))
  }

  const responses = await Promise.all(requests)
  responses.forEach((resultSet) => {
    results = results.concat(resultSet.results)
  })
  return results
}

// persisted queries don't handle larger pages
const BRIGHTSPOT_PAGE_LIMIT = brightspotConfig.query.manualChunkSize

/**
 * execute a brightspot query in chunks, with data provided by the caller. will re-throw any exceptions caused by the
 * query function provided.
 *
 * @param {string[]} ids ids to be chunked
 * @param {(Object) => any[]} queryFunction function to be called with chunked variables. shoud return a list of results
 * @param {(string[]) -> Object} variableFunction function to create the variables necessary. this is a function instead of just an object
 *                             because the field to be chunked on is variable and who knows what else might need to be changed
 * @param {(Object) => any[]} dataExtractor function to get the results out of the response
 * @returns the results of each chunk combined into a single array
 */
export async function loadChunks(ids, queryFunction, variableFunction) {
  if (!ids || ids.length === 0) {
    return []
  }

  let start = 0
  let end = ids.length > BRIGHTSPOT_PAGE_LIMIT ? BRIGHTSPOT_PAGE_LIMIT : ids.length
  let keepGoing = true

  // Execute brightspot query, 100 at a time
  const promises = []
  while (keepGoing) {
    const chunk = ids.slice(start, end)
    const variables = variableFunction(chunk)
    promises.push(queryFunction(variables))

    start = end
    end = start + BRIGHTSPOT_PAGE_LIMIT > ids.length ? ids.length : start + BRIGHTSPOT_PAGE_LIMIT
    keepGoing = start < ids.length
  }

  const chunks = await Promise.all(promises)

  return chunks.flatMap((c) => c.results)
}
