import React from 'react'
import { MenuOverlay } from '@fs/zion-ui'
import LanguageItems from './LanguageItems'

const LanguageOverlay = ({ onLangClick, ...overlay }) => {
  const onClick = (e) => {
    overlay.handleClick(e)
  }

  return (
    <MenuOverlay {...overlay}>
      <LanguageItems onClick={onClick} onLangClick={onLangClick} removeFiller />
    </MenuOverlay>
  )
}

export default LanguageOverlay
