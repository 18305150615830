import React, { Children } from 'react'
import { Ghost, Separator } from '@fs/zion-ui'
import { css } from '@emotion/core'
import { boolAttr } from '@fs/zion-frontend-friends'
import { CardCarousel, cardHeadingMap, NewCard } from '../../../lib/card'
import {
  getCreatorsNames,
  getCustomImage,
  getIsPastBroadcast,
  isExternalUrl,
  getPercentageWatched,
} from '../../../lib/helpers'
import { useIsMdWidth, getIsPlaylistable } from '../../../hooks'
import { SectionHeading } from '../../../lib/section-card'

const cardCss = css`
  --cell-width: 252px;
  width: var(--cell-width);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 400px;

  &[is-md-width] {
    --cell-width: 200px;
    height: 350px;
  }
`

const HorizontalCardGrid = ({ items, heading, as = 'h3', defaultDuration }) => {
  const isMdWidth = useIsMdWidth()

  return (
    <div>
      <SectionHeading heading={heading} flavor="none" as={as} />
      <Separator size="nano" />
      <CardCarousel>
        {Children.toArray(
          items?.map((session) => {
            const { date, isLive, item } = session
            const { title, creators, description, url, sessionUrl, conferenceYear, videoDuration } = item ?? session
            const percentageWatched = getPercentageWatched(item ?? session)
            const duration =
              defaultDuration && (!videoDuration || videoDuration === '0:00') ? defaultDuration : videoDuration
            const { contentLocale } = item?.video ?? session ?? {}
            const actualUrl = url ?? sessionUrl
            return (
              <div css={cardCss} is-md-width={boolAttr(isMdWidth)}>
                <Ghost keepVisible>
                  <NewCard
                    contentId={session?.contentId ?? item?.contentId ?? session?.id ?? item?.id}
                    isPlaylistable={getIsPlaylistable({
                      contentType: session?.contentType || item?.contentType,
                    })}
                    image={getCustomImage(item ?? session)}
                    maxImageHeight="200px"
                    heading={title}
                    description={getCreatorsNames(creators) ?? description}
                    isLive={isLive}
                    to={actualUrl}
                    contentLocale={contentLocale}
                    duration={duration}
                    external={isExternalUrl(url ?? sessionUrl)}
                    startTimestamp={!getIsPastBroadcast({ date, videoDuration: duration }) ? date : undefined}
                    conferenceYear={conferenceYear}
                    percentageWatched={percentageWatched}
                    as={cardHeadingMap[as]}
                    item={session}
                  />
                </Ghost>
              </div>
            )
          })
        )}
      </CardCarousel>
    </div>
  )
}

export default HorizontalCardGrid
