const second = 1 * 1000
const minute = 60 * second
const hour = 60 * minute
const day = 24 * hour

const conferenceConfig = {
  startTimestamp: 1741215600000, // Wed Mar 5 2025 16:00:00 GMT-0700 (Mountain Standard Time)
  endTimestamp: 1741395600000, // Sat Mar 7 2025 18:00:00 GMT-0700 (Mountain Standard Time)
  year: '2025',

  cfcSubmissionDeadlineTimestamp: 1719791999000, // Sunday, June 30, 2024 11:59:59 PM GMT-06:00 DST
  cfcApprovalDeadlineTimestamp: 1724263140000, // Wednesday, August 21, 2024 11:59:00 AM GMT-06:00 DST

  // REGISTRATION PRICING
  earlyBirdStartTimestamp: '2024-09-25T07:00:00.000',
  earlyBirdEndTimestamp: '2024-11-01T07:00:00.000',
  blackFridayStartTimestamp: '2024-11-25T07:00:00.000',
  blackFridayEndTimestamp: '2024-12-02T07:00:00.000',
  lastChanceStartTimestamp: '2025-02-12T07:00:00.000',
  lastChanceEndTimestamp: '2025-02-19T07:00:00.000',

  exhibitorDeadlineTimestamp: 1736549999000, // Fri Jan 10 2025 23:59:59 GMT-0700 (Mountain Standard Time)
  sponsorDeadlineTimestamp: 1740178799000, // Fri Feb 21 2025 23:59:59 GMT-0700 (Mountain Standard Time)

  playerTimeFrame: 42 * day,

  // PROMOTIONS
  promotionsStartTimestamp: '2025-02-26T07:00:00.000',
  promotionsEndTimestamp: '2025-04-15T07:00:00.000',
}

export { second, minute, hour, day, conferenceConfig }
