import React from 'react'
import 'react-multi-carousel/lib/styles.css'
import { colors } from '@fs/zion-ui'
import { css } from '@emotion/core'

const dotCss = css`
  width: var(--dot-diameter);
  height: var(--dot-diameter);
  border-radius: 2em;
  background-color: ${colors.gray50};
  border: none;

  // webkit CSS reset
  padding: 0;

  :focus {
    outline: 2px solid ${colors.gray70};
  }
`
const activeDotCss = css`
  background-color: ${colors.gray90};
`

const Dot = ({ onClick, active }) => {
  // FIXME: add aria-label
  // eslint-disable-next-line jsx-a11y/control-has-associated-label
  return <button type="button" css={[dotCss, active && activeDotCss]} onClick={onClick} />
}

export default Dot
