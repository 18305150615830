import React, { createContext, useContext } from 'react'
import useChatApproval from '../../hooks/useChatApproval'

const ChatContext = createContext()
export const useChatContext = () => useContext(ChatContext)

export const ChatProvider = ({ children }) => {
  const chatApproval = useChatApproval()

  return (
    <ChatContext.Provider
      // FIXME: This is a temporary fix. We need to remove this eslint-disable line and fix the issue.
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ ...chatApproval }}
    >
      {children}
    </ChatContext.Provider>
  )
}
