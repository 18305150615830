import { useLazyQuery } from '@apollo/client'
import { searchSessions } from '../../api'
import useErrorHandlerOverlay from '../useErrorHandlerOverlay'

export default function useLazySessionSearch() {
  const [getSessions, { loading, data, error }] = useLazyQuery(searchSessions())
  useErrorHandlerOverlay(error)

  return [getSessions, { loading, data: data?.SessionSearch, error }]
}
