import React from 'react'
import { PageHead } from '@fs/zion-head'
import { useTranslation } from 'react-i18next'
import { useLocation } from '@fs/zion-router'
import { placeholderSeafoamJpg } from '../../images'

export default function PageTitle() {
  const [t] = useTranslation()
  const { pathname } = useLocation()

  // NOTE: This does not handle all page titles. In some cases, the title needs specific data we don't have here.
  // SeriesPage, SessionPage, BoothPage, and SpeakerPage set their own title.

  const titleMap = {
    '/': t('navigation.home.label', 'Home'),
    '/search': t('navigation.on-demand.label', 'On Demand'),
    '/library': t('library.header', 'Video Library'),
    '/speakers/': t('navigation.speakers.label', 'Speakers'),
    '/schedule/': t('navigation.schedule.label', 'Schedule'),
    '/playlist': t('navigation.playlist.label', 'My Playlist'),
    '/playlists/': t('navigation.playlists.label', 'My Playlists'),
    '/history': t('navigation.watch-history.label', 'Watch History'),
    '/dashboard/': t('navigation.dashboard.label', 'Dashboard'),
    '/user': t('navigation.user.label', 'User'),
    '/faq': t('navigation.faq.label', 'Frequently Asked Questions'),
    '/about': t('about.banner.heading', 'About Us'),

    '/subscription': t('conference-announce.subscribe.for.updates', 'Subscribe for Updates'),
    '/getting-started': t('search.getting-started.label', 'Getting Started'),
    '/call-for-presentations/': t('navigation.call-for-presentations.title', 'Call for Presentations'),

    '/registration/': t('event.reg.attend', 'Register for RootsTech'),
    '/registration/in-person/': t('event.reg.attend.in-person.register', 'In-Person Event Registration'),
    '/registration/online/': t('event.reg.attend.virtual.register', 'Online Event Registration'),
    '/registration/in-person/expohall/': t('event.reg.attend.expohall.register', 'Expo Hall Only Registration'),
    '/registration/in-person/discovery-day/': t(
      'event.reg.attend.discovery-day.register',
      'Discovery Day Registration'
    ),
  }
  const path = titleMap[pathname]
  const rtTitle = t('site-title', 'RootsTech')

  return (
    <PageHead
      title={path ? [path, rtTitle].join(' \u2022 ') : rtTitle}
      includeHreflang
      description={t(
        'event-landing.expect.description',
        'A family history conference. An on-demand learning library. A way to grow closer to the people, places, and stories that matter most. RootsTech is all that and so much more. Join us as we celebrate together the joy of connection.'
      )}
      image={{ url: placeholderSeafoamJpg, width: 1200, height: 630 }}
      siteName={t('global.rootstech', 'RootsTech')}
      type="website"
      twitterSite="@RootsTechConf"
    />
  )
}
