import React, { Children } from 'react'
import PropTypes from 'prop-types'
import { FlowGrid } from '@fs/zion-ui'
import DownloadableResource from './DownloadableResource/DownloadableResource'

export default function DownloadableResources({ downloadableResources = [] }) {
  return (
    <FlowGrid lastRowAlignX={downloadableResources.length > 1 ? 'center' : 'start'}>
      {Children.toArray(downloadableResources.map((content) => <DownloadableResource {...content} />))}
    </FlowGrid>
  )
}

DownloadableResources.propTypes = {
  downloadableResources: PropTypes.arrayOf(PropTypes.shape({ url: PropTypes.string, title: PropTypes.string })),
}
