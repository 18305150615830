import { Link } from '@fs/zion-router'
import React, { forwardRef } from 'react'
import { css } from '@emotion/core'
import { softSanitizeProps } from '../../helpers'

const linkHackCss = css`
  // increase specificity
  &[href][iswhite] {
    border: 0;
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
  }
`

export default forwardRef(function PossibleLink({ to, noUnderline, ...props }, ref) {
  if (to) {
    return <Link ref={ref} to={to} css={noUnderline ? linkHackCss : undefined} {...props} />
  }
  const sanitizedProps = softSanitizeProps(props, ['linkName', 'external'])
  return <span ref={ref} css={noUnderline ? linkHackCss : undefined} {...sanitizedProps} />
})
