import React from 'react'
import ResponsiveLayoutBand from './ResponsiveLayoutBand'

export default function ResponsiveSectionBand({ children, ...props }) {
  return (
    <ResponsiveLayoutBand as="section" {...props}>
      {children}
    </ResponsiveLayoutBand>
  )
}
