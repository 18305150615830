import { gql } from '@apollo/client'
import LocaleDataReference from './fragments/LocaleDataReference'

export default function getLocales() {
  return gql(`
    ${LocaleDataReference}
    query Locales {
      ConferenceDataViewModel {
        locales {
          ...LocaleDataReference
        }
      }
    }
  `)
}
