import { getNowTimestamp } from '../../../lib/helpers'

export const CALENDAR_MAIN_STAGE = 'main-stage'
export const CALENDAR_SESSIONS = 'sessions'
export const FULL_SCHEDULE_TAB = 'full_schedule'
export const MY_SCHEDULE_TAB = 'my_schedule'
export const SESSION_LOCATION = { online: 'Online', inperson: 'In Person', allsessions: 'In Person and Online' }

export const mapToMyScheduleDate = (date, localDate = false) => {
  const year = localDate ? date.getFullYear() : date.getUTCFullYear()
  const month = (localDate ? date.getMonth() : date.getUTCMonth()) + 1
  const day = localDate ? date.getDate() : date.getUTCDate()
  const formattedMonth = month > 9 ? `${month}` : `0${month}`
  const formattedDay = day > 9 ? `${day}` : `0${day}`
  // yyyy-mm-dd
  return `${year}-${formattedMonth}-${formattedDay}`
}

export const mapToMyScheduleHour = (date) => {
  const hour = date.getUTCHours()
  const formattedHour = hour > 9 ? `${hour}:00` : `0${hour}:00`

  return formattedHour
}

export const isInMySchedule = (mySchedule, contentId, calendarDate) => {
  let inMySchedule = false
  // If there is content in my schedule
  if (mySchedule?.days) {
    const sessionDate = new Date(calendarDate)
    const formatedDate = mapToMyScheduleDate(sessionDate)
    const dateKeys = Object.keys(mySchedule.days)
    // Check if the calendar item date is present in my schedule
    if (dateKeys.includes(formatedDate)) {
      dateKeys.forEach((dateKey) => {
        const formatedHour = mapToMyScheduleHour(sessionDate)
        const timeKeys = Object.keys(mySchedule.days[dateKey])

        // Check if my schedule contains the calendar item hour
        if (timeKeys.includes(formatedHour)) {
          timeKeys.forEach((timeKey) => {
            const timeSlot = mySchedule.days[dateKey][timeKey]
            const preferred = timeSlot.preferred
            const alternates = timeSlot.alternates

            if (preferred === contentId || alternates.includes(contentId)) {
              inMySchedule = true
            }
          })
        }
      })
    }
  }
  return inMySchedule
}

export const getMyScheduleItemCount = (mySchedule) => {
  let count = 0
  // If there is content in my schedule
  if (mySchedule?.days) {
    const dateKeys = Object.keys(mySchedule.days)

    dateKeys.forEach((dateKey) => {
      // If the date contains timeslots
      if (Object.keys(mySchedule.days[dateKey]).length !== 0) {
        const timeKeys = Object.keys(mySchedule.days[dateKey])

        timeKeys.forEach((timeKey) => {
          const timeSlot = mySchedule.days[dateKey][timeKey]
          const preferred = timeSlot.preferred
          const alternates = timeSlot.alternates

          if (preferred) {
            count++
          }
          if (alternates.length) {
            count += alternates.length
          }
        })
      }
    })
  }
  if (mySchedule?.onDemand) {
    count += mySchedule?.onDemand?.length ?? 0
  }

  return count
}

const addSessionToMySchedule = (consolidatedItems, day, hour, foundItem) => {
  consolidatedItems[day] = consolidatedItems[day] || {}
  consolidatedItems[day][hour] = consolidatedItems[day][hour] || {}
  const { preferred, alternates } = consolidatedItems[day][hour]
  const hourSlot = {
    preferred: preferred ?? foundItem.item.contentId,
    alternates: !preferred ? [] : [...alternates, foundItem.item.contentId],
  }
  consolidatedItems[day][hour] = hourSlot
}

export const consolidateMySchedule = (mySchedule, calendarData) => {
  const myScheduleDayKeys = mySchedule?.days ? Object.keys(mySchedule?.days) : []
  const consolidatedItems = {}
  const rescheduledItems = []

  // For every day
  myScheduleDayKeys?.forEach((day) => {
    const myScheduleDay = mySchedule?.days?.[day]
    const myScheduleHourKeys = myScheduleDay ? Object.keys(myScheduleDay) : []
    // And every hour in mySchedule
    myScheduleHourKeys?.forEach((hour) => {
      const timeslot = myScheduleDay?.[hour]

      if (timeslot) {
        // Grab the stored ids.
        const sessionsIds = [timeslot.preferred, ...timeslot.alternates]
        sessionsIds.forEach((id) => {
          // Filter the calendar items by contentId.
          const sessions = calendarData.filter(({ item }) => {
            return id === item.contentId
          })
          // Find if the session haven't been rescheduled.
          const foundItem = sessions?.find(({ item, date }) => {
            const sessionDate = new Date(date)
            const calendarDay = mapToMyScheduleDate(sessionDate)
            const calendarHour = mapToMyScheduleHour(sessionDate)
            return id === item.contentId && calendarDay === day && calendarHour === hour
          })
          // If the session exist add it to the consolidated mySchedule object.
          if (foundItem) {
            addSessionToMySchedule(consolidatedItems, day, hour, foundItem)
          }
          // If no session with the same calendar date has been found,
          // and the session exists in the calendar, consolidates best fit.
          else if (sessions?.length) {
            // Convert "yyyy-MM-dd HH:mm" to ms
            const previousDate = new Date(`${day} ${hour}Z`).getTime()

            const closestSession = sessions.reduce((a, b) => {
              const aDiff = Math.abs(a.date - previousDate)
              const bDiff = Math.abs(b.date - previousDate)
              const currentTimestamp = getNowTimestamp()

              // If a session is in the past and there are more sessions
              // return the following session.
              if (currentTimestamp > a.date && currentTimestamp < b.date) {
                return b
              }
              // Otherwise, return the closest session in time from the selected.
              return bDiff < aDiff ? b : a
            })

            const rescheduledItem = { previousDate, ...closestSession }
            rescheduledItems.push(rescheduledItem)
          }
          // Not found sessions will be discarded.
        })
      }
    })
  })

  rescheduledItems?.forEach((calendarItem) => {
    const sessionDate = new Date(calendarItem.date)
    const day = mapToMyScheduleDate(sessionDate)
    const hour = mapToMyScheduleHour(sessionDate)

    addSessionToMySchedule(consolidatedItems, day, hour, calendarItem)
  })

  // Make sure the onDemand object exists to prevent the backend from failing.
  const onDemand = mySchedule?.onDemand || []
  const consolidatedMySchedule = { days: consolidatedItems, onDemand }

  return { consolidatedMySchedule, rescheduledItems }
}
