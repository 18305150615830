import React from 'react'
import { useFeatureFlag } from '@fs/zion-flags'
import getApplicableRegionPages from './helpers'
import { getRelativePath } from '../../helpers'
import SiteBanner from '../SiteBanner'
import { useCountryChange } from '../../../providers'

export default function RegionBanner() {
  const { country } = useCountryChange()
  const regionBannerFlag = useFeatureFlag('rootstech_region_banner')
  if (!regionBannerFlag.isOn) {
    return null
  }

  const applicablePages = getApplicableRegionPages(country)
  if (!applicablePages.length) {
    return null
  }

  const { url, heading, dismissText, confirmText } = applicablePages[0]

  return (
    <div dir="auto">
      <SiteBanner
        type="info"
        heading={heading}
        storageKey="region-page"
        confirmProps={{ to: getRelativePath(url), children: confirmText }}
        dismissProps={{ children: dismissText }}
      />
    </div>
  )
}
