import { isConferencePromo, isSessionDetail, traverseAndModify } from '../../../lib/helpers'

const isSessionObject = (obj) => {
  return isSessionDetail(obj) || isConferencePromo(obj)
}

/**
 * Extracts session IDs from the provided data and maps them to their paths.
 *
 * @param {Object} data - The data object to traverse and extract session IDs from.
 * @returns {Object} An object where the keys are session IDs and the values are their corresponding paths.
 */
const getSessionIds = (data) => {
  const ids = {}

  traverseAndModify(data, isSessionObject, (obj, path) => {
    const id = obj.id || obj.contentId
    // There could be a duplicate id in the main data or in a sub field like 'promo'. We only want the first one it finds
    if (ids[id]) {
      return
    }
    ids[id] = path
  })
  return ids
}

export default getSessionIds
