import CalendarDetail from './CalendarDetail'

const CalendarModule = `
  ${CalendarDetail}
  fragment CalendarModule on CalendarModule {
    __typename
    calendar {
      ...CalendarDetail
    }
  }
`
export default CalendarModule
