import axios from '@fs/zion-axios'
import SERVICE from './serviceName'

// DOCS: https://www.familysearch.org/service/rootstech/rtc2022/helpdesk/

const BASE = `${SERVICE}/rtc2022/helpdesk`
const GENERALISTS = `${BASE}/generalists`
const VENDORS = `${BASE}/vendors`

/**
 * Get info from the backend about a support expert, such as availability and expertise
 * @returns {Promise} A Promise resolving to the expert's information
 */
export const getVolunteer = () => {
  return axios.get(`${GENERALISTS}/CURRENT`)
}
/**
 * update info from the backend about a support expert, such as availability and expertise
 * @param {Object} data The data provided to the function {"languages": ["..."], "supportCategories": ["..."]}
 * @param {String} data.cisId The expert's CIS ID
 * @returns {Promise} A Promise resolving to the expert's information
 */
export const updateVolunteer = ({ cisId, data }) => {
  return axios.post(`${GENERALISTS}/${cisId}`, data)
}

export const createVolunteer = ({ data }) => {
  return axios.post(`${GENERALISTS}`, data)
}

/**
 * Get a list of vendors for a vendorId and event
 * @param {String} vendorId is the exhibitors id
 * @param {String} eventName is the name of the event that a vendor has signed up for (Rootstech, DNA Day, etc..)
 * @returns {Promise} A Promise resolving to a list of available vendors
 */
export const getAvailableVendorReps = (vendorId, eventName) => {
  return axios.get(`${VENDORS}/${vendorId}/reps`, { params: { event: eventName, available: false, limit: 49 } })
}

/**
 * Create a relationship between a user and vendor for event
 * @param {String} vendorId is the exhibitors id
 * @param {String} eventName is the name of the event that a vendor has signed up for (Rootstech, DNA Day, etc..)
 * @param {String} cisId a users unique identifier.
 * @returns {Promise} A Promise resolving to a list of available vendors
 */
export const upsertVendorRep = (vendorId, eventName, cisId) => {
  return axios.put(`${VENDORS}/${vendorId}/events/${eventName}/reps/${cisId}`, null, {
    headers: { 'Content-Type': 'application/json' },
  })
}

/**
 * Delete a vendor for a vendorId and event
 * @param {String} vendorId is the exhibitors id
 * @param {String} eventName is the name of the event that a vendor has signed up for (Rootstech, DNA Day, etc..)
 * @param {String} cisId a users unique identifier.
 * @returns {Promise} A Promise resolving to a list of available vendors
 */
export const deleteVendorRep = (vendorId, eventName, cisId) => {
  return axios.delete(`${VENDORS}/${vendorId}/events/${eventName}/reps/${cisId}`)
}
