import React, { Suspense, lazy, Children, useState } from 'react'
import { FlowGrid, useContainerWidth, Row } from '@fs/zion-ui'

const SeeMoreButton = lazy(() => import('../search/SeeMoreButton'))

export default function CardGrid({
  limit: initialLimit = 0,
  maxColumnCount = 4,
  seeMoreMode = 'none',
  children,
  ...props
}) {
  const atWidth = useContainerWidth()
  const [limit, setLimit] = useState(initialLimit)
  const visibleCards = limit ? Children.toArray(children).slice(0, limit) : children
  let moreLimit = 0
  switch (seeMoreMode) {
    case 'byRow':
      moreLimit = atWidth({ default: 2, lg: 3, xl: maxColumnCount })
      break
    case 'byLimit':
      moreLimit = limit
      break
    default:
      moreLimit = 0
  }
  return (
    <>
      <FlowGrid
        {...props}
        columnCount={atWidth({ default: 1, md: 2, lg: 3, xl: maxColumnCount })}
        gutters={atWidth({ default: 'xxs', xs: 'xs', sm: 'sm' })}
      >
        {visibleCards}
      </FlowGrid>
      {moreLimit && children?.length > visibleCards?.length ? (
        <Suspense fallback={<span />}>
          <Row alignX="center">
            <SeeMoreButton
              onClick={() => {
                setLimit((prevLimit) => prevLimit + moreLimit)
                // The SeeMoreButton expects a promise to be returned, so rather than change
                // functionality there, we just return one to make things not break
                return Promise.resolve()
              }}
            />
          </Row>
        </Suspense>
      ) : null}
    </>
  )
}
