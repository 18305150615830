import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { getBsCountries } from '../../../api'
import { useErrorHandlerOverlay } from '../../../hooks'
import { deepCopy, getRegionName } from '../../../lib/helpers'

export default function useCountries() {
  const { loading, data, error } = useQuery(getBsCountries())
  useErrorHandlerOverlay(error)

  const countries = data?.ConferenceDataViewModel?.countryTags
  const processedCountries = useMemo(
    () =>
      deepCopy(countries || [])
        .sort((first, second) => first.label.localeCompare(second.label))
        .map((country) => ({ ...country, label: getRegionName(country.countryCode) })),
    [countries]
  )

  return { loading, countries: processedCountries, error }
}
