export function mapBsSocialPlatforms(socialAccounts = []) {
  const supportedPlatforms = ['linkedin', 'instagram', 'facebook', 'youtube', 'twitter', 'whatsApp']
  return socialAccounts.reduce((accumulator, currentValue) => {
    const platform = currentValue.platform.toLowerCase().replace('_a', 'A') // .replace() Caters for WHATS_APP
    if (supportedPlatforms.includes(platform)) {
      accumulator[platform] = currentValue.urlOrHandle
    }
    return accumulator
  }, {})
}
export function getWebsite(socialAccounts) {
  const websitePlatform = socialAccounts.find((account) => account.platform === 'WEBSITE')
  if (websitePlatform) return websitePlatform.urlOrHandle
  return undefined
}
