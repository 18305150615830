import React from 'react'
import { Card, Separator, H4, Bleed, H5 } from '@fs/zion-ui'
import { css } from '@emotion/core'
import RainbowDivider from '../RainbowDivider'
import {
  appleWavesJpg,
  blackWavesJpg,
  blueberryWavesJpg,
  duskWavesJpg,
  fireWavesJpg,
  mangoWavesJpg,
  seafoamWavesJpg,
} from '../../images'
import BlackText from '../BlackText'

const innerCardCss = css`
  padding: 4px;
  flex-grow: 1;
`
const imageFlavorMap = {
  apple: appleWavesJpg,
  blueberry: blueberryWavesJpg,
  mango: mangoWavesJpg,
  dusk: duskWavesJpg,
  fire: fireWavesJpg,
  seafoam: seafoamWavesJpg,
}

export default function BorderCard({
  heading,
  topHeading,
  topSeparator = false,
  hideDivider = false,
  flavor,
  children,
  margins = 'regular',
}) {
  return (
    <Card backgroundImage={imageFlavorMap[flavor]}>
      {topSeparator && !topHeading && <Separator size="md" />}
      {topHeading && (
        <BlackText>
          <H5 centered>{topHeading}</H5>
          <Separator size="xxs" />
        </BlackText>
      )}
      <Bleed all css={innerCardCss}>
        <Card backgroundImage={blackWavesJpg} margins={margins}>
          {heading && (
            <>
              <Separator size="nano" />
              <H4>{heading}</H4>
              <Separator size="xxs" />
            </>
          )}
          {!hideDivider && (
            <>
              <RainbowDivider thick and full of flavor="charcoal" />
              <Separator size="xxs" />
            </>
          )}
          {children}
        </Card>
      </Bleed>
    </Card>
  )
}
