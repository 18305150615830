import React from 'react'
import { LayoutBand } from '@fs/zion-ui'
import {
  appleRibbonSvg,
  blueberryRibbonSvg,
  duskRibbonSvg,
  fireRibbonSvg,
  lavaRibbonSvg,
  lemonLimeRibbonSvg,
  mangoRibbonSvg,
  seafoamRibbonSvg,
  sunriseRibbonSvg,
  smokeRibbonSvg,
} from '../images'
import { flavors } from '../config'

const imageFlavorMap = {
  apple: appleRibbonSvg,
  blueberry: blueberryRibbonSvg,
  lava: lavaRibbonSvg,
  mango: mangoRibbonSvg,
  dusk: duskRibbonSvg,
  fire: fireRibbonSvg,
  seafoam: seafoamRibbonSvg,
  lemonLime: lemonLimeRibbonSvg,
  sunrise: sunriseRibbonSvg,

  ancestry: smokeRibbonSvg,
  familysearch: appleRibbonSvg,
  myheritage: fireRibbonSvg,
  storied: lavaRibbonSvg,
}

export default function RainbowRibbonBand({ flavor = flavors.default }) {
  return (
    <LayoutBand
      // the SVG paths have some aliasing that we couldn't figure out
      // adding a solid background hides the problem
      style={{ backgroundColor: flavors[flavor].solid }}
      image={imageFlavorMap[flavor]}
      marginY="xxs"
      data-testid="ribbon-band"
    />
  )
}
