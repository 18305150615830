import { gql } from '@apollo/client'
import SessionTypeReference from './fragments/SessionTypeReference'

export default function getSessionContentTypes() {
  return gql(`
    ${SessionTypeReference}
    query QuerySessionContentTypes {
      ConferenceDataViewModel {
        sessionTypes {
          ...SessionTypeReference
        }
      }
    }
  `)
}
