import axios from '@fs/zion-axios'
import SERVICE from './serviceName'

// NOTE: This service was deleted. We will need to port this to the new service when ready. Work should start in July.
// DOCS: https://fhconfluence.churchofjesuschrist.org/x/ODW4E

const BASE = `${SERVICE}/session-metrics/sessions`
const config = { headers: { 'Content-Type': 'application/json' }, data: {} }
export const getUserSessionRating = async ({ sessionId, cisId }) => {
  return axios.get(`${BASE}/${sessionId}/ratings/${cisId}`, config)
}

export const deleteUserSessionRating = async ({ sessionId, cisId }) => {
  return axios.delete(`${BASE}/${sessionId}/ratings/${cisId}`, config)
}

export const getSessionMetrics = async ({ sessionId }) => {
  return axios.get(`${BASE}/${sessionId}`, config)
}
export const getSessionRatings = async ({ sessionId }) => {
  return axios.get(`${BASE}/${sessionId}/ratings`, config)
}

export const getUserRatings = async () => {
  return axios.get(`${BASE}`, config)
}

export const updateUserSessionRatings = async ({ sessionId, cisId }, body) => {
  return axios.put(`${BASE}/${sessionId}/ratings/${cisId}`, body, config)
}
