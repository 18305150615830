const Pagination = `
  fragment Pagination on Pagination {
    count
    hasNext
    limit
    offset
    pageCount
    pageIndex
  }
`
export default Pagination
