import React from 'react'
import { FormTextField } from '@fs/zion-form'
import { useTranslation } from 'react-i18next'

export default function LastNameField() {
  const [t] = useTranslation()
  return (
    <FormTextField
      name="lastName"
      label={`${t('conference-announce.subscribe.last-name.field', 'Last Name')}: *`}
      required
    />
  )
}
