import React from 'react'
import PropTypes from 'prop-types'
import { LayoutBand, Bleedable } from '@fs/zion-ui'
import { useIsMdWidth } from '../../hooks'

export default function SectionContents({ children }) {
  const isMdWidth = useIsMdWidth()
  const pad = isMdWidth ? 32 : 80
  return (
    <LayoutBand>
      <Bleedable top={pad} sides={pad} bottom={pad}>
        {children}
      </Bleedable>
    </LayoutBand>
  )
}

SectionContents.propTypes = {
  children: PropTypes.node.isRequired,
}
