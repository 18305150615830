import { gql } from '@apollo/client'
import { sanitize } from '../helpers'
import AttachmentDetail from './fragments/AttachmentDetail'

export function mutateAttachment() {
  return gql(`
    ${AttachmentDetail}
    mutation AttachmentMutation(
      $description: String, 
      $title: String, 
      $url: String, 
      $target: MutationTargetInput, 
      $workflow: WorkflowInput) {
      AttachmentMutation(
        description: $description, 
        title: $title, 
        url: $url, 
        _target: $target, 
        _workflow: $workflow) {
        result {
          ...AttachmentDetail
        }
      }
    }
  `)
}

export async function createAttachment(client, attachment) {
  const variables = {}

  if (attachment) {
    variables.description = attachment.description ? sanitize(attachment.description) : ''
    variables.title = attachment.title ? sanitize(attachment.title) : ''
    variables.url = attachment.url ? attachment.url : ''

    // NOTE - 2021-12-02 - This is a hack because BS broke us with their deploy yesterday
    // If we have a session id we are doing an update.  Otherwise, it is a create
    if (attachment.id) {
      variables.target = { id: attachment.id, locale: attachment.locale }
    }
  }

  const response = await client.mutate({ mutation: mutateAttachment(), variables })
  return response?.data?.AttachmentMutation?.result
}

export async function updateAttachment(client, attachment) {
  return createAttachment(client, attachment)
}
